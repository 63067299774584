import { useState, useCallback } from "react";

import { isApolloError } from "@apollo/client";
import { useNavigate } from "react-router";
import shallow from "zustand/shallow";

import { routes } from "config/routes";
import { useEmployeeStore } from "core/stores/useEmployeeStore";
import { EppoFeatureFlags } from "core/types/flags";
import { useCustomToast } from "shared/hooks/useCustomToast";
import { useEppoFeatureFlagProvider } from "shared/hooks/useEppoFeatureFlag";
import { useProductSearchStore } from "shared/stores/useProductSearchStore";
import { formatEmployeeName } from "utils/formats";
import { isNullOrUndefined } from "utils/tsHelpers";

import {
  useAddItemsToPreDroppingListMutation,
  useCreatePreDroppingListMutation,
} from "../queries/collaborativeInbound/collaborativeInbound.generated";
import { useGetMultipleDesadvsRolliIdLazyQuery } from "../queries/despatchAdvice/despatchAdvice.generated";
import { useInboundStore } from "./useInboundStore";
import { useInboundUIStore } from "./useInboundUIStore";

const CREATE_SHARED_LIST_ERROR_TOAST_ID = "create_shared_list_error_toast";
const CREATE_SHARED_LIST_SUCCESS_TOAST_ID = "create_shared_list_success_toast";
const ADD_ITEMS_SHARED_LIST_ERROR_TOAST_ID = "add_items_shared_list_error_toast";
const ADD_ITEMS_SHARED_LIST_SUCCESS_TOAST_ID = "add_items_shared_list_success_toast";

const formatSharedListName = (firstName: string, lastName: string) => {
  const formattedName = formatEmployeeName(firstName, lastName);
  const currentTimeInGerman = new Date().toLocaleTimeString("de-DE").slice(0, 5);
  return `${formattedName}'s list - ${currentTimeInGerman}`;
};

export const useSharedListCreator = () => {
  const { isFeatureEnabled: isDesadvBasedInboundingEnabled } = useEppoFeatureFlagProvider(
    EppoFeatureFlags.DESADV_BASED_INBOUNDING,
  );
  const navigate = useNavigate();
  const { showToastUI } = useCustomToast();
  const [isCreatingList, setIsCreatingList] = useState(false);
  const [isAddingItems, setIsAddingItems] = useState(false);

  const { sharedListId, setDeliverySSCC, setSharedListName, setSharedListId } = useInboundStore(
    (state) => ({
      sharedListId: state.sharedListId,
      setDeliverySSCC: state.setDeliverySSCC,
      setSharedListName: state.setSharedListName,
      setSharedListId: state.setSharedListId,
    }),
    shallow,
  );

  const { setInboundUIState } = useInboundUIStore(
    (state) => ({
      setInboundUIState: state.setInboundUIState,
    }),
    shallow,
  );

  const { employeeId, firstName, lastName } = useEmployeeStore(
    (state) => ({
      employeeId: state.badgeNo,
      firstName: state.firstName,
      lastName: state.lastName,
    }),
    shallow,
  );

  const { selectedProducts, setSelectedProducts } = useProductSearchStore(
    (state) => ({
      selectedProducts: state.selectedProducts,
      setSelectedProducts: state.setSelectedProducts,
    }),
    shallow,
  );

  const listName = formatSharedListName(firstName, lastName);

  const [fetchDesadvs] = useGetMultipleDesadvsRolliIdLazyQuery({
    onError: (e) => {
      if (
        isApolloError(e) &&
        e.graphQLErrors?.[0]?.extensions?.code === "DESPATCH_ADVICE_NOT_FOUND"
      ) {
        setInboundUIState({ shouldShowDespatchAdviceModal: true });
      } else {
        setDeliverySSCC(null);
      }
    },
  });

  const [createSharedListMutation] = useCreatePreDroppingListMutation({
    onCompleted: (data) => {
      const lListId = data?.createPreDroppingList?.id || null;
      setSharedListName(listName);
      setSharedListId(lListId);
      showToastUI({
        status: "success",
        id: CREATE_SHARED_LIST_SUCCESS_TOAST_ID,
        title: "create_shared_list_success_toast",
      });
      navigate(routes.inbound.preDropping);
    },
  });

  const [addItemsToPreDroppingListMutation] = useAddItemsToPreDroppingListMutation({
    onCompleted: () => {
      setSelectedProducts([]);
      showToastUI({
        status: "success",
        id: ADD_ITEMS_SHARED_LIST_SUCCESS_TOAST_ID,
        title: "add_items_shared_list_success_toast",
      });
      navigate(routes.inbound.root);
    },
  });

  const createSharedList = useCallback(
    async (deliverySSCC?: string) => {
      setIsCreatingList(true);
      try {
        if (employeeId) {
          if (!isDesadvBasedInboundingEnabled || isNullOrUndefined(deliverySSCC)) {
            setDeliverySSCC(null);
            await createSharedListMutation({
              variables: { input: { employeeId, name: listName } },
            });
            return;
          }
          setDeliverySSCC(deliverySSCC);
          const { data } = await fetchDesadvs({ variables: { input: { rolliID: deliverySSCC } } });
          if (data) {
            await createSharedListMutation({
              variables: { input: { employeeId, name: listName, sscc: deliverySSCC } },
            });
            return;
          }
        }
      } catch (_) {
        showToastUI({
          id: CREATE_SHARED_LIST_ERROR_TOAST_ID,
          title: "create-shared-list-error-toast",
        });
        setDeliverySSCC(null);
      } finally {
        setIsCreatingList(false);
      }
    },
    [
      employeeId,
      isDesadvBasedInboundingEnabled,
      setDeliverySSCC,
      fetchDesadvs,
      createSharedListMutation,
      listName,
      showToastUI,
    ],
  );

  const addItemsToSharedList = useCallback(async () => {
    setIsAddingItems(true);
    setInboundUIState({ isAddItemsToSharedListModalOpen: false });
    try {
      if (employeeId && sharedListId) {
        await addItemsToPreDroppingListMutation({
          variables: {
            input: {
              employeeId,
              listId: sharedListId,
              products: selectedProducts.map((product) => ({
                quantity: product.quantity,
                sku: product.productSku,
              })),
            },
          },
        });
      }
    } catch (error) {
      showToastUI({
        status: "error",
        id: ADD_ITEMS_SHARED_LIST_ERROR_TOAST_ID,
        title: "add_items_shared_list_error_toast",
      });
    } finally {
      setIsAddingItems(false);
    }
  }, [
    addItemsToPreDroppingListMutation,
    employeeId,
    selectedProducts,
    setInboundUIState,
    sharedListId,
    showToastUI,
  ]);

  const handleDespatchAdviceModalContinue = useCallback(async () => {
    setInboundUIState({ shouldShowDespatchAdviceModal: false });
    setDeliverySSCC(null);
    await createSharedList();
  }, [createSharedList, setDeliverySSCC, setInboundUIState]);

  const handleDespatchAdviceModalRetry = useCallback(() => {
    setInboundUIState({ shouldShowDespatchAdviceModal: false });
    setDeliverySSCC(null);
  }, [setDeliverySSCC, setInboundUIState]);

  const handleAddItemsModalSave = useCallback(async () => {
    setInboundUIState({ isAddItemsToSharedListModalOpen: false });
    await addItemsToSharedList();
  }, [addItemsToSharedList, setInboundUIState]);

  const handleAddItemsModalCancel = useCallback(() => {
    setInboundUIState({ isAddItemsToSharedListModalOpen: false });
  }, [setInboundUIState]);

  return {
    createSharedList,
    addItemsToSharedList,
    isCreatingList,
    isAddingItems,
    handleDespatchAdviceModalContinue,
    handleDespatchAdviceModalRetry,
    handleAddItemsModalSave,
    handleAddItemsModalCancel,
  };
};
