import { useEffect, useMemo } from "react";

import { Flex, Image } from "@chakra-ui/react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import shallow from "zustand/shallow";

import { routes } from "config/routes";
import { HEIGHT } from "core/components/Header";
import { openOngoingActivityModal } from "core/stores/useLayoutStore";
import { useSharedListCreator } from "flows/Inbound/stores/useSharedListCreator";
import DeliveryTruckColored from "images/random/delivery-truck-colored.svg";
import { Page } from "shared/components/Page";
import { useCountryCode } from "shared/hooks/useCountryCode";
import { useTimedActivityStore } from "shared/stores/useTimedActivityStore";
import { DropDown, DropDownProps } from "ui/DropDown/DropDown";
import { NavigationHeader } from "ui/NavigationHeader/NavigationHeader";
import { BodyM, HeaderS } from "ui/Typography/Typography";
import { isNotNullNorUndefined } from "utils/tsHelpers";

import { DeliveryType, useInboundStore } from "../../stores/useInboundStore";

const OPTIONS_BY_COUNTRY: Record<string, DeliveryType[]> = {
  de: [DeliveryType.REWE, DeliveryType.OtherDelivery, DeliveryType.NotDelivery],
  nl: [DeliveryType.Lekkerland, DeliveryType.OtherDelivery, DeliveryType.NotDelivery],
};

export type DeliveryOption = { key: DeliveryType; label: string };

function useHandleDeliverySelection() {
  const { setSelectedOption } = useInboundStore(
    (state) => ({
      setSelectedOption: state.setSelectedOption,
    }),
    shallow,
  );
  const navigate = useNavigate();
  const { createSharedList } = useSharedListCreator();

  return (delivery: string | null) => {
    const selectedOptionKey = delivery as DeliveryType;
    if (selectedOptionKey) {
      setSelectedOption(selectedOptionKey);
      if (
        selectedOptionKey === DeliveryType.REWE ||
        selectedOptionKey === DeliveryType.Lekkerland
      ) {
        navigate(routes.inbound.scanRolli);
      } else {
        createSharedList();
      }
    }
  };
}

export function SelectDeliveryPage() {
  const intl = useIntl();
  const navigate = useNavigate();
  const handleDeliverySelection = useHandleDeliverySelection();
  const { selectedOption, setSelectedOption } = useInboundStore(
    (state) => ({
      selectedOption: state.selectedOption,
      setSelectedOption: state.setSelectedOption,
    }),
    shallow,
  );

  const timedActivityInstance = useTimedActivityStore((state) => state.timedActivityInstance);
  const countryCode = useCountryCode();

  const allPossibleOptions: DeliveryOption[] = useMemo(
    () => [
      { key: DeliveryType.REWE, label: "REWE" },
      { key: DeliveryType.Lekkerland, label: "Lekkerland" },
      {
        key: DeliveryType.OtherDelivery,
        label: intl.formatMessage({ id: "flows.inbound.select-delivery.selection.other-delivery" }),
      },
      {
        key: DeliveryType.NotDelivery,
        label: intl.formatMessage({ id: "flows.inbound.select-delivery.selection.not-delivery" }),
      },
    ],
    [intl],
  );

  const selectionOptions = useMemo(() => {
    return OPTIONS_BY_COUNTRY[countryCode].map(
      (optionKey) => allPossibleOptions.find(({ key }) => key === optionKey) as DeliveryOption,
    );
  }, [countryCode, allPossibleOptions]);

  const dropDownProps = useMemo<DropDownProps>(() => {
    const props: DropDownProps = {
      options: selectionOptions,
      value: selectedOption,
      onInput: handleDeliverySelection,
    };
    if (isNotNullNorUndefined(timedActivityInstance)) {
      props.onClick = () => openOngoingActivityModal();
    }
    return props;
  }, [selectionOptions, selectedOption, handleDeliverySelection, timedActivityInstance]);

  useEffect(() => {
    setSelectedOption(null);
  }, [setSelectedOption]);

  return (
    <Page isFull isBgGrey h="100%">
      <NavigationHeader
        title={intl.formatMessage({
          id: "flows.inbound.inbound-pages.header",
        })}
        onClickGoBack={() => navigate(routes.inbound.root)}
      />
      <Flex
        flex={1}
        w="100%"
        h="100%"
        maxH={`calc(100% - ${HEIGHT})`}
        align="center"
        justify="center"
      >
        <Flex direction="column" align="center">
          <HeaderS mb="s100">
            <FormattedMessage id="flows.inbound.select-delivery.title" />
          </HeaderS>
          <BodyM textAlign="center">
            <FormattedMessage id="flows.inbound.select-delivery.description" />
          </BodyM>
          <Image src={DeliveryTruckColored} h="124px" my="s500" />
          <DropDown {...dropDownProps}>
            <FormattedMessage id="flows.inbound.select-delivery.selection.placeholder" />
          </DropDown>
        </Flex>
      </Flex>
    </Page>
  );
}
