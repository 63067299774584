import React, { useCallback } from "react";

import { useToast, UseToastOptions } from "@chakra-ui/react";
import { useIntl } from "react-intl";
import { v4 as uuidv4 } from "uuid";

import { IntlMessageId } from "shared/types/lang";

interface ToastUIOptions extends UseToastOptions {
  title?: IntlMessageId;
  description?: IntlMessageId | React.ReactNode;
}

export function useCustomToast() {
  const toast = useToast();
  const intl = useIntl();

  const showToastUI = useCallback(
    ({
      id,
      title,
      description,
      containerStyle = {
        alignItems: "center",
      },
      status = "error",
      duration = 4000,
      ...rest
    }: ToastUIOptions) => {
      const modifiedID = id || uuidv4();
      const formattedTitle = title
        ? intl.formatMessage({
            id: title,
          })
        : null;

      let formattedDescription;
      if (React.isValidElement(description)) {
        formattedDescription = description;
      } else {
        formattedDescription =
          description && typeof description === "string"
            ? intl.formatMessage({
                id: description as IntlMessageId,
              })
            : null;
      }
      if (toast.isActive && toast.isActive(modifiedID)) {
        return;
      }

      toast({
        id: modifiedID,
        title: formattedTitle,
        status,
        description: formattedDescription,
        containerStyle,
        duration,
        ...rest,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [intl, toast],
  );

  const closeToast = useCallback(
    (errorToastId: string) => {
      if (toast.isActive(errorToastId)) {
        toast.close(errorToastId);
      }
    },
    [toast],
  );

  return { showToastUI, closeToast };
}
