import { useCallback } from "react";

import { isApolloError } from "@apollo/client";

import { EppoFeatureFlags } from "core/types/flags";
import { useEppoFeatureFlagProvider } from "shared/hooks/useEppoFeatureFlag";
import { isNullOrUndefined } from "utils/tsHelpers";

import {
  ActionAddInboundUnitToDroppingList,
  ActionIncreaseInboundUnitInboundQuantity,
  InboundMachineContext,
} from "./types";

export function useInboundGuardsImplems() {
  const { isFeatureEnabled: isDeliveryBasedInboundingEnabled } = useEppoFeatureFlagProvider(
    EppoFeatureFlags.DELIVERY_BASED_INBOUNDING,
  );

  const { isFeatureEnabled: isDesadvBasedInboundingEnabled } = useEppoFeatureFlagProvider(
    EppoFeatureFlags.DESADV_BASED_INBOUNDING,
  );

  const guardDeliveryBasedInboundingEnabled = useCallback(() => {
    return (
      !isNullOrUndefined(isDeliveryBasedInboundingEnabled) &&
      isDeliveryBasedInboundingEnabled === true
    );
  }, [isDeliveryBasedInboundingEnabled]);

  const guardDeliveryBasedInboundingDisabled = useCallback(() => {
    return isDeliveryBasedInboundingEnabled === false || isDeliveryBasedInboundingEnabled === null;
  }, [isDeliveryBasedInboundingEnabled]);

  const shouldShowDesAdvModalGuard = useCallback(
    (_: InboundMachineContext, event: any) => {
      if (isDesadvBasedInboundingEnabled) {
        if (isApolloError(event.data)) {
          const errorCode = event.data.graphQLErrors?.[0]?.extensions?.code;
          return errorCode === "DESPATCH_ADVICE_NOT_FOUND";
        }
      }
      return false;
    },
    [isDesadvBasedInboundingEnabled],
  );

  const canAddInboundUnitToList = useCallback(
    (context: InboundMachineContext, event: ActionAddInboundUnitToDroppingList) => {
      return isNullOrUndefined(context.inboundUnitsMap[event.inboundUnit.productSku]);
    },
    [],
  );

  const shouldProceedWithDespatchAdviceFetch = useCallback(() => {
    return !!isDesadvBasedInboundingEnabled;
  }, [isDesadvBasedInboundingEnabled]);

  const canIncreaseInboundUnitInboundQuantity = (
    context: InboundMachineContext,
    event: ActionIncreaseInboundUnitInboundQuantity,
  ) => {
    const isInboundUnitBlocked = context.inboundUnitsStockUpdateState[event.sku]?.stockUpdated;
    return !isInboundUnitBlocked && !isDesadvBasedInboundingEnabled;
  };

  const shouldMoveProductToTopOnIncrease = () => !!isDesadvBasedInboundingEnabled;

  return {
    shouldShowDesAdvModalGuard,
    shouldProceedWithDespatchAdviceFetch,
    guardDeliveryBasedInboundingEnabled,
    guardDeliveryBasedInboundingDisabled,
    canAddInboundUnitToList,
    canIncreaseInboundUnitInboundQuantity,
    shouldMoveProductToTopOnIncrease,
  };
}
