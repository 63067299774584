import { cloneElement } from "react";

import { Flex, FlexProps } from "@chakra-ui/react";

import { TitleM } from "ui/Typography/Typography";

type ToastProps = {
  icon: React.ReactElement;
  title: string;
} & FlexProps;

export function Toast({ icon, title, ...rest }: ToastProps) {
  return (
    <Flex
      alignItems="center"
      borderRadius="sm"
      h="3rem"
      color="white"
      px="s200"
      gap="s200"
      {...rest}
    >
      {cloneElement(icon, { boxSize: "1.5rem" })}
      <TitleM color="white">{title}</TitleM>
    </Flex>
  );
}
