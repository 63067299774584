import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Box } from "@chakra-ui/react";
import { useSelector } from "@xstate/react";
import { useIntl } from "react-intl";
import shallow from "zustand/shallow";

import { useLayoutStore } from "core/stores/useLayoutStore";
import { EppoFeatureFlags } from "core/types/flags";
import { useSerialisedDespatchAdvice } from "flows/Inbound/hooks/useDespatchAdvice/useDespatchAdvice";
import {
  useSelectDespatchAdviceItems,
  useSelectInboundUnitListItemDataProps,
  useSelectInboundUnitStockData,
} from "flows/Inbound/hooks/useInboundMachineSelectors";
import { useInboundService } from "flows/Inbound/hooks/useInboundService";
import { useInboundUIStore } from "flows/Inbound/stores/useInboundUIStore";
import { useEppoFeatureFlagProvider } from "shared/hooks/useEppoFeatureFlag";
import useSwipeScroll from "shared/hooks/useSwipeScroll";
import { OutboundIcon, TrashIcon } from "ui/Icons/Icons";
import { InboundPreDroppingListItemCard } from "ui/InboundPreDroppingListItemCard/InboundPreDroppingListItemCard";
import { SideButton } from "ui/SideButton/SideButton";
import { SwipeScrollWrapper } from "ui/SwipeScrollWrapper";
import { isNullOrUndefined } from "utils/tsHelpers";

import { useQuantityInputField } from "../hooks/useQuantityInputField";

type PreDroppingInboundUnitListItemProps = {
  sku: string;
  showSwipeButtons: boolean;
  allowBlur: boolean;
  customScrollContainerRef?: React.RefObject<HTMLDivElement>;
  defaultTabBarState: boolean;
  allowEditHandlingUnitSize?: boolean;
  onSubmitQuantityChange?: () => void;
  openEditTotalQuantityModal?: (callback: () => void) => void;
};

export function PreDroppingInboundUnitListItem({
  sku,
  showSwipeButtons,
  allowBlur,
  customScrollContainerRef,
  defaultTabBarState,
  allowEditHandlingUnitSize = false,
  onSubmitQuantityChange = () => {},
  openEditTotalQuantityModal = () => {},
}: PreDroppingInboundUnitListItemProps) {
  const intl = useIntl();

  const translatedLabels = useMemo(
    () => ({
      singleUnitsLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.single-units-label",
      }),
      handlingUnitsLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.handling-units-label",
      }),
      inboundedLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.inbounded-label",
      }),
      totalUnitsLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.total-handling-units-label",
      }),
      bioLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.bio-label",
      }),
      nonBioLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.non-bio-label",
      }),
      partialOutboundLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.partial-outbound-label",
      }),
      outboundedLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.outbounded-label",
      }),
      outboundLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-list.outbound-label",
      }),
    }),
    [intl],
  );

  const { isFeatureEnabled: isDesadvBasedInboundingEnabled } = useEppoFeatureFlagProvider(
    EppoFeatureFlags.DESADV_BASED_INBOUNDING,
  );

  const {
    showPartialOutboundAnimationForSKU,
    skuToFocusQuantityInputFor,
    setInboundUIState,
    isEditingTotalQuantity,
    isEditingQuantity,
  } = useInboundUIStore(
    (state) => ({
      showPartialOutboundAnimationForSKU: state.showPartialOutboundAnimationForSKU,
      skuToFocusQuantityInputFor: state.skuToFocusQuantityInputFor,
      setInboundUIState: state.setInboundUIState,
      isEditingTotalQuantity: state.isEditingHUSize,
      isEditingQuantity: state.isEditingQuantity,
    }),
    shallow,
  );
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const scrollContainerRefFromStore = useLayoutStore((state) => state.scrollContainerRef);
  const { scrollContainerRef, handleSwipeScroll, snapWrapperBack } = useSwipeScroll(
    customScrollContainerRef ?? scrollContainerRefFromStore,
    wrapperRef,
  );

  const inboundService = useInboundService();

  const droppingListId = useSelector(inboundService, (state) => state.context.droppingListId);
  const inboundItemDataProps = useSelectInboundUnitListItemDataProps(sku);
  const {
    displayQuantity,
    inboundQuantity,
    stockUpdatePlanTotal,
    stockUpdated,
    unitSizeForDisplay,
  } = useSelectInboundUnitStockData(sku);
  const despatchAdviceItems = useSelectDespatchAdviceItems();
  const despatchAdviceItem = useSerialisedDespatchAdvice({
    sku,
    despatchAdviceItems,
    inboundQuantity,
  });

  const displayQuantityInContext = useMemo(() => {
    if (isDesadvBasedInboundingEnabled && isEditingTotalQuantity) return 1;
    return displayQuantity;
  }, [isDesadvBasedInboundingEnabled, isEditingTotalQuantity, displayQuantity]);

  const displayTotalQuantityInContext = useMemo(() => {
    if (isDesadvBasedInboundingEnabled && isEditingTotalQuantity) return unitSizeForDisplay;
    return stockUpdatePlanTotal;
  }, [
    isDesadvBasedInboundingEnabled,
    isEditingTotalQuantity,
    unitSizeForDisplay,
    stockUpdatePlanTotal,
  ]);

  const elementRef = useRef<HTMLDivElement | undefined>();

  const setQuantity = useCallback(
    ({ oldValue, newValue }: { oldValue: number; newValue: number }) => {
      if (isNullOrUndefined(droppingListId)) {
        return oldValue;
      }
      inboundService.send({
        type: "SET_INBOUND_UNIT_TOTAL_QUANTITY",
        sku,
        quantity: newValue,
        oldQuantity: oldValue,
      });
      return newValue;
    },
    [droppingListId, inboundService, sku],
  );

  const onClickRemoveProduct = useCallback(() => {
    inboundService.send({ type: "REMOVE_INBOUND_UNIT_FROM_DROPPING_LIST", sku });
  }, [inboundService, sku]);

  const onClickOutboundButton = useCallback(() => {
    inboundService.send({
      type: "GO_TO_OUTBOUND_PAGE",
      sku,
    });
  }, [sku, inboundService]);

  const {
    fieldProps: quantityFieldProps,
    focus: focusQuantityInput,
    blur: blurQuantityInput,
  } = useQuantityInputField({
    cardRef: elementRef,
    scrollContainerRef,
    defaultTabBarState,
    allowBlur,
    setValue: setQuantity,
    value: displayQuantity,
    onSubmit: onSubmitQuantityChange,
    applyValidationTransform: (rawValue: string) => {
      return rawValue === "" ? "1" : rawValue;
    },
  });

  const { fieldProps: totalQuantityFieldProps, focus: focusTotalQuantityInput } =
    useQuantityInputField({
      cardRef: elementRef,
      scrollContainerRef,
      defaultTabBarState,
      allowBlur,
      setValue: ({ newValue, oldValue }) => {
        if (newValue <= 1) {
          return oldValue;
        }
        inboundService.send({
          type: "SET_HANDLING_UNIT_SIZE",
          sku,
          size: newValue,
          oldSize: oldValue,
        });
        return newValue;
      },
      value: displayTotalQuantityInContext,
      uiEffects: {
        effect: () => {
          setInboundUIState({ isEditingHUSize: true });
          snapWrapperBack();
        },
        cleanup: () => {
          setInboundUIState({ isEditingHUSize: false });
        },
      },
      applyValidationTransform: (rawValue: string) => {
        return rawValue === "" || parseInt(rawValue, 10) < 2 ? null : rawValue;
      },
    });

  const startEditingTotalQuantity = useCallback(() => {
    setTimeout(() => focusTotalQuantityInput({ preventScroll: true }));
  }, [focusTotalQuantityInput]);

  const onClickTotalQuantityNumber = useCallback(() => {
    if (!allowEditHandlingUnitSize || stockUpdated) return;
    if (!isDesadvBasedInboundingEnabled && displayQuantity !== 1) return;

    if (isDesadvBasedInboundingEnabled) {
      openEditTotalQuantityModal(startEditingTotalQuantity);
    } else {
      startEditingTotalQuantity();
    }
  }, [
    isDesadvBasedInboundingEnabled,
    allowEditHandlingUnitSize,
    displayQuantity,
    stockUpdated,
    openEditTotalQuantityModal,
    startEditingTotalQuantity,
  ]);

  useEffect(() => {
    if (skuToFocusQuantityInputFor === sku) {
      focusQuantityInput({ preventScroll: true });
      setInboundUIState({ skuToFocusQuantityInputFor: undefined });
    } else if (skuToFocusQuantityInputFor === null) {
      blurQuantityInput();
      setInboundUIState({ skuToFocusQuantityInputFor: undefined });
    }
  }, [skuToFocusQuantityInputFor, setInboundUIState, sku, focusQuantityInput, blurQuantityInput]);

  const isOutbounded = stockUpdatePlanTotal === 0;

  const isPartiallyOutbounded = useMemo(
    () => stockUpdatePlanTotal < (inboundQuantity ?? 0) && !isOutbounded,
    [stockUpdatePlanTotal, inboundQuantity, isOutbounded],
  );

  const [showPartiallyOutboundedAnim] = useState(() => {
    return showPartialOutboundAnimationForSKU === sku;
  });

  useEffect(() => {
    if (showPartiallyOutboundedAnim) {
      elementRef.current?.scrollIntoView();
      setInboundUIState({ showPartialOutboundAnimationForSKU: null });
    }
  }, [showPartiallyOutboundedAnim, setInboundUIState]);

  const skusWithUnconfirmedQuantity = useSelector(
    inboundService,
    (state) => state.context.skusWithUnconfirmedQuantity,
  );

  const isQuantityConfirmed = useMemo(
    () => !skusWithUnconfirmedQuantity.includes(sku),
    [sku, skusWithUnconfirmedQuantity],
  );

  const confirmQuantity = useCallback(() => {
    inboundService.send({ type: "CONFIRM_QUANTITY", sku });
  }, [inboundService, sku]);

  const showQuantityConfirmation = useMemo(() => {
    if (!isDesadvBasedInboundingEnabled || !despatchAdviceItem) return false;

    return (
      despatchAdviceItem.isTotalInboundQuantityMatchingExpectation ||
      despatchAdviceItem.expectedNumberOfUnits === displayQuantity ||
      despatchAdviceItem.expectedHUSize === unitSizeForDisplay
    );
  }, [isDesadvBasedInboundingEnabled, despatchAdviceItem, displayQuantity, unitSizeForDisplay]);

  const isQuantityMatchingExpectation = useMemo(() => {
    if (!isDesadvBasedInboundingEnabled || !isQuantityConfirmed || !despatchAdviceItem) return null;

    return (
      despatchAdviceItem.isTotalInboundQuantityMatchingExpectation ||
      despatchAdviceItem.expectedNumberOfUnits === displayQuantity
    );
  }, [isDesadvBasedInboundingEnabled, isQuantityConfirmed, despatchAdviceItem, displayQuantity]);

  const isTotalQuantityMatchingExpectation = useMemo(() => {
    if (
      !isDesadvBasedInboundingEnabled ||
      !isQuantityConfirmed ||
      !despatchAdviceItem ||
      isEditingTotalQuantity
    )
      return true;

    return unitSizeForDisplay === despatchAdviceItem.expectedHUSize;
  }, [
    isDesadvBasedInboundingEnabled,
    isQuantityConfirmed,
    despatchAdviceItem,
    isEditingTotalQuantity,
    unitSizeForDisplay,
  ]);

  const mainComponent = (
    <InboundPreDroppingListItemCard
      elementRef={(element) => {
        elementRef.current = element;
      }}
      stockUpdatePlanTotal={displayTotalQuantityInContext}
      {...translatedLabels}
      translatedInboundUnit={inboundItemDataProps}
      quantityFieldProps={quantityFieldProps}
      displayQuantity={displayQuantityInContext}
      isStockUpdateComplete={stockUpdated}
      displayAsHandlingUnit={unitSizeForDisplay > 1}
      isPartiallyOutbounded={isPartiallyOutbounded}
      showPartiallyOutboundedAnimation={showPartiallyOutboundedAnim}
      isOutbounded={isOutbounded}
      isEditingQuantity={isEditingQuantity}
      isEditingTotal={isEditingTotalQuantity}
      onClickTotalQuantityNumber={onClickTotalQuantityNumber}
      totalQuantityFieldProps={totalQuantityFieldProps}
      onConfirmQuantity={confirmQuantity}
      showExpectedItems={!!despatchAdviceItem}
      expectedUnitType={despatchAdviceItem?.expectedUnitType}
      expectedNumberOfUnits={despatchAdviceItem?.expectedNumberOfUnits}
      showQuantityConfirmation={showQuantityConfirmation}
      isQuantityMatchingExpectation={isQuantityMatchingExpectation}
      isTotalQuantityMatchingExpectation={isTotalQuantityMatchingExpectation}
    />
  );

  const scrolledComponent = (
    <Box flex="0 0 137px">
      <SideButton
        color="red.500"
        bgColor="red.100"
        icon={<TrashIcon boxSize={6} />}
        label={intl.formatMessage({
          id: "components.inbound.pre-dropping-list-item.delete-label",
          defaultMessage: "Delete",
        })}
        onClick={onClickRemoveProduct}
        data-testid="remove-product"
      />
      <SideButton
        color="purple.500"
        bgColor="purple.100"
        icon={<OutboundIcon boxSize={6} />}
        label={intl.formatMessage({
          id: "components.inbound.pre-dropping-list-item.outbound-label",
          defaultMessage: "Outbound",
        })}
        onClick={onClickOutboundButton}
        data-testid="go-to-inbound-outbound-page"
      />
    </Box>
  );

  return (
    <SwipeScrollWrapper
      mainComponent={mainComponent}
      handleSwipeScroll={handleSwipeScroll}
      scrolledComponent={stockUpdated && showSwipeButtons ? undefined : scrolledComponent}
      showSwipeButtons={showSwipeButtons}
      ref={wrapperRef}
    />
  );
}
