import { createContext, useMemo } from "react";

import { useInterpret } from "@xstate/react";
import { Outlet } from "react-router";
import { InterpreterFrom } from "xstate";

import { useInterpreterRouteSynchronizer } from "shared/hooks/useInterpreterRouteSynchronizer";
import { useProductSearchActionsImplems } from "shared/machines/productSearchMachine/actions";
import { useProductSearchGuardsImplems } from "shared/machines/productSearchMachine/guards";
import {
  productSearchMachine,
  PRODUCT_SEARCH_MACHINE_NAME,
} from "shared/machines/productSearchMachine/machine";
import { useProductSearchServiceImplems } from "shared/machines/productSearchMachine/services";
import {
  ProductSearchEvents,
  ProductSearchMachineContext,
} from "shared/machines/productSearchMachine/types";
import { keepTrackOfStateForDebugging } from "utils/xstate";

export const XStateProductSearchContext = createContext({
  productSearchService: {} as InterpreterFrom<typeof productSearchMachine>,
});

export function XStateProductSearchProvider() {
  const actions = useProductSearchActionsImplems();
  const services = useProductSearchServiceImplems();
  const guards = useProductSearchGuardsImplems();

  const productSearchService = useInterpret(
    productSearchMachine,
    { actions, services, guards },
    ({ value }) => keepTrackOfStateForDebugging("inboundProductSearch", value),
  );

  useInterpreterRouteSynchronizer<ProductSearchMachineContext, ProductSearchEvents>(
    PRODUCT_SEARCH_MACHINE_NAME,
    productSearchService,
  );

  const values = useMemo(() => ({ productSearchService }), [productSearchService]);

  return (
    <XStateProductSearchContext.Provider value={values}>
      <Outlet />
    </XStateProductSearchContext.Provider>
  );
}
