import { useCallback } from "react";

import { Flex } from "@chakra-ui/react";
import { useNavigate } from "react-router";
import shallow from "zustand/shallow";

import { routes } from "config/routes";
import { ProductsSearchComponent } from "flows/Inbound/components/ProductsSearchComponent";
import { ProductsSearchInboundUnitsResultsList } from "flows/Inbound/components/ProductsSearchInboundUnitsResultsList";
import { ProductsSearchNoEANSubcategories } from "flows/Inbound/components/ProductsSearchNoEANSubcategories";
import { Page } from "shared/components/Page";
import {
  ProductSearchActionTypes,
  useProductSearchStore,
} from "shared/stores/useProductSearchStore";

export function ManualProductsSearchPage() {
  const navigate = useNavigate();

  const { activeState, setSearchState, resetTextSearch } = useProductSearchStore(
    (state) => ({
      textSearch: state.textSearch,
      activeState: state.activeState,
      setSearchState: state.setSearchState,
      resetTextSearch: state.resetTextSearch,
    }),
    shallow,
  );

  const activateTextSearch = useCallback(() => {
    setSearchState(ProductSearchActionTypes.ACTIVATE_TEXT_SEARCH);
  }, [setSearchState]);

  const cancelSearchFlow = useCallback(() => {
    resetTextSearch();
    navigate(routes.inbound.preDropping);
  }, [navigate, resetTextSearch]);

  const shouldShowTextSearchResults = activeState === ProductSearchActionTypes.RESOLVE_TEXT_SEARCH;

  return (
    <Page h="100%" pos="relative" isFull isBgGrey data-testid="inbound-manual-search-page">
      <Flex direction="column" justify="flex-start" h="100%" width="100%">
        <ProductsSearchComponent
          isSearchFlowActive
          activateTextSearch={activateTextSearch}
          cancelSearchFlow={cancelSearchFlow}
        />
        {shouldShowTextSearchResults ? (
          <ProductsSearchInboundUnitsResultsList />
        ) : (
          <ProductsSearchNoEANSubcategories />
        )}
      </Flex>
    </Page>
  );
}
