import { useMemo } from "react";

import { shallowEqual, useSelector } from "@xstate/react";

import { useAppLanguageStore } from "core/stores/useAppLanguageStore";
import { useGetCheckByIdQuery } from "flows/Inventory/shared/queries/check/check.generated";

import { serializeAndTranslateCheck } from "../../../shared/models/check/serializer";
import { useStockCheckService } from "./useStockCheckService";

export function useStockCheckTaskData() {
  const stockCheckService = useStockCheckService();
  const appLanguage = useAppLanguageStore((state) => state.appLanguage);

  const { checkId } = useSelector(stockCheckService, (state) => state.context, shallowEqual);

  const { data: taskData } = useGetCheckByIdQuery({
    variables: { checkId: checkId! },
  });

  return useMemo(() => {
    return serializeAndTranslateCheck(taskData?.getTaskById?.task!, appLanguage);
  }, [taskData, appLanguage]);
}
