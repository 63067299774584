import type { SystemStyleFunction } from "@chakra-ui/theme-tools";

const variantSolid: SystemStyleFunction = () => {
  return {
    container: {
      color: "white",
      bg: "veggie.500",
      my: "s400",
    },
    icon: {
      w: "4",
    },
  };
};

const variantSuccess: SystemStyleFunction = () => {
  return {
    container: {
      color: "white",
      bg: "veggie.500",
    },
    icon: {
      w: "4",
    },
  };
};

export const Alert = {
  variants: {
    completed: variantSolid,
    success: variantSuccess,
  },
};
