import { useCallback, useMemo, useState } from "react";

import { Image } from "@chakra-ui/react";
import { FormattedMessage, useIntl } from "react-intl";

import { openOngoingActivityModal } from "core/stores/useLayoutStore";
import { useInboundService } from "flows/Inbound/hooks/useInboundService";
import DeliveryTruckColored from "images/random/delivery-truck-colored.svg";
import { Page } from "shared/components/Page";
import { useCountryCode } from "shared/hooks/useCountryCode";
import { useTimedActivityStore } from "shared/stores/useTimedActivityStore";
import { DropDown, DropDownProps } from "ui/DropDown/DropDown";
import { BodyM, HeaderS } from "ui/Typography/Typography";
import { isNotNullNorUndefined } from "utils/tsHelpers";

export type DeliveryType = "REWE" | "Lekkerland" | "other-delivery" | "not-delivery";

const OPTIONS_BY_COUNTRY: Record<string, DeliveryType[]> = {
  de: ["REWE", "other-delivery", "not-delivery"],
  nl: ["Lekkerland", "other-delivery", "not-delivery"],
};

export type DeliveryOption = { key: DeliveryType; label: string; skip: boolean };

export function InboundSelectDeliveryPage() {
  const inboundService = useInboundService();
  const intl = useIntl();

  const timedActivityInstance = useTimedActivityStore((state) => state.timedActivityInstance);

  const countryCode = useCountryCode();

  const allPossibleOptions: DeliveryOption[] = useMemo(
    () => [
      { key: "REWE", label: "REWE", skip: false },
      { key: "Lekkerland", label: "Lekkerland", skip: false },
      {
        key: "other-delivery",
        label: intl.formatMessage({ id: "flows.inbound.select-delivery.selection.other-delivery" }),
        skip: true,
      },
      {
        key: "not-delivery",
        label: intl.formatMessage({ id: "flows.inbound.select-delivery.selection.not-delivery" }),
        skip: true,
      },
    ],
    [intl],
  );

  const selectionOptions = useMemo(() => {
    return OPTIONS_BY_COUNTRY[countryCode].map(
      (optionKey) => allPossibleOptions.find(({ key }) => key === optionKey) as DeliveryOption,
    );
  }, [countryCode, allPossibleOptions]);

  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  const selectDelivery = useCallback(
    (delivery: string | null) => {
      const selectedOptionKey = delivery as DeliveryType;
      setSelectedOption(selectedOptionKey);
      setTimeout(() => {
        const selectedOptionConfig = allPossibleOptions.find(
          ({ key }) => key === selectedOptionKey,
        )!;
        if (selectedOptionConfig.skip) {
          inboundService.send({ type: "SKIP_DELIVERY", deliveryType: selectedOptionKey });
          return;
        }
        inboundService.send({ type: "SELECT_DELIVERY", deliveryType: selectedOptionKey });
      }, 80);
    },
    [allPossibleOptions, inboundService],
  );

  const dropDownProps = useMemo<DropDownProps>(() => {
    const props: DropDownProps = {
      options: selectionOptions,
      value: selectedOption,
      onInput: selectDelivery,
    };
    if (isNotNullNorUndefined(timedActivityInstance)) {
      // need to add the onClick prop this way, otherwise it'll overwrite the component's onClick prop even when set to undefined
      props.onClick = () => openOngoingActivityModal();
    }
    return props;
  }, [selectionOptions, selectedOption, selectDelivery, timedActivityInstance]);

  return (
    <Page isCentered isBgGrey>
      <HeaderS mb="s100">
        <FormattedMessage id="flows.inbound.select-delivery.title" />
      </HeaderS>
      <BodyM textAlign="center">
        <FormattedMessage id="flows.inbound.select-delivery.description" />
      </BodyM>

      <Image src={DeliveryTruckColored} h="124px" my="s500" />

      <DropDown {...dropDownProps}>
        <FormattedMessage id="flows.inbound.select-delivery.selection.placeholder" />
      </DropDown>
    </Page>
  );
}
