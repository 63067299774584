// Preparation for the forthcoming routing refactor
export const routes = {
  inbound: {
    root: "/inbound",
    selectDelivery: "/inbound/select-delivery",
    scanRolli: "/inbound/scan-rolli",
    preDropping: "/inbound/pre-dropping",
    outbound: "/inbound/outbound",
    listVerification: "/inbound/list-verification",
    dropping: "/inbound/dropping",
    manualSearch: "/inbound/search",
    noEANProducts: "/inbound/search/non-ean-products",
    sharedList: "/inbound/shared-list",
    claimedList: "/inbound/claimed-list",
  },
  inventory: {
    root: "/inventory",
    restockingList: {
      // restocking flow
      root: "/inventory/restocking-list",
      publicList: "/inventory/restocking-list/public-list",
      moveItems: "/inventory/restocking-list/move-items",
      dropOff: "/inventory/restocking-list/drop-off",
      textSearch: "/inventory/restocking-list/search/text",
      shelfSearch: "/inventory/restocking-list/search/shelf",
    },
    stockCorrections: {
      root: "/inventory/stock-correction", // home to stock correction flow
      declare: "/inventory/stock-correction/declare",
      selectProduct: "/inventory/stock-correction/select-product",
    },
    bbdCorrections: {
      root: "/inventory/bbd-correction",
      declare: "/inventory/bbd-correction/declare",
      selectProduct: "/inventory/bbd-correction/select-product",
    },
    stockChecks: {
      // stockCheck flow
      root: "/inventory/stock-checks",
      detail: "/inventory/stock-checks/:checkId",
      quantity: "/inventory/stock-checks/:checkId/quantity",
      update: "/inventory/stock-checks/:checkId/update",
    },
    freshChecks: {
      // freshCheck flow
      root: "/inventory/fresh-checks",
      detail: "/inventory/fresh-checks/:checkId",
      quantity: "/inventory/fresh-checks/:checkId/quantity",
    },
    bbdChecks: {
      // bbdCheck flow
      root: "/inventory/bbd-check",
      detail: "/inventory/bbd-check/:checkId",
      declareExpired: "/inventory/bbd-check/:checkId/declare-expired",
      declareClosestBdd: "/inventory/bbd-check/:checkId/update",
    },
    eoyChecks: {
      // eoyChecks flow
      root: "/inventory/eoy-checks",
      detail: "/inventory/eoy-checks/:checkId/quantity",
    },
    eoyRecountChecks: {
      // eoyRecountChecks flow
      root: "/inventory/recount-checks",
      detail: "/inventory/recount-checks/:checkId/quantity",
    },
  },
  activities: {
    root: "/",
    deliveryCheckIn: "/delivery-check-in",
    featureFlagConfig: "/feature-flag-config",
    timedActivities: {
      list: "/timed-activities/list",
      details: "/timed-activities/:activityId",
    },
  },
  picking: {
    root: "/picking",
    start: "/picking/start",
    manual: "/picking/manual",
    containers: "/picking/containers",
    shelves: "/picking/shelves",
    end: "/picking/end",
    incomplete: "/picking/incomplete",
  },
};
