import { useCallback } from "react";

import { datadogLogs } from "@datadog/browser-logs";
import { useIntl } from "react-intl";
import shallow from "zustand/shallow";

import { InventoryChangeReason } from "__graphql__/types";
import {
  InboundProgressedAction,
  InboundProgressedMethod,
  InboundUpdateState,
  InboundVerificationReason,
  PageName,
} from "analytics/events";
import { useLayoutStore } from "core/stores/useLayoutStore";
import { EppoFeatureFlags } from "core/types/flags";
import { ListVerificationCheckTypeKeys } from "flows/Inbound/models/listVerificationCheck/types";
import { ActionTypes, useInboundUIStore } from "flows/Inbound/stores/useInboundUIStore";
import { useAnalytics } from "shared/hooks/useAnalytics";
import { useCustomToast } from "shared/hooks/useCustomToast";
import { useEppoFeatureFlagProvider } from "shared/hooks/useEppoFeatureFlag";
import { useHubSlug } from "shared/hooks/useHubSlug";
import { BOTTOM_TAB_BAR_HEIGHT_NUMBER } from "ui/BottomTabBarContainer";
import { WarningRoundFilledIcon } from "ui/Icons/Icons";
import { Toast } from "ui/Toast";
import { isEmptyObject, isNotNullNorUndefined, isNullOrUndefined } from "utils/tsHelpers";

import { useDespatchAdviceCallback } from "../../hooks/useDespatchAdvice/useDespatchAdvice";
import {
  ActionAddInboundUnitToDroppingList,
  ActionFinishDropping,
  ActionIncreaseInboundUnitInboundQuantity,
  ActionRemoveInboundUnitFromDroppingList,
  ActionStartDropping,
  ActionSetInboundUnitQuantitiesByReason,
  InboundMachineContext,
  ActionSetInboundUnitTotalQuantity,
  ActionStartListVerification,
  ActionCompleteListVerificationCheck,
  ActionSetHandlingUnitSize,
  ActionSkipRolliValidation,
  InboundServiceDoneInvokeEvents,
} from "./types";
import { extractInboundPlan, getMatchedtDesadvId, sumStockUpdatePlanTotal } from "./utils";

const updateInboundUnitProductStockErrorToastId = "update_inbound_unit_product_stock_error_toast";
const setDeliverySuccessToastId = "set_delivery_success_toast";
const productNotAssignedError = "product-not-assigned-error";
const unableToCreateSkuHubAssociationError = "unable-create-sku-hub-association-error";
const editHUSizeSuccessToastId = "edit-hu-size-success-toast";

type LoggerInfoEventType =
  | ActionSkipRolliValidation
  | ActionAddInboundUnitToDroppingList
  | InboundServiceDoneInvokeEvents["fetchMultipleDesadvs"]
  | InboundServiceDoneInvokeEvents["updateInboundUnitProductStock"];

export function useInboundActionImplems() {
  const { showToastUI } = useCustomToast();

  const intl = useIntl();
  const hubSlug = useHubSlug();

  const { sendSegmentTrackEvent } = useAnalytics();

  const scrollContainerRef = useLayoutStore((state) => state.scrollContainerRef);
  const { showDespatchAdviceInfoBanner, setInboundUIState } = useInboundUIStore(
    (state) => ({
      showDespatchAdviceInfoBanner: state.showDespatchAdviceInfoBanner,
      setInboundUIState: state.setInboundUIState,
    }),
    shallow,
  );
  const isDesadvBasedInboundingEnabled = useEppoFeatureFlagProvider(
    EppoFeatureFlags.DESADV_BASED_INBOUNDING,
  );

  const showSetDeliverySuccess = () => {
    setInboundUIState({ showDespatchAdviceInfoBanner: ActionTypes.ADD_ITEM });
    showToastUI({
      id: setDeliverySuccessToastId,
      status: "success",
      containerStyle: {
        alignItems: "center",
        bottom: BOTTOM_TAB_BAR_HEIGHT_NUMBER,
        position: "absolute",
        paddingLeft: "s200",
        paddingRight: "s200",
      },
      title: "hooks.use-set-delivery-success.success-title",
    });
  };

  const onUpdateInboundUnitProductStockError = () => {
    sendSegmentTrackEvent("errorShown", {
      screen_name: PageName.INBOUND_DROPPING_PAGE,
      component_value: "inbounding_request_failed",
    });
    showToastUI({
      id: updateInboundUnitProductStockErrorToastId,
      title: "hooks.use-update-inbound-unit-product-stock.error-title",
      description: "hooks.use-update-inbound-unit-product-stock.error-description",
    });
  };

  const showProductNotAssignedForTheHubError = () => {
    showToastUI({
      id: productNotAssignedError,
      title: "hooks.use-inbound-action-implems.product-not-assigned-error",
    });
  };

  const showUnableToCreateSkuHubAssociationError = () => {
    showToastUI({
      id: unableToCreateSkuHubAssociationError,
      title: "hooks.use-inbound-action-implems.association-creation-failed-error",
    });
  };

  const scrollToPageTop = useCallback(() => {
    scrollContainerRef.current?.scrollTo({ top: 0, behavior: "smooth" });
  }, [scrollContainerRef]);

  const trackInboundStateUpdated = () => {
    sendSegmentTrackEvent("inboundStateUpdated", {
      state: "activity_started",
      dropping_list_id: "",
      sscc: null,
      inbounding_type: null,
    });
  };

  const sendInboundStateUpdated = useCallback(
    (
      ctx: InboundMachineContext,
      event:
        | ActionAddInboundUnitToDroppingList
        | ActionStartDropping
        | ActionFinishDropping
        | ActionStartListVerification,
    ) => {
      const eventToInboundUpdateState: Record<string, InboundUpdateState> = {
        ADD_INBOUND_UNIT_TO_DROPPING_LIST: "list_preparation_started",
        START_DROPPING: "dropping_list_started",
        FINISH_DROPPING: "dropping_list_finished",
        START_LIST_VERIFICATION: "list_verification_started",
      };
      const eventState = eventToInboundUpdateState[event.type];

      if (isNullOrUndefined(ctx.droppingListId) || isNullOrUndefined(eventState)) {
        datadogLogs.logger.info(
          "Missing droppingListId or eventState in sendInboundStateUpdated action",
          {
            inboundMachineContext: ctx,
          },
        );
        return;
      }

      sendSegmentTrackEvent("inboundStateUpdated", {
        state: eventState,
        dropping_list_id: ctx.droppingListId,
        sscc: ctx.deliverySSCC ?? null,
        inbounding_type: ctx.deliveryType ?? null,
      });
    },
    [sendSegmentTrackEvent],
  );

  const sendInboundProgressed = useCallback(
    (
      ctx: InboundMachineContext,
      event:
        | ActionAddInboundUnitToDroppingList
        | ActionIncreaseInboundUnitInboundQuantity
        | ActionRemoveInboundUnitFromDroppingList
        | ActionSetInboundUnitQuantitiesByReason
        | ActionSetInboundUnitTotalQuantity
        | ActionSetHandlingUnitSize,
    ) => {
      const sku =
        event.type === "ADD_INBOUND_UNIT_TO_DROPPING_LIST"
          ? event.inboundUnit.productSku
          : event.sku;
      const { droppingListId, deliverySSCC } = ctx;

      if (isNullOrUndefined(droppingListId)) {
        datadogLogs.logger.info("Missing droppingListId in sendInboundProgressed action", {
          inboundMachineContext: ctx,
        });
        return;
      }

      let method: InboundProgressedMethod | undefined;

      if ("origin" in event) {
        switch (event.origin) {
          case "scan": {
            method = "scanned";
            break;
          }
          case "textSearch": {
            method = "text_searched";
            break;
          }
          case "noEANSearch": {
            method = "advanced_searched";
            break;
          }
          default:
        }
      }

      const inboundUnitStockUpdateState = ctx.inboundUnitsStockUpdateState[sku];
      const currentUnitSize = ctx.inboundUnitsDisplayStates[sku].unitSizeForDisplay;

      let action: InboundProgressedAction;
      let quantity: number;
      let temporaryHandlingUnitSize;

      switch (event.type) {
        case "ADD_INBOUND_UNIT_TO_DROPPING_LIST": {
          action = "product_added_to_list";
          quantity = currentUnitSize;
          break;
        }
        case "INCREASE_INBOUND_UNIT_INBOUND_QUANTITY": {
          action = "product_updated_quantity";
          quantity = event.quantity;
          break;
        }
        case "REMOVE_INBOUND_UNIT_FROM_DROPPING_LIST": {
          action = "product_removed_from_list";
          quantity = sumStockUpdatePlanTotal(inboundUnitStockUpdateState.stockUpdatePlan);
          break;
        }
        case "SET_INBOUND_UNIT_QUANTITIES_BY_REASON": {
          const oldQuantity =
            extractInboundPlan(inboundUnitStockUpdateState.stockUpdatePlan)?.quantityDelta ?? 0;
          const newQuantity = extractInboundPlan(event.quantitiesByReason)?.quantity ?? oldQuantity;
          quantity = newQuantity - oldQuantity;
          if (quantity === 0) {
            return;
          }
          action = "product_updated_quantity";
          break;
        }
        case "SET_INBOUND_UNIT_TOTAL_QUANTITY": {
          action = "product_updated_quantity";
          method = "manual_su";
          quantity = (event.quantity - event.oldQuantity) * currentUnitSize;
          break;
        }
        case "SET_HANDLING_UNIT_SIZE": {
          action = "product_updated_quantity";
          method = "manual_hu";
          quantity = event.size - event.oldSize;
          temporaryHandlingUnitSize = event.size;
          break;
        }
        default: {
          return;
        }
      }

      sendSegmentTrackEvent("inboundProgressed", {
        action,
        method,
        quantity: quantity > 0 ? quantity : 0,
        dropping_list_id: droppingListId,
        product_sku: sku,
        is_handling_unit: currentUnitSize !== 1,
        sscc: deliverySSCC ?? null,
        shelf_number: null,
        temporary_handling_unit_size: temporaryHandlingUnitSize,
      });
    },
    [sendSegmentTrackEvent],
  );

  const sendOutboundProgressed = useCallback(
    (ctx: InboundMachineContext, event: ActionSetInboundUnitQuantitiesByReason) => {
      const { deliverySSCC, droppingListId } = ctx;

      if (isNullOrUndefined(droppingListId)) {
        datadogLogs.logger.info("Missing droppingListId in sendOutboundProgressed action", {
          inboundMachineContext: ctx,
        });
        return;
      }

      const quantityReceived =
        extractInboundPlan(ctx.inboundUnitsStockUpdateState[event.sku].stockUpdatePlan)
          ?.quantityDelta ?? 0;

      const [quantityExpired, quantityDamaged] = [
        InventoryChangeReason.outbound_delivery_product_expired,
        InventoryChangeReason.outbound_delivery_product_damaged,
      ].map((inventoryChangeReason) => {
        const newValue = event.quantitiesByReason.find(
          ({ reason }) => reason === inventoryChangeReason,
        )?.quantity;
        if (isNotNullNorUndefined(newValue)) {
          return newValue;
        }
        return (
          ctx.inboundUnitsStockUpdateState[event.sku].stockUpdatePlan.find(
            ({ reason }) => reason === inventoryChangeReason,
          )?.quantityDelta ?? 0
        );
      });
      sendSegmentTrackEvent("outboundProgressed", {
        action: "product_added_to_outbound",
        quantity_expired: -quantityExpired,
        quantity_damaged: -quantityDamaged,
        quantity_perished: 0,
        quantity_received: quantityReceived,
        dropping_list_id: droppingListId,
        product_sku: event.sku,
        sscc: deliverySSCC ?? null,
        shelf_number: null,
      });
    },
    [sendSegmentTrackEvent],
  );

  const getSelectDespatchAdviceBySku = useDespatchAdviceCallback();

  const focusInboundQuantityField = useCallback(
    (ctx: InboundMachineContext, event: ActionAddInboundUnitToDroppingList) => {
      const { productSku, quantity } = event.inboundUnit;
      const { skusWithUnconfirmedQuantity } = ctx;
      if (isDesadvBasedInboundingEnabled && !!skusWithUnconfirmedQuantity.length) {
        setInboundUIState({ skuToFocusQuantityInputFor: null });
      } else {
        setInboundUIState({ skuToFocusQuantityInputFor: quantity === 1 ? productSku : null });
      }
    },
    [isDesadvBasedInboundingEnabled, setInboundUIState],
  );

  const showDesAdvAskModalAction = useCallback(() => {
    setInboundUIState({ shouldShowDespatchAdviceModal: true });
  }, [setInboundUIState]);

  const sendInboundVerificationProgressed = useCallback(
    (ctx: InboundMachineContext, event: ActionCompleteListVerificationCheck) => {
      const { droppingListId, deliverySSCC, listVerificationChecks, inboundUnitsStockUpdateState } =
        ctx;
      const { key, index } = event;

      if (isNullOrUndefined(droppingListId)) {
        datadogLogs.logger.info(
          "Missing droppingListId in sendInboundVerificationProgressed action",
          {
            inboundMachineContext: ctx,
          },
        );
        return;
      }

      const check = listVerificationChecks.find((c) => c.key === key);

      if (isNullOrUndefined(check)) {
        datadogLogs.logger.info("Cannot find check in sendInboundVerificationProgressed action", {
          inboundMachineContext: ctx,
        });
        return;
      }

      const inboundStockUpdate = extractInboundPlan(
        inboundUnitsStockUpdateState[check.sku].stockUpdatePlan,
      );

      if (isNullOrUndefined(inboundStockUpdate)) {
        datadogLogs.logger.info(
          "Missing inbound stock update in sendInboundVerificationProgressed action",
          {
            inboundMachineContext: ctx,
          },
        );
        return;
      }

      const VERIFICATION_CHECK_TYPE_TO_TRACKING_REASON_MAP: Record<
        ListVerificationCheckTypeKeys,
        InboundVerificationReason
      > = {
        [ListVerificationCheckTypeKeys.InboundQuantityOfOne]: "quantity_equal_to_one",
        [ListVerificationCheckTypeKeys.LargeInboundQuantity]: "quantity_more_than_fifty",
        [ListVerificationCheckTypeKeys.HandlingUnitQuantityMismatch]:
          "quantity_handling_unit_mismatch",
      };

      const quantityBeforeVerification =
        check.type === ListVerificationCheckTypeKeys.InboundQuantityOfOne ? 1 : check.data.quantity;

      sendSegmentTrackEvent("inboundVerificationProgressed", {
        dropping_list_id: droppingListId,
        sscc: deliverySSCC ?? null,
        product_sku: check.sku,
        verification_reason: VERIFICATION_CHECK_TYPE_TO_TRACKING_REASON_MAP[check.type],
        quantity_before_verification: quantityBeforeVerification,
        quantity_after_verification:
          inboundStockUpdate.quantityDelta > 0 ? inboundStockUpdate.quantityDelta : 0,
        running_number: index + 1,
      });
    },
    [sendSegmentTrackEvent],
  );

  const showDespatchAdviceInfoBannerIfApplicable = useCallback(
    (context: InboundMachineContext, event: ActionAddInboundUnitToDroppingList) => {
      const despatchAdviceItem = getSelectDespatchAdviceBySku(
        event.inboundUnit.productSku,
        context.despatchAdviceItems,
      );
      if (
        isDesadvBasedInboundingEnabled &&
        showDespatchAdviceInfoBanner === ActionTypes.ADD_ITEM &&
        despatchAdviceItem
      ) {
        setInboundUIState({ showDespatchAdviceInfoBanner: ActionTypes.CHECK_QUANTITIES });
      }
    },
    [
      isDesadvBasedInboundingEnabled,
      showDespatchAdviceInfoBanner,
      getSelectDespatchAdviceBySku,
      setInboundUIState,
    ],
  );

  const hideDespatchAdviceInfoBanner = useCallback(
    (context: InboundMachineContext) => {
      if (
        showDespatchAdviceInfoBanner === ActionTypes.CHECK_QUANTITIES &&
        !context.skusWithUnconfirmedQuantity.length
      ) {
        setInboundUIState({ showDespatchAdviceInfoBanner: ActionTypes.RESET_STATE });
      }
    },
    [setInboundUIState, showDespatchAdviceInfoBanner],
  );

  const addLoggerInfoForDesAdv = useCallback(
    (context: InboundMachineContext, event: LoggerInfoEventType) => {
      const logInfo: Record<string, any> = {
        despatchAdviceData: isEmptyObject(context.despatchAdviceItems)
          ? null
          : Object.keys(context.despatchAdviceItems)[0],
        hubSlug,
        rolliID: context.deliverySSCC,
      };

      if ("data" in event) {
        const isUpdateInboundUnitProductStockEvent = typeof event.data === "string";
        if (typeof event.data === "string") {
          const sku = event.data;
          const matchingKey = getMatchedtDesadvId(sku, context.despatchAdviceItems);
          logInfo.SKU = sku;
          logInfo.despatchAdviceData = matchingKey;
        }

        datadogLogs.logger.info(
          isUpdateInboundUnitProductStockEvent
            ? "Inbound product : Information about despatchAdvice, rolliID, SKU and hubSlug"
            : "Scan Rolli : Information about despatchAdvice, rolliID and hubSlug",
          logInfo,
        );
      } else if (event.type === "SKIP_ROLLI_VALIDATION") {
        datadogLogs.logger.info(
          "Scan Rolli : Information about despatchAdvice, rolliID and hubSlug",
          logInfo,
        );
      } else {
        const sku = event.inboundUnit.productSku;
        const matchingKey = getMatchedtDesadvId(sku, context.despatchAdviceItems);
        datadogLogs.logger.info("Scan product: Information about despatchAdvice, SKU and hubSlug", {
          despatchAdviceData: matchingKey,
          SKU: sku,
          hubSlug,
        });
      }
    },
    [hubSlug],
  );

  const showDespatchAdviceQuantityMismatchBanner = useCallback(
    (context: InboundMachineContext, event: ActionSetInboundUnitTotalQuantity) => {
      if (!isDesadvBasedInboundingEnabled) return;
      const { quantity, sku } = event;
      const { unitSizeForDisplay } = context.inboundUnitsDisplayStates[sku];
      const despatchAdviceItem = getSelectDespatchAdviceBySku(sku, context.despatchAdviceItems);
      if (!despatchAdviceItem) return;
      const showQuantityMismatchBanner =
        despatchAdviceItem.totalQuantity !== quantity * unitSizeForDisplay;
      setInboundUIState({ showQuantityMismatchBanner });
    },
    [getSelectDespatchAdviceBySku, isDesadvBasedInboundingEnabled, setInboundUIState],
  );

  const hideQuantityMismatchBanner = useCallback(() => {
    setInboundUIState({ showQuantityMismatchBanner: false });
  }, [setInboundUIState]);

  const showEditHUSizeSuccessToast = useCallback(() => {
    showToastUI({
      id: editHUSizeSuccessToastId,
      containerStyle: {
        width: "100%",
        bottom: BOTTOM_TAB_BAR_HEIGHT_NUMBER,
        position: "absolute",
        paddingLeft: "s200",
        paddingRight: "s200",
      },
      render: () => (
        <Toast
          icon={<WarningRoundFilledIcon color="white" />}
          title={intl.formatMessage({
            id: "flows.inbound.pre-dropping.edit-total-quantity.success-toast",
          })}
          bgColor="nightBlueFlink.500"
        />
      ),
    });
  }, [showToastUI, intl]);

  const showProductAlreadyAddedBanner = useCallback(() => {
    setInboundUIState({ showProductAlreadyAddedBanner: true });
  }, [setInboundUIState]);

  const hideProductAlreadyAddedBanner = useCallback(() => {
    setInboundUIState({ showProductAlreadyAddedBanner: false });
  }, [setInboundUIState]);

  return {
    onUpdateInboundUnitProductStockError,
    showProductNotAssignedForTheHubError,
    showUnableToCreateSkuHubAssociationError,
    showSetDeliverySuccess,
    sendInboundStateUpdated,
    sendInboundProgressed,
    sendOutboundProgressed,
    sendInboundVerificationProgressed,
    scrollToPageTop,
    focusInboundQuantityField,
    showDesAdvAskModalAction,
    showDespatchAdviceInfoBannerIfApplicable,
    hideDespatchAdviceInfoBanner,
    showDespatchAdviceQuantityMismatchBanner,
    hideQuantityMismatchBanner,
    addLoggerInfoForDesAdv,
    showEditHUSizeSuccessToast,
    showProductAlreadyAddedBanner,
    hideProductAlreadyAddedBanner,
    trackInboundStateUpdated,
  };
}
