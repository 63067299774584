import { FormattedMessage } from "react-intl";

import { Banner } from "ui/Banner/Banner";
import { transformChunks } from "utils/ui";

import { useInboundUIStore } from "../stores/useInboundUIStore";

export function HUSizeQuantityMismatchBanner() {
  const showQuantityMismatchBanner = useInboundUIStore((state) => state.showQuantityMismatchBanner);
  const setInboundUIState = useInboundUIStore((state) => state.setInboundUIState);

  const onClose = () => {
    setInboundUIState({
      showQuantityMismatchBanner: false,
    });
  };
  if (!showQuantityMismatchBanner) {
    return null;
  }

  return (
    <Banner
      subTitle={
        <FormattedMessage
          id="components.inbound.hu-size-quantity-mismatch-banner.description"
          values={transformChunks({ pink: { as: "strong", color: "pinkFlink.500" } })}
        />
      }
      pos="fixed"
      top="55px"
      zIndex={100}
      subTitleSize="s"
      onClose={onClose}
    />
  );
}
