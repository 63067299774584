export function getShelfDetail(shelf: string) {
  const explode = shelf.match(/\D+|\d+/g);

  return {
    shelfNumber: explode === null ? "???" : explode[0],
    shelfLetter: explode === null ? "?" : explode[1],
  };
}

type ItemWithShelf = {
  shelfNumber?: string | null;
  shelfLetter?: string | null;
  shelf?: string | null;
  name?: string | null;
};

export type SortOrder = "asc" | "desc";

export function sortByShelf(a: ItemWithShelf, b: ItemWithShelf, order: SortOrder = "asc") {
  const orderMultiplier = order === "asc" ? 1 : -1;
  const sortRes = (() => {
    if (a.shelfNumber && a.shelfLetter && b.shelfNumber && b.shelfLetter) {
      if (a.shelfNumber === b.shelfNumber) {
        return a.shelfLetter < b.shelfLetter ? -1 : 1;
      }
      return parseInt(a.shelfNumber, 10) < parseInt(b.shelfNumber, 10) ? -1 : 1;
    }
    if (!a.shelf && b.shelf) {
      return 1;
    }
    if (a.shelf && !b.shelf) {
      return -1;
    }
    return a.name?.localeCompare(b.name ?? "", "en-US") ?? 0;
  })();
  return sortRes * orderMultiplier;
}
