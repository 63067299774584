import { useCallback, useState } from "react";

import { chakra } from "@chakra-ui/react";
import { useSelector } from "@xstate/react";
import { FormattedMessage, useIntl } from "react-intl";

import { PageName } from "analytics/events";
import { usePickingService } from "flows/Picking/hooks/usePickingService";
import { Page } from "shared/components/Page";
import { ScannableInput } from "shared/components/ScannableInput";
import { useAnalytics } from "shared/hooks/useAnalytics";
import { Button } from "ui/Button/Button";
import { HeaderS } from "ui/Typography/Typography";
import { getErrorMessage } from "utils/error";
import { getOrderNumberFromQRCode } from "utils/scan";

import { serializeOrderToStartPicking } from "../models/order/serializer";
import { useStartManualPickingMutation } from "../queries/order/order.generated";

const MIN_ORDER_NUMBER_LENGTH = 16;

const isValidOrderNumber = (orderNumber: string) => {
  return orderNumber !== "" && orderNumber.trim().length >= MIN_ORDER_NUMBER_LENGTH;
};

export function ManualPickingPage() {
  const intl = useIntl();

  const pickingService = usePickingService();

  const [orderNumberValue, setOrderNumberValue] = useState<string>("");

  const { sendSegmentTrackEvent } = useAnalytics();
  const [startManualPicking, { error, loading, reset }] = useStartManualPickingMutation();

  const handleChangeInputValue = (value: string) => {
    reset();
    setOrderNumberValue(value);
  };

  // We have discovered a bug in the app that OAs sometimes press the back button, and orders are stuck in the picking state.
  // So, we are adding this check to redirect users to the stuck page if they are not in the correct state.
  const isEndPickingIdle = useSelector(pickingService, (state) => state.matches("endPicking.idle"));
  const isStartManualPicking = useSelector(pickingService, (state) =>
    state.matches("picking.startManualPicking"),
  );
  if (!isEndPickingIdle || !isStartManualPicking) {
    pickingService.send({ type: "GO_TO_STUCK" });
  }

  const startPicking = useCallback(async (orderNumber: string) => {
    const orderNumberParsed = getOrderNumberFromQRCode(orderNumber);
    if (!isValidOrderNumber(orderNumberParsed)) {
      return;
    }

    try {
      const { data } = await startManualPicking({
        variables: {
          orderNumber: orderNumberParsed,
        },
      });
      if (data?.startManualPickingV2) {
        pickingService.send({
          type: "START_PICKING",
          order: serializeOrderToStartPicking(data?.startManualPickingV2),
          origin: "QR_CODE",
        });
      }
    } catch (err) {
      sendSegmentTrackEvent("errorShown", {
        screen_name: PageName.MANUAL_PICKING_PAGE,
        component_value: getErrorMessage(err),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFormSubmit = useCallback(
    (e?: React.FormEvent<HTMLFormElement>) => {
      e?.preventDefault();
      startPicking(orderNumberValue);
    },
    [startPicking, orderNumberValue],
  );

  const isSubmitButtonDisabled = loading || !isValidOrderNumber(orderNumberValue);

  const errorMessage = error
    ? intl.formatMessage({ id: "pages.picking.manual-picking.input-error-default" })
    : undefined;
  return (
    <Page isCentered>
      <HeaderS textAlign="center">
        <FormattedMessage id="pages.picking.manual-picking.title" />
      </HeaderS>
      <chakra.form w="100%" onSubmit={onFormSubmit}>
        <ScannableInput
          error={errorMessage}
          placeholder={intl.formatMessage({
            id: "pages.picking.manual-picking.input-placeholder",
          })}
          onScan={(scannedValue) => {
            startPicking(scannedValue);
          }}
          isAutoFocus
          onValueChange={handleChangeInputValue}
        />

        <Button
          isLoading={loading}
          isDisabled={isSubmitButtonDisabled}
          onClick={() => startPicking(orderNumberValue)}
          size="lg"
          flinkVariant="primary"
          data-testid="button-submit-button"
          width="full"
          mt="s300"
        >
          {intl.formatMessage({ id: "pages.picking.manual-picking.submit-button-label" })}
        </Button>
      </chakra.form>
    </Page>
  );
}
