import { useCallback, useEffect } from "react";

import { Flex } from "@chakra-ui/react";
import { useNavigate } from "react-router";
import shallow from "zustand/shallow";

import { routes } from "config/routes";
import { HEIGHT } from "core/components/Header";
import { openOngoingActivityModal } from "core/stores/useLayoutStore";
import { EppoFeatureFlags } from "core/types/flags";
import { AddItemsToSharedListButton } from "flows/Inbound/components/AddItemsToSharedListButton";
import { DespatchAdviceInfoBanner } from "flows/Inbound/components/DespatchAdviceInfoBanner";
import { EditHandlingUnitsBanner } from "flows/Inbound/components/EditHandlingUnitsBanner";
import { HUSizeQuantityMismatchBanner } from "flows/Inbound/components/HUSizeQuantityMismatchBanner";
import { InboundIndexInstructions } from "flows/Inbound/components/InboundIndexInstructions";
import { PreDroppingListPreparation } from "flows/Inbound/components/PreDroppingListPreparation";
import { ProductAlreadyAddedBanner } from "flows/Inbound/components/ProductAlreadyAddedBanner";
import { ProductsSearchComponent } from "flows/Inbound/components/ProductsSearchComponent";
import { SharedListCreationConfirmationModal } from "flows/Inbound/components/SharedListCreationConfirmationModal";
import { SharedListUnsavedChangesWarningModal } from "flows/Inbound/components/SharedListUnsavedChangesWarningModal";
import { UnverifiedQuantityModal } from "flows/Inbound/components/UnverifiedQuantityModal";
import { useInboundStore } from "flows/Inbound/stores/useInboundStore";
import { ActionTypes, useInboundUIStore } from "flows/Inbound/stores/useInboundUIStore";
import { useSharedListCreator } from "flows/Inbound/stores/useSharedListCreator";
import { Page } from "shared/components/Page";
import { SpinnerModal } from "shared/components/SpinnerModal";
import { useEppoFeatureFlagProvider } from "shared/hooks/useEppoFeatureFlag";
import {
  ProductSearchActionTypes,
  useProductSearchStore,
} from "shared/stores/useProductSearchStore";
import { useTimedActivityStore } from "shared/stores/useTimedActivityStore";
import { NavigationHeader } from "ui/NavigationHeader/NavigationHeader";
import { isNotNullNorUndefined } from "utils/tsHelpers";

export function PreDroppingListPage() {
  const navigate = useNavigate();

  const { isFeatureEnabled: isDeliveryBasedInboundingEnabled } = useEppoFeatureFlagProvider(
    EppoFeatureFlags.DELIVERY_BASED_INBOUNDING,
  );
  const { isFeatureEnabled: isDesadvBasedInboundingEnabled } = useEppoFeatureFlagProvider(
    EppoFeatureFlags.DESADV_BASED_INBOUNDING,
  );

  const timedActivityInstance = useTimedActivityStore((state) => state.timedActivityInstance);

  const { sharedListName } = useInboundStore(
    (state) => ({
      sharedListName: state.sharedListName,
    }),
    shallow,
  );

  const {
    resetNoEANStore,
    showDespatchAdviceInfoBanner,
    showProductAlreadyAddedBanner,
    isUnverifiedQuantityModalOpen,
    isAddItemsToSharedListModalOpen,
    isSharedListUnsavedChangesWarningModalOpen,
    setInboundUIState,
  } = useInboundUIStore(
    (state) => ({
      resetNoEANStore: state.resetNoEANStore,
      showDespatchAdviceInfoBanner: state.showDespatchAdviceInfoBanner,
      showProductAlreadyAddedBanner: state.showProductAlreadyAddedBanner,
      isUnverifiedQuantityModalOpen: state.isUnverifiedQuantityModalOpen,
      isAddItemsToSharedListModalOpen: state.isAddItemsToSharedListModalOpen,
      isSharedListUnsavedChangesWarningModalOpen: state.isSharedListUnsavedChangesWarningModalOpen,
      setInboundUIState: state.setInboundUIState,
    }),
    shallow,
  );

  const { selectedProducts, setSearchState, setSelectedProducts } = useProductSearchStore(
    (state) => ({
      selectedProducts: state.selectedProducts,
      setSearchState: state.setSearchState,
      setSelectedProducts: state.setSelectedProducts,
    }),
    shallow,
  );

  const { isAddingItems, handleAddItemsModalSave, handleAddItemsModalCancel } =
    useSharedListCreator();

  // TO_DO
  // const hasUnconfirmedQuantities = useSelector(
  //   inboundService,
  //   (state) => !!state.context.skusWithUnconfirmedQuantity.length,
  // );

  const activateSearchFlow = useCallback(() => {
    // TO_DO
    // if (hasUnconfirmedQuantities) {
    //   setInboundUIState({ isUnverifiedQuantityModalOpen: true });
    //   return;
    // }
    if (isNotNullNorUndefined(timedActivityInstance)) {
      openOngoingActivityModal();
      return;
    }
    resetNoEANStore();
    setSearchState(ProductSearchActionTypes.ACTIVATE_SEARCH);
    navigate(routes.inbound.manualSearch);
  }, [timedActivityInstance, resetNoEANStore, setSearchState, navigate]);

  const preDroppingListIsEmpty = !selectedProducts.length;

  const closeProductAlreadyAddedBanner = () => {
    setInboundUIState({ showProductAlreadyAddedBanner: false });
  };

  const onVerifyQuantityButton = () => {
    setInboundUIState({ isUnverifiedQuantityModalOpen: false });
  };

  const shouldShowDespatchAdviceInfoBanner =
    isDesadvBasedInboundingEnabled && showDespatchAdviceInfoBanner === ActionTypes.CHECK_QUANTITIES;

  useEffect(() => {
    const keyboard = (navigator as any).virtualKeyboard;
    if (!keyboard) {
      return () => {};
    }
    const defaultKeyboardState = keyboard.overlaysContent;
    keyboard.overlaysContent = true;
    return () => {
      keyboard.overlaysContent = defaultKeyboardState;
    };
  }, []);

  useEffect(() => {
    if (!sharedListName) navigate(routes.inbound.root);
  }, [navigate, sharedListName]);

  if (!sharedListName) return null;

  if (isAddingItems) return <SpinnerModal isOpen />;

  return (
    <Page isFull isBgGrey h="100%">
      <NavigationHeader
        title={sharedListName}
        onClickGoBack={() => {
          if (!preDroppingListIsEmpty) {
            setInboundUIState({ isSharedListUnsavedChangesWarningModalOpen: true });
          } else {
            navigate(routes.inbound.root);
          }
        }}
      />
      {!preDroppingListIsEmpty &&
        isDesadvBasedInboundingEnabled &&
        showProductAlreadyAddedBanner && (
          <ProductAlreadyAddedBanner onClose={closeProductAlreadyAddedBanner} />
        )}
      <ProductsSearchComponent isSearchFlowActive={false} activateTextSearch={activateSearchFlow} />
      {preDroppingListIsEmpty ? (
        <Flex
          flex={1}
          w="100%"
          h="100%"
          maxH={`calc(100% - ${HEIGHT})`}
          align="center"
          justify="center"
          p="s200"
        >
          <InboundIndexInstructions />
        </Flex>
      ) : (
        <Flex
          direction="column"
          flex={1}
          w="100%"
          h="100%"
          maxH="calc(100% - 129px)"
          justify="flex-start"
          bg="grey.100"
          overflowX="scroll"
        >
          <PreDroppingListPreparation />
          <AddItemsToSharedListButton />
        </Flex>
      )}
      {!isDesadvBasedInboundingEnabled && <EditHandlingUnitsBanner />}
      {isDeliveryBasedInboundingEnabled && <HUSizeQuantityMismatchBanner />}
      {shouldShowDespatchAdviceInfoBanner && <DespatchAdviceInfoBanner />}
      {/* TO_DO <PreDroppingListModals /> */}
      <UnverifiedQuantityModal
        isOpen={isUnverifiedQuantityModalOpen}
        onVerifyQuantity={onVerifyQuantityButton}
      />
      <SharedListUnsavedChangesWarningModal
        isOpen={isSharedListUnsavedChangesWarningModalOpen}
        onClickYes={() => {
          setSelectedProducts([]);
          setInboundUIState({ isSharedListUnsavedChangesWarningModalOpen: false });
          navigate(routes.inbound.root);
        }}
        onClickNo={() => setInboundUIState({ isSharedListUnsavedChangesWarningModalOpen: false })}
      />
      <SharedListCreationConfirmationModal
        isOpen={isAddItemsToSharedListModalOpen}
        onClickYes={handleAddItemsModalSave}
        onClickNo={handleAddItemsModalCancel}
      />
    </Page>
  );
}
