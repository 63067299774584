import create from "zustand";

export enum ProductSearchActionTypes {
  IDLE = "IDLE", // Represents the idle state of the app when no search or fetch is active
  ACTIVATE_SEARCH = "ACTIVATE_SEARCH", // Generic search activation (text, category, or non-scannable)
  ACTIVATE_TEXT_SEARCH = "ACTIVATE_TEXT_SEARCH", // When text search is specifically triggered
  RESOLVE_TEXT_SEARCH = "RESOLVE_TEXT_SEARCH", // When text search results are resolved
  ACTIVATE_CATEGORY_SEARCH = "ACTIVATE_CATEGORY_SEARCH", // When category/subcategory search is triggered
  ACTIVATE_NON_SCANNABLE_SEARCH = "ACTIVATE_NON_SCANNABLE_SEARCH", // When non-EAN subcategories are searched
}

type ProductSearchStore = {
  textSearch: string;
  selectedProducts: any[];
  nonScannableCategories: any[];
  productsForSubcategory: any[];
  selectedSubcategoryID: string | null;
  activeState: string;
};

type ProductSearchStoreWithMethods = ProductSearchStore & {
  setSearchState: (newState: string) => void;
  setTextSearch: (query: string) => void;
  setSelectedProducts: (selectedProducts: any[]) => void;
  setNonScannableCategories: (categories: any[]) => void;
  setProductsForSubcategory: (products: any[]) => void;
  setSubcategoryID: (subcategoryID: string) => void;
  resetTextSearch: () => void;
};

const initialState: ProductSearchStore = {
  textSearch: "",
  selectedProducts: [],
  nonScannableCategories: [],
  productsForSubcategory: [],
  selectedSubcategoryID: null,
  activeState: ProductSearchActionTypes.IDLE,
};

export const useProductSearchStore = create<ProductSearchStoreWithMethods>((set) => ({
  ...initialState,
  setSearchState: (newState) => set({ activeState: newState }),
  setTextSearch: (query) =>
    set({ activeState: ProductSearchActionTypes.RESOLVE_TEXT_SEARCH, textSearch: query }),
  setSelectedProducts: (selectedProducts) => set({ selectedProducts }),
  setNonScannableCategories: (categories) => set({ nonScannableCategories: categories }),
  setProductsForSubcategory: (products) => set({ productsForSubcategory: products }),
  setSubcategoryID: (subcategoryID) => set({ selectedSubcategoryID: subcategoryID }),
  resetTextSearch: () => set({ activeState: ProductSearchActionTypes.IDLE, textSearch: "" }),
}));
