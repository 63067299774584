import * as Types from "../../../../../__graphql__/types.js";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type GetProductStockQueryVariables = Types.Exact<{
  sku: Types.Scalars["ID"];
}>;

export type GetProductStockQuery = {
  __typename?: "Query";
  getProduct: {
    __typename?: "IProduct";
    numberOfShelfFacings?: number | null;
    isShelvedInHandlingUnits?: boolean | null;
    inventoryEntry: {
      __typename?: "InventoryEntry";
      stock: { __typename?: "Stock"; shelf: number };
    };
  };
};

export const GetProductStockDocument = gql`
  query getProductStock($sku: ID!) {
    getProduct(input: { sku: $sku }) {
      numberOfShelfFacings
      isShelvedInHandlingUnits
      inventoryEntry {
        stock {
          shelf
        }
      }
    }
  }
`;

/**
 * __useGetProductStockQuery__
 *
 * To run a query within a React component, call `useGetProductStockQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductStockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductStockQuery({
 *   variables: {
 *      sku: // value for 'sku'
 *   },
 * });
 */
export function useGetProductStockQuery(
  baseOptions: Apollo.QueryHookOptions<GetProductStockQuery, GetProductStockQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductStockQuery, GetProductStockQueryVariables>(
    GetProductStockDocument,
    options,
  );
}
export function useGetProductStockLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetProductStockQuery, GetProductStockQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProductStockQuery, GetProductStockQueryVariables>(
    GetProductStockDocument,
    options,
  );
}
export type GetProductStockQueryHookResult = ReturnType<typeof useGetProductStockQuery>;
export type GetProductStockLazyQueryHookResult = ReturnType<typeof useGetProductStockLazyQuery>;
export type GetProductStockQueryResult = Apollo.QueryResult<
  GetProductStockQuery,
  GetProductStockQueryVariables
>;
