import create from "zustand";
import { devtools } from "zustand/middleware";

export enum DeliveryType {
  REWE = "REWE",
  Lekkerland = "Lekkerland",
  OtherDelivery = "other-delivery",
  NotDelivery = "not-delivery",
}
type DeliveryStore = {
  selectedOption: DeliveryType | null;
  deliverySSCC: string | null;
  sharedListName: string | null;
  sharedListId: string | null;
};

type DeliveryStoreWithMethods = DeliveryStore & {
  setSelectedOption(delivery: DeliveryType | null): void;
  setDeliverySSCC(deliverySSCC: string | null): void;
  setSharedListName(sharedListName: string | null): void;
  setSharedListId(sharedListId: string | null): void;
};

const initialState: DeliveryStore = {
  selectedOption: null,
  deliverySSCC: null,
  sharedListName: null,
  sharedListId: null,
};

export const useInboundStore = create<DeliveryStoreWithMethods>()(
  devtools((set) => ({
    ...initialState,
    setSelectedOption: (delivery) => set({ selectedOption: delivery }),
    setDeliverySSCC: (deliverySSCC) => set({ deliverySSCC }),
    setSharedListName: (sharedListName) => set({ sharedListName }),
    setSharedListId: (sharedListId) => set({ sharedListId }),
  })),
);
