import { useCallback, useMemo, useRef, useState } from "react";

import { AddIcon } from "@chakra-ui/icons";
import { Button, chakra, Flex } from "@chakra-ui/react";
import { useSelector } from "@xstate/react";
import { FormattedMessage, useIntl } from "react-intl";

import { GoToAssignShelvesButton } from "flows/Picking/components/GoToAssignShelvesButton";
import { SkipScanningButton } from "flows/Picking/components/SkipScanningButton";
import { usePickingService } from "flows/Picking/hooks/usePickingService";
import { Page } from "shared/components/Page";
import { ScannableInput, ScannableInputHandle } from "shared/components/ScannableInput";
import { IntlMessageId } from "shared/types/lang";
import { RemovableListItem } from "ui/RemovableListItem/RemovableListItem";
import { BodyM, HeaderS } from "ui/Typography/Typography";

const isContainerIdValid = (input: string | undefined) => {
  if (input) {
    return /^[a-z0-9]{5,8}$/i.test(input);
  }

  return false;
};

export function ScanContainersPage() {
  const intl = useIntl();

  const pickingService = usePickingService();

  const { send } = pickingService;
  const [errorTextId, setErrorTextId] = useState<IntlMessageId | undefined>(undefined);
  const refInput = useRef<ScannableInputHandle>();
  const [typedValue, setTypedValue] = useState("");
  const [isAddButtonVisible, setIsAddButtonVisible] = useState(false);

  const containersIds = useSelector(pickingService, (state) => state.context.containersIds);

  const endPickingLoading = useSelector(pickingService, (state) =>
    state.matches("endPicking.loading"),
  );

  const handleValueChange = (value: string) => {
    setTypedValue(value);
    if (isContainerIdValid(value)) {
      setIsAddButtonVisible(true);
      setErrorTextId(undefined);
    }
  };

  const addContainer = useCallback(
    (newContainerId: string) => {
      if (newContainerId === "") {
        return false;
      }
      if (!isContainerIdValid(newContainerId)) {
        setErrorTextId("pages.picking.scan-container.input-error-invalid-format");
        return false;
      }
      if (containersIds.find((id) => id === newContainerId)) {
        setErrorTextId("pages.picking.scan-container.input-error-already-scanned");
        return false;
      }

      send({ type: "ADD_CONTAINER", containerId: newContainerId });
      setIsAddButtonVisible(false);
      setTypedValue("");
      refInput?.current?.resetValue();
      return true;
    },
    [containersIds, send],
  );

  const onClickSkipButton = () => {
    send({ type: "RESET_CONTAINERS" });
    send({ type: "GO_TO_ASSIGN_SHELVES" });
  };

  const onFormSubmit = useCallback(
    (e?: React.FormEvent<HTMLFormElement>) => {
      e?.preventDefault();
      if (!typedValue) return true;
      send({ type: "SET_INPUT_METHOD", inputMethod: "MANUAL" });
      return addContainer(typedValue);
    },
    [addContainer, send, typedValue],
  );

  const endScanContainersButton = useMemo(() => {
    const isButtonDisabled = !containersIds.length && !isContainerIdValid(typedValue);
    return (
      <GoToAssignShelvesButton
        mt="s300"
        isButtonDisabled={isButtonDisabled}
        onValidateContinue={onFormSubmit}
      />
    );
  }, [containersIds, onFormSubmit, typedValue]);

  return (
    <Page isCentered scrollBehavior="auto">
      <HeaderS textAlign="center">
        <FormattedMessage id="pages.picking.scan-container.title" />
      </HeaderS>
      <chakra.form onSubmit={onFormSubmit} alignItems="center" w="100%">
        {containersIds.map((containerId) => (
          <RemovableListItem
            key={containerId}
            title={containerId.slice(-5)}
            onClickRemove={() => send({ type: "REMOVE_CONTAINER", containerId })}
          />
        ))}

        <ScannableInput
          ref={refInput}
          inputName="container-id"
          error={errorTextId ? intl.formatMessage({ id: errorTextId }) : undefined}
          placeholder={intl.formatMessage({
            id: "pages.picking.scan-container.input-placeholder",
          })}
          onScan={(scannedValue) => {
            send({ type: "SET_INPUT_METHOD", inputMethod: "SCAN" });
            addContainer(scannedValue);
          }}
          isAutoFocus
          onValueChange={handleValueChange}
        />
        {isAddButtonVisible && !errorTextId && (
          <Flex pt="s100" justify="flex-end">
            <Button
              bg="pinkFlink.200"
              aria-label="Add container"
              alignSelf="flex-end"
              data-testid="add-container-button"
              onClick={() => {
                send({ type: "SET_INPUT_METHOD", inputMethod: "MANUAL" });
                addContainer(typedValue);
              }}
            >
              <AddIcon mr="s100" color="pinkFlink.500" />
              <BodyM color="pinkFlink.500">
                {intl.formatMessage({
                  id: "pages.picking.scan-container.add-container-button",
                })}
              </BodyM>
            </Button>
          </Flex>
        )}
        {endScanContainersButton}
      </chakra.form>
      <SkipScanningButton onClickSkipButton={onClickSkipButton} isDisabled={endPickingLoading} />
    </Page>
  );
}
