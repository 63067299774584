import { useCallback, useEffect } from "react";

import { Flex } from "@chakra-ui/react";
import { useSelector } from "@xstate/react";
import shallow from "zustand/shallow";

import { openOngoingActivityModal } from "core/stores/useLayoutStore";
import { EppoFeatureFlags } from "core/types/flags";
import { DespatchAdviceInfoBanner } from "flows/Inbound/components/DespatchAdviceInfoBanner";
import { EditHandlingUnitsBanner } from "flows/Inbound/components/EditHandlingUnitsBanner";
import { HUSizeQuantityMismatchBanner } from "flows/Inbound/components/HUSizeQuantityMismatchBanner";
import { InboundIndexInstructions } from "flows/Inbound/components/InboundIndexInstructions";
import { ListVerificationButton } from "flows/Inbound/components/ListVerificationButton";
import { PreDroppingInboundUnitsList } from "flows/Inbound/components/PreDroppingInboundUnitsList";
import { PreDroppingListModals } from "flows/Inbound/components/PreDroppingListModals";
import { ProductAlreadyAddedBanner } from "flows/Inbound/components/ProductAlreadyAddedBanner";
import { ProductSearchBar } from "flows/Inbound/components/ProductSearchBar";
import { UnverifiedQuantityModal } from "flows/Inbound/components/UnverifiedQuantityModal";
import { useInboundIndexEanScan } from "flows/Inbound/hooks/useInboundIndexEanScan";
import { useSelectPreDroppingListInboundUnitsSkusSortedByAddedDate } from "flows/Inbound/hooks/useInboundMachineSelectors";
import { useInboundService } from "flows/Inbound/hooks/useInboundService";
import { ActionTypes, useInboundUIStore } from "flows/Inbound/stores/useInboundUIStore";
import { Page } from "shared/components/Page";
import { useEppoFeatureFlagProvider } from "shared/hooks/useEppoFeatureFlag";
import { useProductSearchService } from "shared/hooks/useProductSearchService";
import { useTimedActivityStore } from "shared/stores/useTimedActivityStore";
import { isNotNullNorUndefined } from "utils/tsHelpers";

export function InboundPreDroppingList() {
  useInboundIndexEanScan();

  const inboundUnitsSortedByAddedDate = useSelectPreDroppingListInboundUnitsSkusSortedByAddedDate();
  const {
    resetNoEANStore,
    showDespatchAdviceInfoBanner,
    showProductAlreadyAddedBanner,
    isUnverifiedQuantityModalOpen,
    setInboundUIState,
  } = useInboundUIStore(
    (state) => ({
      resetNoEANStore: state.resetNoEANStore,
      showDespatchAdviceInfoBanner: state.showDespatchAdviceInfoBanner,
      showProductAlreadyAddedBanner: state.showProductAlreadyAddedBanner,
      isUnverifiedQuantityModalOpen: state.isUnverifiedQuantityModalOpen,
      setInboundUIState: state.setInboundUIState,
    }),
    shallow,
  );
  const productSearchService = useProductSearchService();
  const inboundService = useInboundService();
  const { send: sendProductSearch } = productSearchService;
  const { isFeatureEnabled: isDeliveryBasedInboundingEnabled } = useEppoFeatureFlagProvider(
    EppoFeatureFlags.DELIVERY_BASED_INBOUNDING,
  );

  const timedActivityInstance = useTimedActivityStore((state) => state.timedActivityInstance);

  const { isFeatureEnabled: isDesadvBasedInboundingEnabled } = useEppoFeatureFlagProvider(
    EppoFeatureFlags.DESADV_BASED_INBOUNDING,
  );

  const hasUnconfirmedQuantities = useSelector(
    inboundService,
    (state) => !!state.context.skusWithUnconfirmedQuantity.length,
  );

  const activateSearchFlow = useCallback(() => {
    if (hasUnconfirmedQuantities) {
      setInboundUIState({ isUnverifiedQuantityModalOpen: true });
      return;
    }
    if (isNotNullNorUndefined(timedActivityInstance)) {
      openOngoingActivityModal();
      return;
    }
    resetNoEANStore();
    sendProductSearch({ type: "ACTIVATE_SEARCH" });
  }, [
    hasUnconfirmedQuantities,
    timedActivityInstance,
    resetNoEANStore,
    sendProductSearch,
    setInboundUIState,
  ]);

  const preDroppingListIsEmpty = inboundUnitsSortedByAddedDate.length === 0;

  const closeProductAlreadyAddedBanner = () => {
    setInboundUIState({ showProductAlreadyAddedBanner: false });
  };

  const onVerifyQuantityButton = () => {
    setInboundUIState({ isUnverifiedQuantityModalOpen: false });
  };

  const shouldShowDespatchAdviceInfoBanner =
    isDesadvBasedInboundingEnabled && showDespatchAdviceInfoBanner === ActionTypes.CHECK_QUANTITIES;

  const shouldShowListVerificationButton =
    !isDesadvBasedInboundingEnabled || !hasUnconfirmedQuantities;

  useEffect(() => {
    const keyboard = (navigator as any).virtualKeyboard;
    if (!keyboard) {
      return () => {};
    }
    const defaultKeyboardState = keyboard.overlaysContent;
    keyboard.overlaysContent = true;
    return () => {
      keyboard.overlaysContent = defaultKeyboardState;
    };
  }, []);

  return (
    <Page h="100%" pos="relative" isFull isBgGrey data-testid="inbound-pre-dropping-list-page">
      {!isDesadvBasedInboundingEnabled && <EditHandlingUnitsBanner />}
      {isDeliveryBasedInboundingEnabled && <HUSizeQuantityMismatchBanner />}
      {shouldShowDespatchAdviceInfoBanner && <DespatchAdviceInfoBanner />}
      <Flex
        direction="column"
        justify="flex-start"
        h="100%"
        width="100%"
        marginTop={shouldShowDespatchAdviceInfoBanner ? "240px" : undefined}
      >
        <ProductSearchBar isSearchFlowActive={false} activateTextSearch={activateSearchFlow} />
        {preDroppingListIsEmpty ? (
          <InboundIndexInstructions />
        ) : (
          <>
            {isDesadvBasedInboundingEnabled && showProductAlreadyAddedBanner && (
              <ProductAlreadyAddedBanner onClose={closeProductAlreadyAddedBanner} />
            )}
            <PreDroppingInboundUnitsList />
            {shouldShowListVerificationButton && <ListVerificationButton />}
          </>
        )}
      </Flex>
      <PreDroppingListModals />
      <UnverifiedQuantityModal
        isOpen={isUnverifiedQuantityModalOpen}
        onVerifyQuantity={onVerifyQuantityButton}
      />
    </Page>
  );
}
