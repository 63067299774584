/* eslint-disable no-console */
import { useState, useEffect, useCallback } from "react";

import { UserTrackingServiceApi, V1ExperimentLookupRequest, V1Platform } from "@flink/experiment";

import { config } from "config";
import { useEppoFeatureFlagsProvider } from "core/providers/CustomEppoProvider";
import { useFeatureFlagOverridesStore } from "core/stores/useFeatureFlagOverridesStore";
import { EppoFeatureFlags, EppoFeatureVariants } from "core/types/flags";

import { useHubSlug } from "./useHubSlug";

const fetchFeatureFlag = async (
  hubSlug: string,
  featureFlag: EppoFeatureFlags,
): Promise<boolean | null> => {
  const userTrackingServiceApi = new UserTrackingServiceApi({}, config.environment.EPPO_URL);
  try {
    const experimentRequest: V1ExperimentLookupRequest = {
      userId: "not-used",
      meta: {
        attributes: {
          hub_slug: hubSlug,
        },
      },
      flags: [featureFlag],
      platform: V1Platform.EppoOps,
    };
    const experimentResponse = await userTrackingServiceApi.userTrackingServiceExperimentLookup(
      experimentRequest,
    );
    const decision = experimentResponse.data.decisions?.[featureFlag];
    const variation = decision?.variation;
    return variation ? variation === EppoFeatureVariants.ON : null;
  } catch (error: any) {
    console.error(`Error checking feature flag - ${featureFlag}:`, error.response.data);
    return null;
  }
};

const useFetchFeatureFlag = (
  hubSlug: string | null,
  featureFlag: EppoFeatureFlags,
  hasLocalOverride: boolean,
): boolean | null => {
  const [isFeatureEnabled, setIsFeatureEnabled] = useState<boolean | null>(null);

  const checkFeatureFlag = useCallback(async () => {
    if (!hasLocalOverride && hubSlug) {
      const isEnabled = await fetchFeatureFlag(hubSlug, featureFlag);
      setIsFeatureEnabled(isEnabled);
    } else {
      setIsFeatureEnabled(null);
    }
  }, [hasLocalOverride, hubSlug, featureFlag]);

  useEffect(() => {
    checkFeatureFlag();
  }, [checkFeatureFlag]);

  return isFeatureEnabled;
};

export const useEppoFeatureFlag = (featureFlag: EppoFeatureFlags): boolean | null => {
  const hubSlug = useHubSlug();
  const localOverride = useFeatureFlagOverridesStore((state) => state.overridesMap[featureFlag]);
  const hasLocalOverride = typeof localOverride === "boolean";
  const isFeatureEnabled = useFetchFeatureFlag(hubSlug, featureFlag, hasLocalOverride);

  if (hasLocalOverride) {
    return localOverride;
  }

  return isFeatureEnabled;
};

export const useEppoFeatureFlagProvider = (
  featureFlag: EppoFeatureFlags,
): { isFeatureEnabled: boolean | null; isLoading: boolean } => {
  const { decisions, isLoading } = useEppoFeatureFlagsProvider();
  const localOverride = useFeatureFlagOverridesStore((state) => state.overridesMap[featureFlag]);
  const hasLocalOverride = typeof localOverride === "boolean";
  const variation = decisions?.[featureFlag];
  const isFeatureEnabled = variation ? variation === EppoFeatureVariants.ON : null;

  if (hasLocalOverride) {
    return { isFeatureEnabled: localOverride, isLoading: false };
  }

  return { isFeatureEnabled, isLoading };
};
