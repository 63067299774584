import { useCallback, useMemo } from "react";

import { DespatchAdviceItem } from "__graphql__/types";
import { EppoFeatureFlags } from "core/types/flags";
import { useEppoFeatureFlagProvider } from "shared/hooks/useEppoFeatureFlag";
import { isNullOrUndefined } from "utils/tsHelpers";

import { UnitType } from "../../../../shared/models/productSearchUnit/types";
import { selectDespatchAdviceItemBySku } from "../../machines/inboundMachine/utils";

interface UseDespatchAdviceProps {
  sku: string;
  despatchAdviceItems: Record<string, DespatchAdviceItem[]>;
  inboundQuantity: number;
}
interface UseDespatchAdviceResult {
  expectedUnitType: UnitType;
  expectedNumberOfUnits: number;
  isTotalInboundQuantityMatchingExpectation: boolean;
  expectedHUSize: number;
}

export function useDespatchAdviceCallback() {
  const { isFeatureEnabled: isDesadvBasedInboundingEnabled } = useEppoFeatureFlagProvider(
    EppoFeatureFlags.DESADV_BASED_INBOUNDING,
  );
  return useCallback(
    (productSku: string, despatchAdviceItems: Record<string, DespatchAdviceItem[]>) => {
      if (!isDesadvBasedInboundingEnabled) return null;
      return selectDespatchAdviceItemBySku(productSku, despatchAdviceItems);
    },
    [isDesadvBasedInboundingEnabled],
  );
}

export function useSerialisedDespatchAdvice({
  sku,
  despatchAdviceItems,
  inboundQuantity,
}: UseDespatchAdviceProps): UseDespatchAdviceResult | null {
  const getSelectDespatchAdviceBySku = useDespatchAdviceCallback();
  const { isFeatureEnabled: isPartialInboundsEnabled } = useEppoFeatureFlagProvider(
    EppoFeatureFlags.PARTIAL_INBOUNDS,
  );

  return useMemo(() => {
    const despatchAdviceBySku = getSelectDespatchAdviceBySku(sku, despatchAdviceItems);
    if (isNullOrUndefined(despatchAdviceBySku)) return null;

    const remainder = despatchAdviceBySku.totalQuantity % despatchAdviceBySku.handlingUnitSize;
    const newTotalValue = despatchAdviceBySku.totalQuantity - remainder;
    const newExpectedNumberOfUnits = newTotalValue / despatchAdviceBySku.handlingUnitSize;

    return {
      expectedUnitType: despatchAdviceBySku.unitType,
      expectedNumberOfUnits: isPartialInboundsEnabled
        ? newExpectedNumberOfUnits
        : despatchAdviceBySku.expectedNumberOfUnits,
      isTotalInboundQuantityMatchingExpectation:
        despatchAdviceBySku.totalQuantity === inboundQuantity,
      expectedHUSize: despatchAdviceBySku.handlingUnitSize,
    };
  }, [
    despatchAdviceItems,
    getSelectDespatchAdviceBySku,
    inboundQuantity,
    isPartialInboundsEnabled,
    sku,
  ]);
}
