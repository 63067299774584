import produce from "immer";
import { v4 as uuidv4 } from "uuid";
import { assign, createMachine, DoneInvokeEvent } from "xstate";

import { InventoryChangeReason } from "__graphql__/types";
import { routes } from "config/routes";
import { XstateRouteSyncMeta } from "shared/types/xstate";
import { removeLeadingZerosFromSSCC } from "utils/scan";
import { isNullOrUndefined } from "utils/tsHelpers";
import { wrongEventErrorHandlerFactory } from "utils/xstate";

import { SerializedListVerificationCheck } from "../../models/listVerificationCheck/types";
import { selectDroppingListActiveInboundUnitFromContext } from "./selector";
import {
  InboundingEvents,
  InboundMachineContext,
  InboundServices,
  InboundUnitDisplayState,
  InboundUnitStockUpdateState,
} from "./types";
import {
  extractInboundPlan,
  selectDespatchAdviceItemBySku,
  sumStockUpdatePlanTotal,
} from "./utils";

export const INBOUND_MACHINE_NAME = "inboundMachine";
export const INBOUND_MACHINE_CTX_LOCAL_STORAGE_KEY = "INBOUND_MACHINE_CTX";

export const initialInboundUnitStockUpdateState = (
  initialQuantity: number,
): InboundUnitStockUpdateState => ({
  stockUpdatePlan: [
    {
      reason: InventoryChangeReason.inbound_goods_received,
      quantityDelta: initialQuantity,
    },
  ],
  stockUpdated: false,
});

export const initialInboundUnitDisplayState = (
  unitSizeForDisplay: number,
): InboundUnitDisplayState => ({
  unitSizeForDisplay,
});

export const contextInitialState: InboundMachineContext = {
  droppingListShelfSortOrder: "asc",
  preDroppingListInboundUnitsSkusSortedByAddedDate: [],
  inboundUnitsMap: {},
  droppingListId: undefined,
  inboundUnitsStockUpdateState: {},
  inboundUnitsDisplayStates: {},
  inboundUnitSkuToOutbound: undefined,
  deliverySSCC: undefined,
  deliveryType: undefined,
  onShelfStockLevels: {},
  listVerificationChecks: [],
  lastActionType: undefined,
  despatchAdviceItems: {},
  skusWithUnconfirmedQuantity: [],
};

export const getInitialInboundMachineContext = () => {
  const persisted = localStorage.getItem(INBOUND_MACHINE_CTX_LOCAL_STORAGE_KEY);
  return persisted ? JSON.parse(persisted) : contextInitialState;
};

export const inboundMachine = createMachine(
  {
    preserveActionOrder: true,
    id: INBOUND_MACHINE_NAME,
    predictableActionArguments: true,
    tsTypes: {} as import("./machine.typegen").Typegen0,
    schema: {
      context: {} as InboundMachineContext,
      events: {} as InboundingEvents,
      services: {} as InboundServices,
    },
    context: contextInitialState,
    on: {
      "*": { actions: "wrongEventErrorHandler" },
      RESET: {
        actions: "resetMachineState",
      },
    },
    type: "parallel",
    states: {
      inbounding: {
        initial: "preliminary",
        states: {
          preliminary: {
            always: [
              {
                target: "selectDelivery",
                cond: "guardDeliveryBasedInboundingEnabled",
              },
              {
                target: "preDropping",
                cond: "guardDeliveryBasedInboundingDisabled",
              },
            ],
            meta: {
              routeSync: {
                path: routes.inbound.root,
              } as XstateRouteSyncMeta,
            },
          },
          selectDelivery: {
            always: {
              // This is for cold starts where state is loaded from local storage or
              // when the user is switching back from a different flow.
              // We don't want users to have to choose a delivery for an existing list
              // if they have already done so.
              target: "preDropping",
              cond: "deliveryAlreadySelectedForExistingList",
            },
            on: {
              SELECT_DELIVERY: {
                target: "scanRolli",
                actions: ["setDeliveryType"],
              },
              SKIP_DELIVERY: {
                target: "preDropping",
                actions: [
                  (ctx: InboundMachineContext) => {
                    ctx.despatchAdviceItems = {};
                  },
                  "setDeliveryType",
                  "setDeliverySSCCToNull",
                ],
              },
              GO_BACK: ".",
            },
            meta: {
              routeSync: {
                path: routes.inbound.selectDelivery,
              } as XstateRouteSyncMeta,
            },
          },
          scanRolli: {
            on: {
              SCAN_ROLLI: [
                {
                  cond: "shouldProceedWithDespatchAdviceFetch",
                  target: "loadingDespatchAdvice",
                  actions: ["setDeliverySSCC"],
                },
                {
                  target: "preDropping",
                  actions: ["setDeliverySSCC"],
                },
              ],
              GO_BACK: {
                target: "selectDelivery",
              },
              SKIP_ROLLI_VALIDATION: {
                target: "preDropping",
                actions: ["addLoggerInfoForDesAdv"],
              },
            },
            meta: {
              routeSync: {
                path: routes.inbound.scanRolli,
              } as XstateRouteSyncMeta,
            },
          },
          loadingDespatchAdvice: {
            invoke: {
              id: "fetchMultipleDesadvs",
              src: "fetchMultipleDesadvs",
              onDone: [
                {
                  target: "preDropping",
                  actions: [
                    "transformDesAdvData",
                    "showSetDeliverySuccess",
                    "addLoggerInfoForDesAdv",
                  ],
                },
              ],
              onError: [
                {
                  cond: "shouldShowDesAdvModalGuard",
                  target: "scanRolli",
                  actions: [
                    (ctx: InboundMachineContext) => {
                      ctx.despatchAdviceItems = {};
                    },
                    "showDesAdvAskModalAction",
                  ],
                },
                {
                  target: "preDropping",
                  actions: [
                    (ctx: InboundMachineContext) => {
                      ctx.despatchAdviceItems = {};
                    },
                    "showSetDeliverySuccess",
                  ],
                },
              ],
            },
          },
          preDropping: {
            entry: ["trackInboundStateUpdated"],
            always: [
              {
                target: "listVerification",
                cond: (context) => context.lastActionType === "START_LIST_VERIFICATION",
              },
              {
                target: "loadDroppingList",
                cond: (context) => context.lastActionType === "START_DROPPING",
              },
            ],
            on: {
              ADD_INBOUND_UNIT_TO_DROPPING_LIST: [
                {
                  actions: [
                    "createDroppingListId",
                    "hideQuantityMismatchBanner",
                    "showDespatchAdviceInfoBannerIfApplicable",
                    "addInboundUnitToDroppingList",
                    "sendInboundStateUpdated",
                    "sendInboundProgressed",
                    "scrollToPageTop",
                    "focusInboundQuantityField",
                    "addLoggerInfoForDesAdv",
                    "hideProductAlreadyAddedBanner",
                  ],
                  cond: (context) =>
                    context.preDroppingListInboundUnitsSkusSortedByAddedDate.length === 0,
                },
                {
                  actions: [
                    "showDespatchAdviceInfoBannerIfApplicable",
                    "addInboundUnitToDroppingList",
                    "sendInboundProgressed",
                    "scrollToPageTop",
                    "focusInboundQuantityField",
                    "addLoggerInfoForDesAdv",
                  ],
                  cond: "canAddInboundUnitToList",
                },
              ],
              REMOVE_INBOUND_UNIT_FROM_DROPPING_LIST: {
                actions: [
                  "sendInboundProgressed",
                  "removeInboundUnitFromDroppingList",
                  "confirmQuantity",
                  "hideQuantityMismatchBanner",
                  "hideDespatchAdviceInfoBanner",
                  "hideProductAlreadyAddedBanner",
                ],
                cond: "isInboundUnitNotBlocked",
              },
              // this is triggered when manually changing the quantity on the pre-dropping list
              SET_INBOUND_UNIT_TOTAL_QUANTITY: {
                actions: [
                  "setInboundUnitTotalQuantity",
                  "confirmQuantity",
                  "showDespatchAdviceQuantityMismatchBanner",
                  "hideDespatchAdviceInfoBanner",
                  "sendInboundProgressed",
                ],
                cond: "isInboundUnitNotBlocked",
              },
              // this is triggered when scanning or adding an existing item through a search flow
              INCREASE_INBOUND_UNIT_INBOUND_QUANTITY: [
                {
                  actions: [
                    "increaseInboundUnitInboundQuantity",
                    "sendInboundProgressed",
                    "scrollToPageTop",
                  ],
                  cond: "canIncreaseInboundUnitInboundQuantity",
                },
                {
                  actions: [
                    "scrollToPageTop",
                    "moveProductToTopOfList",
                    "showProductAlreadyAddedBanner",
                  ],
                  cond: "shouldMoveProductToTopOnIncrease",
                },
              ],
              SET_HANDLING_UNIT_SIZE: {
                actions: [
                  "setHandlingUnitSize",
                  "showEditHUSizeSuccessToast",
                  "sendInboundProgressed",
                ],
              },
              START_LIST_VERIFICATION: {
                target: "loadListVerification",
                cond: "canStartListVerification",
                actions: [
                  "setLastEventType",
                  "sendInboundStateUpdated",
                  "hideQuantityMismatchBanner",
                  "hideProductAlreadyAddedBanner",
                ],
              },
              GO_TO_OUTBOUND_PAGE: {
                target: "outbounding",
                actions: "setInboundUnitIdToOutbound",
              },
              START_MANUAL_SEARCH: "usingManualSearch",
              CONFIRM_QUANTITY: {
                actions: ["confirmQuantity", "hideDespatchAdviceInfoBanner"],
              },
              GO_BACK: {
                target: "selectDelivery",
                actions: ["removeDeliverySelection", "hideProductAlreadyAddedBanner"],
              },
            },
            meta: {
              routeSync: {
                path: routes.inbound.preDropping,
              } as XstateRouteSyncMeta,
            },
          },
          usingManualSearch: {
            on: {
              CANCEL_MANUAL_SEARCH: "preDropping",
            },
          },
          loadListVerification: {
            on: {
              GO_BACK: { target: "preDropping", actions: "setLastEventType" },
            },
            invoke: {
              id: "generateListVerificationChecks",
              src: "generateListVerificationChecks",
              onDone: [
                {
                  target: "listVerification",
                  actions: (
                    ctx: InboundMachineContext,
                    event: DoneInvokeEvent<SerializedListVerificationCheck[]>,
                  ) => {
                    ctx.listVerificationChecks = event.data;
                  },
                },
              ],
              onError: { target: "preDropping", actions: "setLastEventType" },
            },
          },
          listVerification: {
            on: {
              SET_INBOUND_UNIT_TOTAL_QUANTITY: {
                actions: ["setInboundUnitTotalQuantity", "sendInboundProgressed"],
                cond: "isInboundUnitNotBlocked",
              },
              COMPLETE_LIST_VERIFICATION_CHECK: {
                actions: ["completeListVerificationCheck", "sendInboundVerificationProgressed"],
              },
              START_DROPPING: {
                target: "loadDroppingList",
                actions: ["setLastEventType", "sendInboundStateUpdated"],
              },
              GO_BACK: { target: "preDropping", actions: "setLastEventType" },
            },
            meta: {
              routeSync: {
                path: routes.inbound.listVerification,
              } as XstateRouteSyncMeta,
            },
          },
          loadDroppingList: {
            invoke: {
              id: "loadOnShelfStockLevels",
              src: "loadOnShelfStockLevels",
              onDone: {
                target: "dropping",
                actions: assign(
                  (ctx: InboundMachineContext, event: DoneInvokeEvent<Record<string, number>>) => {
                    const nextState = produce(ctx, (ctxDraft) => {
                      ctxDraft.onShelfStockLevels = {
                        ...ctxDraft.onShelfStockLevels,
                        ...event.data,
                      };
                    });
                    return nextState;
                  },
                ),
              },
              onError: [
                {
                  target: "listVerification",
                  actions: "setLastEventType",
                },
              ],
            },
            on: {
              GO_BACK: [
                {
                  target: "listVerification",
                  actions: "setLastEventType",
                },
              ],
            },
          },
          dropping: {
            on: {
              TOGGLE_DROPPING_LIST_SHELF_SORT_ORDER: {
                actions: "toggleDroppingListShelfSortOrder",
              },
              GO_BACK: {
                target: "preDropping",
                actions: "setLastEventType",
              },
              FINISH_DROPPING: {
                cond: "canFinishDropping",
                target: "preliminary",
                actions: ["sendInboundStateUpdated", "resetMachineState"],
              },
            },
            meta: {
              routeSync: {
                path: routes.inbound.dropping,
              } as XstateRouteSyncMeta,
            },
          },
          outbounding: {
            on: {
              GO_BACK: {
                target: "preDropping",
              },
              SET_INBOUND_UNIT_QUANTITIES_BY_REASON: {
                target: "preDropping",
                actions: [
                  "setInboundUnitQuantitiesByReason",
                  "sendInboundProgressed",
                  "sendOutboundProgressed",
                ],
              },
            },
            meta: {
              routeSync: {
                path: routes.inbound.outbound,
              } as XstateRouteSyncMeta,
            },
          },
        },
      },
      updateInboundUnitStock: {
        initial: "idle",
        states: {
          idle: {
            on: {
              UPDATE_INBOUND_UNIT_STOCK: {
                target: "loading",
                cond: "canUpdateInboundUnitStock",
              },
            },
          },
          loading: {
            invoke: {
              id: "updateInboundUnitProductStock",
              src: "updateInboundUnitProductStock",
              onDone: {
                target: "idle",
                actions: [
                  assign((ctx: InboundMachineContext, event: DoneInvokeEvent<string>) => {
                    const nextState = produce(ctx, (ctxDraft) => {
                      const sku = event.data;

                      const inboundUnitStockUpdateStates =
                        ctxDraft.inboundUnitsStockUpdateState[sku];
                      if (!inboundUnitStockUpdateStates) {
                        return;
                      }
                      inboundUnitStockUpdateStates.stockUpdated = true;
                    });
                    return nextState;
                  }),
                  "addLoggerInfoForDesAdv",
                ],
              },
              onError: [
                {
                  cond: (_, event) =>
                    (event.data?.toString() ?? "").includes("Error: productNotAssignedForTheHub"),
                  target: "idle",
                  actions: "showProductNotAssignedForTheHubError",
                },
                {
                  cond: (_, event) =>
                    (event.data?.toString() ?? "").includes(
                      "Error: unableToCreateSkuHubAssociation",
                    ),
                  target: "idle",
                  actions: "showUnableToCreateSkuHubAssociationError",
                },
                {
                  target: "idle",
                  actions: "onUpdateInboundUnitProductStockError",
                },
              ],
            },
          },
        },
      },
    },
  },
  {
    actions: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      resetMachineState: assign((context) => ({ ...contextInitialState })),
      // PRE-DROPPING FLOW ACTIONS
      addInboundUnitToDroppingList: assign((ctx, event) => {
        // if DESADV based inbounding is on and there is an unconfirmed quantity,
        // we disallow adding new items.

        const nextState = produce(ctx, (ctxDraft) => {
          const newInboundUnit = event.inboundUnit;
          const sku = newInboundUnit.productSku;
          const inboundingMethod = event.origin;
          const selectedDespatchAdviceItem = selectDespatchAdviceItemBySku(
            sku,
            ctx.despatchAdviceItems,
          );
          const { quantity } = newInboundUnit;
          const newQuantity = selectedDespatchAdviceItem
            ? selectedDespatchAdviceItem.totalQuantity
            : quantity;
          const unitSizeForDisplay = selectedDespatchAdviceItem
            ? selectedDespatchAdviceItem.handlingUnitSize
            : quantity;
          const uncomfirmed = selectedDespatchAdviceItem
            ? [...ctx.skusWithUnconfirmedQuantity, sku]
            : ctx.skusWithUnconfirmedQuantity;
          ctxDraft.skusWithUnconfirmedQuantity = uncomfirmed;
          // Adding the new inbound unit SKU to the preDroppingListInboundUnitsSkusSortedByAddedDate list;
          ctxDraft.preDroppingListInboundUnitsSkusSortedByAddedDate = [
            sku,
            ...ctx.preDroppingListInboundUnitsSkusSortedByAddedDate,
          ].sort((a, b) => {
            return uncomfirmed.indexOf(b) - uncomfirmed.indexOf(a);
          });
          // Adding the new inbound unit data to the inboundUnitsMap
          ctxDraft.inboundUnitsMap[sku] = {
            ...newInboundUnit,
            inboundingMethod,
          };
          // Adding a new entry to the inboundUnitsStockUpdateState with an initialized state
          ctxDraft.inboundUnitsStockUpdateState[sku] =
            initialInboundUnitStockUpdateState(newQuantity);
          // Adding a new entry to the inboundUnitsDisplayStates with an initialized state
          ctxDraft.inboundUnitsDisplayStates[sku] = { unitSizeForDisplay };
        });
        return nextState;
      }),
      createDroppingListId: assign((ctx) => {
        const nextState = produce(ctx, (ctxDraft) => {
          // Creating a unique id for the list if it happens to be new
          ctxDraft.droppingListId = uuidv4();
        });
        return nextState;
      }),
      removeInboundUnitFromDroppingList: assign((ctx, event) => {
        const nextState = produce(ctx, (ctxDraft) => {
          const skuToRemove = event.sku;
          // Removing the SKU from the preDroppingListInboundUnitsSkusSortedByAddedDate list;
          ctxDraft.preDroppingListInboundUnitsSkusSortedByAddedDate =
            ctxDraft.preDroppingListInboundUnitsSkusSortedByAddedDate.filter(
              (sku) => sku !== skuToRemove,
            );
          // Delete from all the data maps
          delete ctxDraft.inboundUnitsMap[skuToRemove];
          delete ctxDraft.inboundUnitsStockUpdateState[skuToRemove];
          delete ctxDraft.inboundUnitsDisplayStates[skuToRemove];
        });
        return nextState;
      }),
      setInboundUnitTotalQuantity: assign((ctx, event) => {
        // note: the quantity here comes in units of the current unitSizeForDisplay!!
        const nextState = produce(ctx, (ctxDraft) => {
          const newTotalQuantityInUnitSizeForDisplay = event.quantity;
          if (newTotalQuantityInUnitSizeForDisplay < 1) {
            return;
          }
          const inboundUnitStockUpdateStates = ctxDraft.inboundUnitsStockUpdateState[event.sku];
          if (!inboundUnitStockUpdateStates) {
            return;
          }
          const inboundUnitDisplayState = ctxDraft.inboundUnitsDisplayStates[event.sku];
          if (!inboundUnitDisplayState) {
            return;
          }

          const oldInboundStockUpdateState = extractInboundPlan(
            inboundUnitStockUpdateStates.stockUpdatePlan,
          );
          if (!oldInboundStockUpdateState) {
            return;
          }
          const oldTotalQuantity = sumStockUpdatePlanTotal(
            inboundUnitStockUpdateStates.stockUpdatePlan,
          );
          const newTotalQuantity =
            newTotalQuantityInUnitSizeForDisplay * inboundUnitDisplayState.unitSizeForDisplay;
          const inboundQuantityChange = newTotalQuantity - oldTotalQuantity;
          const newInboundQuantity =
            oldInboundStockUpdateState.quantityDelta + inboundQuantityChange;
          oldInboundStockUpdateState.quantityDelta = newInboundQuantity;
        });
        return nextState;
      }),
      setInboundUnitQuantitiesByReason: assign((ctx, event) => {
        const nextState = produce(ctx, (ctxDraft) => {
          const inboundUnitStockUpdateStates = ctxDraft.inboundUnitsStockUpdateState[event.sku];
          if (!inboundUnitStockUpdateStates) {
            return;
          }
          const inboundUnitDisplayState = ctxDraft.inboundUnitsDisplayStates[event.sku];
          if (!inboundUnitDisplayState) {
            return;
          }

          event.quantitiesByReason.forEach(({ quantity, reason }) => {
            const existingStockUpdateState = inboundUnitStockUpdateStates.stockUpdatePlan.find(
              ({ reason: r }) => r === reason,
            );
            if (!existingStockUpdateState) {
              inboundUnitStockUpdateStates.stockUpdatePlan.push({
                reason,
                quantityDelta: quantity,
              });
            } else {
              existingStockUpdateState.quantityDelta = quantity;
            }
          });
          // always convert to single units, unless outbound qties are all 0
          if (event.quantitiesByReason.some(({ quantity }) => quantity !== 0)) {
            inboundUnitDisplayState.unitSizeForDisplay = 1;
          }
        });
        return nextState;
      }),
      increaseInboundUnitInboundQuantity: assign((ctx, event) => {
        const nextState = produce(ctx, (ctxDraft) => {
          const inboundUnitStockUpdateStates = ctxDraft.inboundUnitsStockUpdateState[event.sku];
          if (!inboundUnitStockUpdateStates) {
            return;
          }

          const inboundUnitDisplayState = ctxDraft.inboundUnitsDisplayStates[event.sku];
          if (!inboundUnitDisplayState) {
            return;
          }

          const inboundStockUpdateState = extractInboundPlan(
            inboundUnitStockUpdateStates.stockUpdatePlan,
          );
          if (!inboundStockUpdateState) {
            return;
          }

          inboundStockUpdateState.quantityDelta += event.quantity;

          // if added quantity is not equal to the current handling unit size,
          // convert display to single units
          if (event.quantity !== inboundUnitDisplayState.unitSizeForDisplay) {
            inboundUnitDisplayState.unitSizeForDisplay = 1;
          }

          ctxDraft.preDroppingListInboundUnitsSkusSortedByAddedDate.splice(
            ctxDraft.preDroppingListInboundUnitsSkusSortedByAddedDate.indexOf(event.sku),
            1,
          );
          ctxDraft.preDroppingListInboundUnitsSkusSortedByAddedDate.unshift(event.sku);
        });
        return nextState;
      }),
      setHandlingUnitSize: assign((ctx, event) => {
        return produce(ctx, (ctxDraft) => {
          const displayState = ctxDraft.inboundUnitsDisplayStates[event.sku];
          const inboundStockUpdate = extractInboundPlan(
            ctxDraft.inboundUnitsStockUpdateState[event.sku].stockUpdatePlan,
          );
          if (!inboundStockUpdate) return;

          inboundStockUpdate.quantityDelta =
            (inboundStockUpdate.quantityDelta / displayState.unitSizeForDisplay) * event.size;
          displayState.unitSizeForDisplay = event.size;
        });
      }),
      setInboundUnitIdToOutbound: assign((ctx, event) => {
        return produce(ctx, (ctxDraft) => {
          ctxDraft.inboundUnitSkuToOutbound = event.sku;
        });
      }),
      // DROPPING FLOW ACTIONS
      toggleDroppingListShelfSortOrder: assign((ctx) => {
        const nextState = produce(ctx, (ctxDraft) => {
          ctxDraft.droppingListShelfSortOrder =
            ctxDraft.droppingListShelfSortOrder === "asc" ? "desc" : "asc";
        });
        return nextState;
      }),
      removeDeliverySelection: assign((ctx, event) => {
        const nextState = produce(ctx, (ctxDraft) => {
          ctxDraft.deliverySSCC = undefined;
          ctxDraft.deliveryType = undefined;
          ctx.despatchAdviceItems = {};
          ctxDraft.lastActionType = event.type;
        });
        return nextState;
      }),
      setDeliverySSCC: assign((ctx, event) => {
        const nextState = produce(ctx, (ctxDraft) => {
          ctxDraft.deliverySSCC = removeLeadingZerosFromSSCC(event.deliverySSCC);
        });
        return nextState;
      }),
      setDeliverySSCCToNull: assign((ctx) => {
        const nextState = produce(ctx, (ctxDraft) => {
          ctxDraft.deliverySSCC = null;
        });
        return nextState;
      }),
      setDeliveryType: assign((ctx, event) => {
        const nextState = produce(ctx, (ctxDraft) => {
          ctxDraft.deliveryType = event.deliveryType;
        });
        return nextState;
      }),
      completeListVerificationCheck: assign((ctx, event) => {
        return produce(ctx, (ctxDraft) => {
          const existingCheck = ctxDraft.listVerificationChecks.find(
            ({ key }) => key === event.key,
          );
          if (existingCheck) {
            if ("quantity" in existingCheck.data) {
              const newQuantity = extractInboundPlan(
                ctxDraft.inboundUnitsStockUpdateState[existingCheck.sku].stockUpdatePlan,
              )?.quantityDelta;
              existingCheck.data.quantity = newQuantity ?? existingCheck.data.quantity;
            }
            existingCheck.isCompleted = true;
          }
        });
      }),
      setLastEventType: assign((ctx, event) => {
        const nextState = produce(ctx, (ctxDraft) => {
          ctxDraft.lastActionType = event.type;
        });
        return nextState;
      }),
      confirmQuantity: assign((ctx, event) => {
        const nextState = produce(ctx, (ctxDraft) => {
          if (ctx.skusWithUnconfirmedQuantity.includes(event.sku)) {
            ctxDraft.skusWithUnconfirmedQuantity = ctx.skusWithUnconfirmedQuantity.filter(
              (sku) => sku !== event.sku,
            );
          }
        });
        return nextState;
      }),
      moveProductToTopOfList: assign((ctx, event) => {
        const nextState = produce(ctx, (ctxDraft) => {
          ctxDraft.preDroppingListInboundUnitsSkusSortedByAddedDate = [
            event.sku,
            ...ctx.preDroppingListInboundUnitsSkusSortedByAddedDate.filter(
              (sku) => sku !== event.sku,
            ),
          ];
        });
        return nextState;
      }),
      wrongEventErrorHandler: wrongEventErrorHandlerFactory(INBOUND_MACHINE_NAME),
      transformDesAdvData: assign((ctx, event) => {
        const nextState = produce(ctx, (ctxDraft) => {
          // Reset despatchAdviceItems
          ctxDraft.despatchAdviceItems = {};

          const desadvs = event.data;
          if (desadvs && Array.isArray(desadvs)) {
            desadvs.forEach((desadv) => {
              if (!ctxDraft.despatchAdviceItems[desadv.id]) {
                ctxDraft.despatchAdviceItems[desadv.id] = [];
              }
              ctxDraft.despatchAdviceItems[desadv.id] = [
                ...ctxDraft.despatchAdviceItems[desadv.id],
                ...desadv.items,
              ];
            });
          }
        });
        return nextState;
      }),
    },
    guards: {
      isInboundUnitNotBlocked: (context, event) => {
        return context.inboundUnitsStockUpdateState[event.sku]?.stockUpdated === false;
      },
      canStartListVerification: ({
        inboundUnitsStockUpdateState,
        inboundUnitsMap,
        preDroppingListInboundUnitsSkusSortedByAddedDate,
      }) =>
        Object.keys(inboundUnitsStockUpdateState).length > 0 &&
        Object.keys(inboundUnitsMap).length > 0 &&
        preDroppingListInboundUnitsSkusSortedByAddedDate.length > 0,
      canUpdateInboundUnitStock: (context, event) => {
        const inboundUnitStockUpdateState = context.inboundUnitsStockUpdateState?.[event.sku];
        return !!inboundUnitStockUpdateState && !inboundUnitStockUpdateState.stockUpdated;
      },
      canFinishDropping: (context) =>
        isNullOrUndefined(selectDroppingListActiveInboundUnitFromContext(context)),
      deliveryAlreadySelectedForExistingList: (context) => {
        return (
          context.deliveryType !== undefined &&
          context.deliverySSCC !== undefined &&
          Object.keys(context.inboundUnitsMap).length > 0
        );
      },
    },
  },
);
