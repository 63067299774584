import parseBarcode from "libs/barcodes";

type OrderQRCodeType = {
  picking_new_order: {
    id: string;
    number: string;
    is_new_customer: boolean;
    customer_email?: string;
    first_name?: string;
    last_name?: string;
  };
};

export function getOrderNumberFromQRCode(scannedValue: string) {
  let orderNumber = "";
  try {
    const parsedOrder: OrderQRCodeType = JSON.parse(scannedValue);
    if (parsedOrder?.picking_new_order?.number) {
      orderNumber = parsedOrder?.picking_new_order?.number;
    }
  } catch (e) {
    orderNumber = scannedValue.trim();
  }
  return orderNumber;
}

type ParseBarcodeOutput = {
  parsedCodeItems: {
    ai: string;
    data: string;
    dataTitle: "GTIN" | "CONTENT";
  }[];
};

export function parseGS1128Barcode(scannedValue: string): ParseBarcodeOutput {
  const result = parseBarcode(scannedValue);
  return result as ParseBarcodeOutput;
}

export const isShelfNumberFormat = (barcode: string) => {
  const shelfNumberRegex = /\d\d\d[a-zA-Z]$/;
  return shelfNumberRegex.test(barcode);
};

export const isValidSSCCFormat = (barcode: string) => {
  return /^(00\d{18}|\d{18})$/.test(barcode);
};

/*
 * Return sscc trimmed without leading zeros, there are some cases when SSCC is being proceed with 18 digits and 2 zero in front
 */
export const removeLeadingZerosFromSSCC = (sscc: string) => {
  return sscc.padStart(18, "0").slice(-18);
};

export const simulateScan = (scannedValue: string) => {
  let i = 0;
  const interval = setInterval(() => {
    if (i === scannedValue.length) {
      clearInterval(interval);
      return;
    }
    window.dispatchEvent(new KeyboardEvent("keydown", { key: scannedValue[i] }));
    i += 1;
  }, 5);
};
