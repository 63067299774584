import { useCallback } from "react";

import { useCustomToast } from "shared/hooks/useCustomToast";

export function useCheckSessionExpiredToast() {
  const { showToastUI } = useCustomToast();

  const showCheckSessionExpiredToast = useCallback(() => {
    showToastUI({
      title: "flows.inventory.hooks.use-check-session-expired-toast.title",
      duration: null, // null mnakes the toast last forever, this is intended so that the user understands what happened if the page was not focused and they come back to it later on
      isClosable: true,
    });
  }, [showToastUI]);

  return {
    showCheckSessionExpiredToast,
  };
}
