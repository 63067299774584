import * as Types from "../../../../__graphql__/types.js";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type GetInboundSharedListsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetInboundSharedListsQuery = {
  __typename?: "Query";
  getInboundSharedLists: {
    __typename?: "InboundSharedListsResponse";
    lists?: Array<{
      __typename?: "InboundSharedListItem";
      id: string;
      sscc: string;
      status: Types.SharedListStatus;
      name: string;
      creatorId: string;
      createdAt: string;
      products?: Array<{
        __typename?: "SharedListItemProduct";
        id: string;
        sku: string;
        quantity: number;
        imageUrl?: string | null;
      }> | null;
    }> | null;
  };
};

export type CreatePreDroppingListMutationVariables = Types.Exact<{
  input: Types.CreatePreDroppingListInput;
}>;

export type CreatePreDroppingListMutation = {
  __typename?: "Mutation";
  createPreDroppingList: { __typename?: "CreatePreDroppingListResponse"; id?: string | null };
};

export type AddItemsToPreDroppingListMutationVariables = Types.Exact<{
  input: Types.AddItemsToPreDroppingListInput;
}>;

export type AddItemsToPreDroppingListMutation = {
  __typename?: "Mutation";
  addItemsToPreDroppingList: { __typename?: "AddItemsToPreDroppingListResponse"; success: boolean };
};

export const GetInboundSharedListsDocument = gql`
  query getInboundSharedLists {
    getInboundSharedLists {
      lists {
        id
        products {
          id
          sku
          quantity
          imageUrl
        }
        sscc
        status
        name
        creatorId
        createdAt
      }
    }
  }
`;

/**
 * __useGetInboundSharedListsQuery__
 *
 * To run a query within a React component, call `useGetInboundSharedListsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInboundSharedListsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInboundSharedListsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInboundSharedListsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetInboundSharedListsQuery,
    GetInboundSharedListsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInboundSharedListsQuery, GetInboundSharedListsQueryVariables>(
    GetInboundSharedListsDocument,
    options,
  );
}
export function useGetInboundSharedListsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInboundSharedListsQuery,
    GetInboundSharedListsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInboundSharedListsQuery, GetInboundSharedListsQueryVariables>(
    GetInboundSharedListsDocument,
    options,
  );
}
export type GetInboundSharedListsQueryHookResult = ReturnType<typeof useGetInboundSharedListsQuery>;
export type GetInboundSharedListsLazyQueryHookResult = ReturnType<
  typeof useGetInboundSharedListsLazyQuery
>;
export type GetInboundSharedListsQueryResult = Apollo.QueryResult<
  GetInboundSharedListsQuery,
  GetInboundSharedListsQueryVariables
>;
export const CreatePreDroppingListDocument = gql`
  mutation createPreDroppingList($input: CreatePreDroppingListInput!) {
    createPreDroppingList(input: $input) {
      id
    }
  }
`;
export type CreatePreDroppingListMutationFn = Apollo.MutationFunction<
  CreatePreDroppingListMutation,
  CreatePreDroppingListMutationVariables
>;

/**
 * __useCreatePreDroppingListMutation__
 *
 * To run a mutation, you first call `useCreatePreDroppingListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePreDroppingListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPreDroppingListMutation, { data, loading, error }] = useCreatePreDroppingListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePreDroppingListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePreDroppingListMutation,
    CreatePreDroppingListMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePreDroppingListMutation, CreatePreDroppingListMutationVariables>(
    CreatePreDroppingListDocument,
    options,
  );
}
export type CreatePreDroppingListMutationHookResult = ReturnType<
  typeof useCreatePreDroppingListMutation
>;
export type CreatePreDroppingListMutationResult =
  Apollo.MutationResult<CreatePreDroppingListMutation>;
export type CreatePreDroppingListMutationOptions = Apollo.BaseMutationOptions<
  CreatePreDroppingListMutation,
  CreatePreDroppingListMutationVariables
>;
export const AddItemsToPreDroppingListDocument = gql`
  mutation addItemsToPreDroppingList($input: AddItemsToPreDroppingListInput!) {
    addItemsToPreDroppingList(input: $input) {
      success
    }
  }
`;
export type AddItemsToPreDroppingListMutationFn = Apollo.MutationFunction<
  AddItemsToPreDroppingListMutation,
  AddItemsToPreDroppingListMutationVariables
>;

/**
 * __useAddItemsToPreDroppingListMutation__
 *
 * To run a mutation, you first call `useAddItemsToPreDroppingListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddItemsToPreDroppingListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addItemsToPreDroppingListMutation, { data, loading, error }] = useAddItemsToPreDroppingListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddItemsToPreDroppingListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddItemsToPreDroppingListMutation,
    AddItemsToPreDroppingListMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddItemsToPreDroppingListMutation,
    AddItemsToPreDroppingListMutationVariables
  >(AddItemsToPreDroppingListDocument, options);
}
export type AddItemsToPreDroppingListMutationHookResult = ReturnType<
  typeof useAddItemsToPreDroppingListMutation
>;
export type AddItemsToPreDroppingListMutationResult =
  Apollo.MutationResult<AddItemsToPreDroppingListMutation>;
export type AddItemsToPreDroppingListMutationOptions = Apollo.BaseMutationOptions<
  AddItemsToPreDroppingListMutation,
  AddItemsToPreDroppingListMutationVariables
>;
