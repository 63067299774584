import { forwardRef, useMemo } from "react";

import { Box, BoxProps, Flex, Grid } from "@chakra-ui/react";

import { TranslatedProductSearchUnit } from "shared/models/productSearchUnit/types";
import { TapIcon } from "ui/Icons/Icons";
import { InboundFruitOrVeggyChips } from "ui/InboundFruitOrVeggyChips/InboundFruitOrVeggyChips";
import { Number } from "ui/Number/Number";
import { ProductFacing } from "ui/ProductFacing/ProductFacing";
import { ProductInformation } from "ui/ProductInformation/ProductInformation";
import { Shelf } from "ui/Shelf/Shelf";
import { Status } from "ui/Status/Status";
import { HeaderS, TitleS } from "ui/Typography/Typography";
import { isNotNullNorUndefined } from "utils/tsHelpers";

export type InboundDroppingListItemCardTranslatedLabelsProps = {
  singleUnitsLabel: string;
  handlingUnitsLabel: string;
  totalUnitsLabel: string;
  inboundedLabel: string;
  bioLabel: string;
  nonBioLabel: string;
  outboundedLabel: string;
  outboundLabel: string;
  droppingLabel: string;
  inStockLabel: string;
};

export type InboundUnitListItemDataProps = Pick<
  TranslatedProductSearchUnit,
  | "shelfLetter"
  | "shelfNumber"
  | "productName"
  | "productSku"
  | "productImageUrl"
  | "numberOfShelfFacings"
  | "isShelvedInHandlingUnits"
  | "unitType"
  | "countryOfOriginName"
  | "countryOfOriginCode"
  | "isProductBio"
>;

export type InboundDroppingListItemCardProps = {
  stockUpdatePlanTotal?: number;
  onClickCard: () => void;
  displayQuantity: number;
  isStockUpdateComplete: boolean;
  displayAsHandlingUnit: boolean;
  isPartiallyOutbounded?: boolean;
  isOutbounded?: boolean;
  stockOnShelf: number;
  shouldShowProductFacing: boolean;
} & InboundDroppingListItemCardTranslatedLabelsProps &
  InboundUnitListItemDataProps &
  BoxProps;

export const InboundDroppingListItemCard = forwardRef<
  HTMLDivElement,
  InboundDroppingListItemCardProps
>(
  (
    {
      shelfLetter,
      shelfNumber,
      productName,
      productSku,
      productImageUrl,
      isShelvedInHandlingUnits,
      numberOfShelfFacings,
      unitType,
      displayQuantity,
      inboundedLabel,
      singleUnitsLabel,
      handlingUnitsLabel,
      stockUpdatePlanTotal,
      totalUnitsLabel,
      bioLabel,
      nonBioLabel,
      outboundedLabel,
      outboundLabel,
      countryOfOriginName,
      countryOfOriginCode,
      isProductBio,
      isStockUpdateComplete,
      displayAsHandlingUnit,
      onClickCard,
      isPartiallyOutbounded = false,
      isOutbounded = false,
      droppingLabel,
      inStockLabel,
      stockOnShelf,
      shouldShowProductFacing,
      ...rest
    },
    ref,
  ) => {
    const isFruitOrVeggy = isNotNullNorUndefined(countryOfOriginName);
    const bgColorForQuantityIncomplete = displayAsHandlingUnit ? "orange.100" : "marine.100";

    const bgColorForQuantity = isStockUpdateComplete ? "veggie.100" : bgColorForQuantityIncomplete;

    const colorForQuantity = isStockUpdateComplete ? "green.500" : "grey.800";

    const displayedShelf =
      isNotNullNorUndefined(shelfLetter) && isNotNullNorUndefined(shelfNumber) ? (
        <Shelf gridColumnStart="3" letter={shelfLetter} number={shelfNumber} />
      ) : (
        <Status bg="grey.200" textColor="black" gridColumnStart="3" label="N/A" />
      );

    const displayedStatus = useMemo(() => {
      if (isOutbounded) {
        return (
          <Status
            bg="purple.500"
            textColor="white"
            label={isStockUpdateComplete ? outboundedLabel : outboundLabel}
          />
        );
      }
      if (isStockUpdateComplete) {
        return <Status bg="veggie.500" textColor="white" label={inboundedLabel} />;
      }
      if (isFruitOrVeggy) {
        return (
          <InboundFruitOrVeggyChips
            bioLabel={bioLabel}
            nonBioLabel={nonBioLabel}
            countryOfOriginName={countryOfOriginName}
            isBio={!!isProductBio}
          />
        );
      }
      return null;
    }, [
      isStockUpdateComplete,
      isOutbounded,
      inboundedLabel,
      outboundedLabel,
      outboundLabel,
      isFruitOrVeggy,
      bioLabel,
      nonBioLabel,
      isProductBio,
      countryOfOriginName,
    ]);

    const showTapIcon = !shouldShowProductFacing && !isStockUpdateComplete;
    const updatedStock = isStockUpdateComplete
      ? stockOnShelf + (stockUpdatePlanTotal ?? 0)
      : stockOnShelf;

    return (
      <Box
        onClick={onClickCard}
        p="s200"
        bg="white"
        ref={ref}
        data-testid="inbound-dropping-card"
        {...rest}
      >
        <Grid mb="s200" templateColumns="min-content 1fr min-content">
          {displayedStatus}
          {displayedShelf}
        </Grid>
        <ProductInformation
          bg="grey.100"
          name={productName ?? ""}
          sku={productSku}
          imageUrl={productImageUrl ?? ""}
          showLeftSideBar={isPartiallyOutbounded || isStockUpdateComplete}
          leftSideBarProps={{ bg: isPartiallyOutbounded ? "purple.500" : "veggie.500" }}
          showSKU={!shouldShowProductFacing}
          stock={shouldShowProductFacing ? updatedStock : undefined}
          variantSize={shouldShowProductFacing ? "sm" : "m"}
        />
        {shouldShowProductFacing && (
          <ProductFacing
            isShelvedInHandlingUnits={isShelvedInHandlingUnits}
            numberOfFacings={numberOfShelfFacings}
          />
        )}
        <Flex justifyContent="space-between" mt="s200">
          <Flex direction="column">
            <TitleS mb="s50" color="grey.800">
              {displayAsHandlingUnit ? handlingUnitsLabel : singleUnitsLabel}
            </TitleS>
            <Flex justifyContent="flex-start" alignItems="center">
              <Number
                content={displayQuantity.toString()}
                color={colorForQuantity}
                bgColor={bgColorForQuantity}
                data-testid="unit-quantity"
              />
              {showTapIcon && (
                <TapIcon data-testid="product-tap-icon" h="32px" w="32px" ml="s100" />
              )}
            </Flex>
          </Flex>
          <Flex gap="s200">
            <Flex direction="column" alignItems="center">
              <TitleS mb="s100" color="grey.800">
                {droppingLabel}
              </TitleS>
              <HeaderS data-testid="product-dropping-amount">
                {isStockUpdateComplete ? "0" : stockUpdatePlanTotal}
              </HeaderS>
            </Flex>
            {!shouldShowProductFacing && (
              <Flex direction="column" alignItems="center">
                <TitleS mb="s100" color="grey.800">
                  {inStockLabel}
                </TitleS>
                <HeaderS data-testid="product-stock-on-shelf">{updatedStock}</HeaderS>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Box>
    );
  },
);
