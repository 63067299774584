import { useSelector } from "@xstate/react";

import { EppoFeatureFlags } from "core/types/flags";
import { useInboundService } from "flows/Inbound/hooks/useInboundService";
import { useEppoFeatureFlagProvider } from "shared/hooks/useEppoFeatureFlag";

export function useProductSearchGuardsImplems() {
  const inboundService = useInboundService();
  const hasUnconfirmedQuantities = useSelector(
    inboundService,
    (state) => !!state.context.skusWithUnconfirmedQuantity.length,
  );

  const { isFeatureEnabled: isDesadvBasedInboundingEnabled } = useEppoFeatureFlagProvider(
    EppoFeatureFlags.DESADV_BASED_INBOUNDING,
  );

  const guardSearchIsEnabled = () => {
    if (!isDesadvBasedInboundingEnabled) return true;
    return !hasUnconfirmedQuantities;
  };

  return {
    guardSearchIsEnabled,
  };
}
