import { useCallback } from "react";

import shallow from "zustand/shallow";

import { useProductSearchStore } from "shared/stores/useProductSearchStore";
import { BOTTOM_TAB_BAR_HEIGHT_NUMBER } from "ui/BottomTabBarContainer";

import { useInboundUIStore } from "../stores/useInboundUIStore";
import { EndDroppingProcessButton } from "./EndDroppingProcessButton";

export function AddItemsToSharedListButton() {
  const { isAddItemsToSharedListModalOpen, setInboundUIState } = useInboundUIStore(
    (state) => ({
      isAddItemsToSharedListModalOpen: state.isAddItemsToSharedListModalOpen,
      setInboundUIState: state.setInboundUIState,
    }),
    shallow,
  );
  const { selectedProducts } = useProductSearchStore(
    (state) => ({
      selectedProducts: state.selectedProducts,
    }),
    shallow,
  );
  const shouldShowAddItemsToSharedListButton =
    !!selectedProducts.length && !isAddItemsToSharedListModalOpen;

  const onClickAddItemsToSharedList = useCallback(() => {
    setInboundUIState({ isAddItemsToSharedListModalOpen: true });
  }, [setInboundUIState]);

  return (
    <EndDroppingProcessButton
      shouldShowButton={shouldShowAddItemsToSharedListButton}
      onClickButton={onClickAddItemsToSharedList}
      labelMessageId="pages.inbound.add-items-to-shared-list.button"
      bottom={BOTTOM_TAB_BAR_HEIGHT_NUMBER}
    />
  );
}
