import { useCallback } from "react";

import { TaskType } from "__graphql__/types";
import { PageName } from "analytics/events";
import { ErrorTypeForAnalytics, useAnalytics } from "shared/hooks/useAnalytics";
import { useErrorToastWithAudio } from "shared/hooks/useErrorToastWithAudio";
import { useGetPendingCheckCountLazyQuery } from "shared/queries/checkCount/checkCount.generated";
import { reportErrorsToDDAndThrow } from "utils/datadog";

import { serializeCheck } from "../models/check/serializer";
import { Check } from "../models/check/types";
import {
  useGetNextCheckForShelfLazyQuery,
  useStartCheckMutation,
} from "../queries/check/check.generated";

export enum StartCheckError {
  conflict = "409: Conflict",
}

export type StartNextCheckInputType = {
  shelfNumber: string;
  groupedPriority: number[];
  type?: TaskType[];
};

export type StartNextCheckReturnType = {
  check?: Check;
  status: "pendingCheck" | "noMoreCheck" | "unknownError" | "checkAlreadyTaken";
};

export function useStartNextCheck() {
  const { sendSegmentTrackEvent } = useAnalytics();
  const { showToastAndPlayError } = useErrorToastWithAudio({ errorToastId: "stock_machine_error" });

  const [fetchNextCheck, { loading: loadingFetchNextCheck }] = useGetNextCheckForShelfLazyQuery({
    fetchPolicy: "network-only",
  });
  const [startCheck, { loading: loadingStartCheck }] = useStartCheckMutation();

  const trackError = useCallback(
    (error: Error) => {
      sendSegmentTrackEvent("errorShown", {
        screen_name: PageName.INVENTORY_PAGE,
        component_value:
          error.message === "Received status code 409"
            ? ErrorTypeForAnalytics.TASK_ALREADY_TAKEN
            : ErrorTypeForAnalytics.UNKNOWN,
      });
    },
    [sendSegmentTrackEvent],
  );

  const [updateUrgentChecksNotification] = useGetPendingCheckCountLazyQuery({
    variables: {
      input: { priority: [4, 5, 6] },
    },
    fetchPolicy: "network-only",
  });

  const fetchAndStartNextCheck = useCallback(
    async ({
      shelfNumber,
      groupedPriority,
      type,
    }: StartNextCheckInputType): Promise<StartNextCheckReturnType> =>
      reportErrorsToDDAndThrow(
        "fetching and starting next check",
        async () => {
          let check: Check | undefined;
          try {
            const { data } = await fetchNextCheck({
              variables: {
                shelfNumber,
                filters: { priority: groupedPriority, type },
              },
            });
            if (data?.getNextCheckForShelf.check) {
              check = serializeCheck(data.getNextCheckForShelf.check);
            } else {
              return { status: "noMoreCheck" };
            }
          } catch (error: any) {
            showToastAndPlayError({ title: "hooks.use-custom-toast.error-title" });
            trackError(error);
            throw error;
          }
          try {
            if (!check?.checkId) {
              return { status: "unknownError" };
            }
            await startCheck({
              variables: {
                checkId: check.checkId,
              },
            });
          } catch (error: any) {
            trackError(error);
            if (error.message !== StartCheckError.conflict) {
              throw error;
            }
            return { status: "checkAlreadyTaken" };
          }
          return { check, status: "pendingCheck" };
        },
        {
          args: { shelfNumber, groupedPriority },
        },
      ),
    [fetchNextCheck, startCheck, showToastAndPlayError, trackError],
  );

  const startNextCheck = useCallback(
    async (args: StartNextCheckInputType) => {
      const returnValue = await fetchAndStartNextCheck(args);
      await updateUrgentChecksNotification();
      return returnValue;
    },
    [fetchAndStartNextCheck, updateUrgentChecksNotification],
  );

  return {
    startNextCheck,
    isLoading: loadingFetchNextCheck || loadingStartCheck,
  };
}
