import { useCallback, useEffect, useMemo } from "react";

import shallow from "zustand/shallow";

import { useAppLanguageStore } from "core/stores/useAppLanguageStore";
import { useInboundUIStore } from "flows/Inbound/stores/useInboundUIStore";
import { LetterRange, LetterRangeSelector } from "ui/LetterRangeSelector/LetterRangeSelector";
import { SubcategoryList } from "ui/SubcategoryList/SubcategoryList";

export type Category = "fruits" | "vegetables";

const letterRangeTemplates: Omit<LetterRange, "disabled">[] = [
  { id: "AD", from: "A", to: "D" },
  { id: "EH", from: "E", to: "H" },
  { id: "IL", from: "I", to: "L" },
  { id: "MP", from: "M", to: "P" },
  { id: "QT", from: "Q", to: "T" },
  { id: "UX", from: "U", to: "X" },
  { id: "YZ", from: "Y", to: "Z" },
];

type NoEANSubcategoriesProps = {
  chooseSubcategory: (subcategoryID: string) => void;
};

export function ProductsSearchNoEANSubcategoriesList({
  chooseSubcategory,
}: NoEANSubcategoriesProps) {
  const { setInboundUIState, selectedLetterRangeId } = useInboundUIStore((state) => state, shallow);
  const appLanguage = useAppLanguageStore((state) => state.appLanguage);

  // TO_DO
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const filteredSortedGroupedAndTranslatedSubcategories: any = {
    AD: [
      {
        __typename: "SubcategoryV2",
        id: "65c473fe-0cc8-4d38-8497-032682a69a47",
        imageUrl:
          "https://res.cloudinary.com/goflink/image/upload/w_300/product-images-staging/749c2f68-ac95-4b36-b82c-5d46464b3160.webp",
        name: "Asparagus",
        productsCount: 5,
        translatedName: "Asparagus",
      },
      {
        __typename: "SubcategoryV2",
        id: "ca076f4d-3c45-4976-a745-b6f9a65387fc",
        imageUrl:
          "https://res.cloudinary.com/goflink/image/upload/w_300/product-images-staging/722810e8-1b7b-4f07-9c5d-c591955f0adc.webp",
        name: "Aubergine and Zucchini",
        productsCount: 8,
        translatedName: "Aubergine and Zucchini",
      },
      {
        __typename: "SubcategoryV2",
        id: "69827c0c-28a0-4ae1-8895-bf28189e1003",
        imageUrl:
          "https://res.cloudinary.com/goflink/image/upload/w_300/product-images-staging/141bc31c-9c39-4c13-a8f6-9c4b252de44b.webp",
        name: "Avocado",
        productsCount: 6,
        translatedName: "Avocado",
      },
      {
        __typename: "SubcategoryV2",
        id: "1a5fe950-e035-47ce-88bc-b74ec321b403",
        imageUrl:
          "https://res.cloudinary.com/goflink/image/upload/w_300/product-images-staging/cbf74ace-cb7e-4b6f-9770-404656d19d56.webp",
        name: "Beetroot",
        productsCount: 4,
        translatedName: "Beetroot",
      },
      {
        __typename: "SubcategoryV2",
        id: "e7ef046f-3278-4e44-b71e-487eac38142b",
        imageUrl:
          "https://res.cloudinary.com/goflink/image/upload/w_300/de/products/1_11010729_202211151100.webp",
        name: "Broccoli and Cauliflower",
        productsCount: 6,
        translatedName: "Broccoli and Cauliflower",
      },
      {
        __typename: "SubcategoryV2",
        id: "afb8db66-8e4a-449a-969c-0d84a1e8ff05",
        imageUrl:
          "https://res.cloudinary.com/goflink/image/upload/w_300/product-images-staging/404f01e5-43ed-448f-ae43-077087e6ac46.webp",
        name: "Carrots",
        productsCount: 4,
        translatedName: "Carrots",
      },
      {
        __typename: "SubcategoryV2",
        id: "ba30b1c2-b310-44fe-b061-fc9622bcdcb9",
        imageUrl:
          "https://res.cloudinary.com/goflink/image/upload/w_300/product-images-staging/1a529021-8628-4c7f-91f1-88c3a7db29d6.webp",
        name: "Celery, Leek, and Others",
        productsCount: 17,
        translatedName: "Celery, Leek, and Others",
      },
      {
        __typename: "SubcategoryV2",
        id: "846e5db2-b9f2-425f-aad8-d09cfd642acb",
        imageUrl:
          "https://res.cloudinary.com/goflink/image/upload/w_300/product-images-staging/a039d95b-612c-490e-9636-33dea06bd5f4.webp",
        name: "Corn",
        productsCount: 1,
        translatedName: "Corn",
      },
      {
        __typename: "SubcategoryV2",
        id: "554e29c3-e47d-4284-9086-04111ed285d8",
        imageUrl:
          "https://res.cloudinary.com/goflink/image/upload/w_300/de/products/1_11010726_202211110900.webp",
        name: "Cucumbers",
        productsCount: 5,
        translatedName: "Cucumbers",
      },
    ],
  };
  // const filteredSortedGroupedAndTranslatedSubcategories =
  //   useFilteredSortedGroupedAndTranslatedSubcategories(letterRangeTemplates) as Record<
  //     string,
  //     TranslatedSubcategory[]
  //   >;
  // asserting type here because there is a guard clause in
  // the parent component that doesn't display this component
  // whenever the categories are null.

  const letterRanges: LetterRange[] = useMemo(() => {
    return letterRangeTemplates.map((template) => ({
      ...template,
      disabled: filteredSortedGroupedAndTranslatedSubcategories[template.id]?.length === 0,
    }));
  }, [filteredSortedGroupedAndTranslatedSubcategories]);

  // The following function will detect if the currently selected letter range is disabled.
  // If so, it will look for the (alphabetically) first non-disabled letter range instead.
  const safeSelectedLetterRangeId = useMemo(() => {
    if (!letterRanges.find((lr) => lr.id === selectedLetterRangeId)?.disabled) {
      return selectedLetterRangeId;
    }
    return letterRanges.find((lr) => !lr.disabled)?.id || selectedLetterRangeId;
  }, [letterRanges, selectedLetterRangeId]);

  const selectLetterRangeId = useCallback(
    (letterRangeId: string) => setInboundUIState({ selectedLetterRangeId: letterRangeId }),
    [setInboundUIState],
  );

  useEffect(() => {
    selectLetterRangeId(safeSelectedLetterRangeId);
  }, [safeSelectedLetterRangeId, selectLetterRangeId]);

  const displayedSubcategories = useMemo(
    () => filteredSortedGroupedAndTranslatedSubcategories[safeSelectedLetterRangeId],
    [filteredSortedGroupedAndTranslatedSubcategories, safeSelectedLetterRangeId],
  );

  const handleSubcategoryClick = useCallback(
    (subcategoryID: string) => {
      setTimeout(() => chooseSubcategory(subcategoryID), 90);
    },
    [chooseSubcategory],
  );

  return (
    <>
      <LetterRangeSelector
        p="s200"
        pt="0"
        value={safeSelectedLetterRangeId}
        letterRanges={letterRanges}
        onInput={(v) => selectLetterRangeId(v as string)}
      />
      <SubcategoryList
        subcategories={displayedSubcategories}
        onClickSubcategory={handleSubcategoryClick}
        lang={appLanguage}
        bgColor="white"
        pt="s300"
        overflowY="scroll"
      />
    </>
  );
}
