import { useMemo, useState } from "react";

import { Box, Flex, Image } from "@chakra-ui/react";
import { shallowEqual, useSelector } from "@xstate/react";
import { useIntl } from "react-intl";

import { useAppLanguageStore } from "core/stores/useAppLanguageStore";
import { TaskCheckHeader } from "flows/Inventory/shared/components/TaskCheckHeader";
import { serializeAndTranslateCheck } from "flows/Inventory/shared/models/check/serializer";
import { useGetCheckByIdQuery } from "flows/Inventory/shared/queries/check/check.generated";
import { useGetProductStockQuery } from "flows/Inventory/shared/queries/inventoryEntry/inventoryEntry.generated";
import { IncreaseDecrease } from "shared/components/IncreaseDecrease";
import { Page } from "shared/components/Page";
import { SpinnerModal } from "shared/components/SpinnerModal";
import { Button } from "ui/Button/Button";
import { BodyS } from "ui/Typography/Typography";
import { formatImage } from "utils/image";
import { isNotNullNorUndefined } from "utils/tsHelpers";

import { useFreshCheckService } from "../hooks/useFreshCheckService";

const IMAGE_SIZE = 218;
export function FreshCheckTask() {
  const intl = useIntl();
  const appLanguage = useAppLanguageStore((state) => state.appLanguage);

  const freshCheckService = useFreshCheckService();

  const { checkId, origin, shelfLetter, shelfNumber } = useSelector(
    freshCheckService,
    ({ context }) => context,
    shallowEqual,
  );

  const isUpdating = useSelector(freshCheckService, (state) => state.matches("performCheckUpdate"));

  const { data } = useGetCheckByIdQuery({
    variables: { checkId: checkId! },
  });

  const { sku, productName, productImageUrl, productCountryOfOriginName } = useMemo(() => {
    return serializeAndTranslateCheck(data?.getTaskById?.task!, appLanguage);
  }, [data, appLanguage]);

  const [quantityExpired, setQuantityExpired] = useState<number>(0);
  const [quantityDamaged, setQuantityDamaged] = useState<number>(0);
  const [quantityTooGoodToGo, setQuantityTooGoodToGo] = useState<number>(0);

  const { data: upToDateStockQuantity, loading } = useGetProductStockQuery({
    variables: { sku: sku! },
    fetchPolicy: "network-only",
    skip: !sku,
  });

  const totalStock = upToDateStockQuantity?.getProduct?.inventoryEntry.stock.shelf ?? null;
  const totalNewStock =
    (totalStock || 0) -
    ((quantityExpired || 0) + (quantityDamaged || 0) + (quantityTooGoodToGo || 0));

  const isIncreaseBtnDisabled = totalNewStock === 0;

  const declareStock = () => {
    freshCheckService.send({
      type: "DECLARE_STOCK_DETAILS",
      expectedQuantity: totalStock ?? 0,
      remainingStock: totalNewStock,
      damagedQuantity: quantityDamaged,
      expiredQuantity: quantityExpired,
      tooGoodToGoQuantity: quantityTooGoodToGo,
    });
  };

  const imageUrlFormatted = isNotNullNorUndefined(productImageUrl)
    ? formatImage(productImageUrl, IMAGE_SIZE, IMAGE_SIZE)
    : null;

  return (
    <Page isCentered isBgGrey isFull>
      <SpinnerModal isOpen={loading} />
      <TaskCheckHeader
        headerMessageId="pages.fresh-check-header.fresh-check"
        origin={origin}
        shelfLetter={shelfLetter}
        shelfNumber={shelfNumber}
      />
      <Flex flex={1} flexDir="column" overflowY="scroll" w="100%">
        <Flex flex={1} align="center" flexDir="column" justify="center" px="s200">
          <Flex flex={1} align="center">
            {isNotNullNorUndefined(imageUrlFormatted) && (
              <Image
                src={imageUrlFormatted}
                objectFit="contain"
                h="25vh"
                maxW={`${IMAGE_SIZE}px`}
                maxH={`${IMAGE_SIZE}px`}
              />
            )}
          </Flex>
          <Box
            py="s100"
            px="s200"
            mb="s100"
            bg="white"
            borderRadius="sm"
            justifyContent="center"
            w="100%"
          >
            <BodyS w="100%" textAlign="center" color="grey.800">
              {productName} {productCountryOfOriginName ? ` (${productCountryOfOriginName})` : null}
            </BodyS>
          </Box>
        </Flex>
        <Box bg="white">
          <Box
            overflowY="scroll"
            p="s150"
            m="s150"
            mb="0px"
            maxH="245px"
            css={{
              "&::-webkit-scrollbar": {
                width: "4px",
              },
              "&::-webkit-scrollbar-track": {
                width: "4px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#DEDEDE",
                borderRadius: "8px",
              },
            }}
          >
            <IncreaseDecrease
              labelMessageId="components.increasedecrease.how_many_are_perished"
              changeHandler={setQuantityExpired}
              count={quantityExpired || 0}
              isIncreaseBtnDisabled={isIncreaseBtnDisabled}
            />
            <IncreaseDecrease
              labelMessageId="components.increasedecrease.how_many_are_damaged"
              changeHandler={setQuantityDamaged}
              count={quantityDamaged || 0}
              isIncreaseBtnDisabled={isIncreaseBtnDisabled}
            />
            <IncreaseDecrease
              dataTestId="too_good_to_go"
              labelMessageId="components.increasedecrease.too_good_to_go"
              changeHandler={setQuantityTooGoodToGo}
              isIncreaseBtnDisabled={isIncreaseBtnDisabled}
              count={quantityTooGoodToGo}
            />
          </Box>
          <Box p="s200" borderTopColor="grey.200" borderTopWidth="1px">
            <Button
              size="lg"
              width="100%"
              onClick={declareStock}
              disabled={isUpdating}
              isLoading={isUpdating}
            >
              {intl.formatMessage({ id: "components.fresh-check.confirm-amount-button" })}
            </Button>
          </Box>
        </Box>
      </Flex>
    </Page>
  );
}
