import { Button, Flex } from "@chakra-ui/react";

import { ArrowLeftIcon } from "ui/Icons/Icons";
import { TitleS } from "ui/Typography/Typography";

type SkipStepButtonProps = {
  onClick: () => void;
  label: string;
};

export function SkipStepButton({ onClick, label }: SkipStepButtonProps) {
  return (
    <Flex minH="70px" w="100%" alignItems="center" justifyContent="center">
      <Button
        onClick={onClick}
        h="40px"
        w="120px"
        variant="ghost"
        rightIcon={<ArrowLeftIcon boxSize={5} transform="rotate(180deg)" color="grey.800" />}
        data-testid="skip-step-button"
      >
        <TitleS color="grey.800">{label}</TitleS>
      </Button>
    </Flex>
  );
}
