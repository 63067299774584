import { useCallback, useEffect, useMemo, useState } from "react";

import { Box, Flex } from "@chakra-ui/react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router";
import shallow from "zustand/shallow";

import { routes } from "config/routes";
import { useAppLanguageStore } from "core/stores/useAppLanguageStore";
import { EndDroppingProcessButton } from "flows/Inbound/components/EndDroppingProcessButton";
import { countryNameFromCode } from "libs/countryNames";
import { SpinnerModal } from "shared/components/SpinnerModal";
import { serializeInboundUnitFromSearchResult } from "shared/models/productSearchUnit/serializer";
import { TranslatedProductSearchUnit } from "shared/models/productSearchUnit/types";
import { useSearchUnitsByTextLazyQuery } from "shared/queries/productSearchUnit/productSearchUnit.generated";
import { useProductSearchStore } from "shared/stores/useProductSearchStore";
import { ProductSearchResultCard } from "ui/ProductSearchResultCard/ProductSearchResultCard";
import { BodyS, TitleM } from "ui/Typography/Typography";
import { isNullOrUndefined } from "utils/tsHelpers";

import { useInboundUIStore } from "../stores/useInboundUIStore";

export function ProductsSearchInboundUnitsResultsList() {
  const intl = useIntl();
  const navigate = useNavigate();

  const appLanguage = useAppLanguageStore((state) => state.appLanguage);

  const { searchedUnitType, setInboundUIState } = useInboundUIStore(
    (state) => ({
      searchedUnitType: state.searchedUnitType,
      setInboundUIState: state.setInboundUIState,
    }),
    shallow,
  );
  const { textSearchQuery, selectedProducts, setSelectedProducts, resetTextSearch } =
    useProductSearchStore(
      (state) => ({
        textSearchQuery: state.textSearch,
        selectedProducts: state.selectedProducts,
        setSelectedProducts: state.setSelectedProducts,
        resetTextSearch: state.resetTextSearch,
      }),
      shallow,
    );

  const [searchUnitsByText, { data, loading }] = useSearchUnitsByTextLazyQuery({
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (textSearchQuery?.length >= 3) {
      searchUnitsByText({ variables: { searchUnitsByTextInput: { query: textSearchQuery } } });
    }
  }, [textSearchQuery, searchUnitsByText]);

  const searchResults = useMemo(() => {
    if (!data) return null;
    return {
      matchType: data.searchUnitsByText.matchType,
      origin: "textSearch",
      results: data.searchUnitsByText.units.map(serializeInboundUnitFromSearchResult),
    };
  }, [data]);

  const transformedSearchResults: TranslatedProductSearchUnit[] | null = useMemo(() => {
    if (!searchResults) {
      return null;
    }
    const translatedResults: TranslatedProductSearchUnit[] = searchResults.results.map(
      (inboundUnit) => ({
        ...inboundUnit,
        countryOfOriginName: countryNameFromCode(appLanguage, inboundUnit.countryOfOriginCode),
      }),
    );

    if (searchResults.matchType === "ean") return translatedResults;

    return translatedResults.filter(
      (result, index, self) =>
        index ===
          self.findIndex(
            (unit) => unit.productSku === result.productSku && unit.quantity === result.quantity,
          ) && result.unitType === searchedUnitType,
    );
  }, [appLanguage, searchResults, searchedUnitType]);

  const shouldShowNoResults = useMemo(
    () =>
      searchResults?.origin === "textSearch" &&
      textSearchQuery.length > 2 &&
      transformedSearchResults?.length === 0,
    [searchResults, textSearchQuery.length, transformedSearchResults],
  );

  const [selectedStates, setSelectedStates] = useState<Record<string, boolean>>({});

  const selectedResults = useMemo(
    () => transformedSearchResults?.filter(({ id }) => selectedStates[id]) || [],
    [selectedStates, transformedSearchResults],
  );

  const handleToggleSelection = useCallback((id: string) => {
    setSelectedStates((prevState) => ({ ...prevState, [id]: !prevState[id] }));
  }, []);

  const handleAddToList = useCallback(() => {
    const newProducts = selectedResults;
    const existingProductIds = new Set(selectedProducts.map((product) => product.id));
    const uniqueNewProducts = newProducts.filter((product) => !existingProductIds.has(product.id));
    const hasDuplicates = newProducts.some((product) => existingProductIds.has(product.id));

    if (hasDuplicates) {
      setInboundUIState({ showProductAlreadyAddedBanner: true });
    }
    setSelectedProducts([...uniqueNewProducts, ...selectedProducts]);
    resetTextSearch();
    navigate(routes.inbound.preDropping);
  }, [
    selectedResults,
    selectedProducts,
    setSelectedProducts,
    resetTextSearch,
    navigate,
    setInboundUIState,
  ]);

  const productCardLabels = useMemo(
    () => ({
      singleUnitLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-search-results-list.single-unit-label",
      }),
      handlingUnitLabel: intl.formatMessage({
        id: "components.inbound.inbound-units-search-results-list.handling-unit-label",
      }),
    }),
    [intl],
  );

  if (loading) return <SpinnerModal isOpen />;

  if (isNullOrUndefined(transformedSearchResults)) return null;

  return (
    <Flex
      direction="column"
      w="100%"
      gap="s50"
      h={shouldShowNoResults ? "100%" : undefined}
      data-testid="inbound-unit-search-results-list"
    >
      {shouldShowNoResults ? (
        <Flex direction="column" flex={1} justifyContent="center" textAlign="center">
          <TitleM>
            <FormattedMessage id="components.inbound.inbound-units-search-results-list.no-results-found.title" />
          </TitleM>
          <BodyS>
            <FormattedMessage id="components.inbound.inbound-units-search-results-list.no-results-found.description" />
          </BodyS>
        </Flex>
      ) : (
        <>
          {transformedSearchResults.map((result) => (
            <ProductSearchResultCard
              key={result.id}
              {...result}
              {...productCardLabels}
              onClickProductCard={() => handleToggleSelection(result.id)}
              isHighlighted={!!selectedStates[result.id]}
            />
          ))}
          <Box mb="60px" />
          <EndDroppingProcessButton
            shouldShowButton={selectedResults.length > 0}
            onClickButton={handleAddToList}
            labelMessageId="components.inbound.inbound-units-search-results-list.add-to-list-button-label"
            buttonTestId="add-to-list-button"
            bottom="0"
            p="s200"
          />
        </>
      )}
    </Flex>
  );
}
