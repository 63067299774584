import { useEffect } from "react";

import { useLocation, useParams } from "react-router-dom";

import { CheckStatus } from "__graphql__/types";
import { PageName } from "analytics/events";
import { isNullOrUndefined } from "utils/tsHelpers";

import { GetCheckByIdQueryResult, useGetCheckByIdQuery } from "../queries/check/check.generated";
import { useChecksRedirect } from "./useChecksRedirect";

const isInvalidTask = (task: GetCheckByIdQueryResult["data"]) => {
  return (
    [task, task?.getTaskById?.task?.product?.sku, task?.getTaskById?.task?.priority].some(
      isNullOrUndefined,
    ) || task?.getTaskById?.task?.status === CheckStatus.done // If the task was already treated, we obviously need to treat it as invalid
  );
};

export function useCheckRoutesEffects() {
  const { checkId } = useParams<{ checkId: string | undefined }>();
  const { state: locationState } = useLocation() as {
    state: { origin: PageName } | null;
  };

  const { navigateToOrigin } = useChecksRedirect();

  const { data, loading } = useGetCheckByIdQuery({
    skip: isNullOrUndefined(checkId),
    variables: { checkId: checkId! },
    fetchPolicy: "network-only",
  });

  const isInvalidParams = isNullOrUndefined(checkId) || (!loading && isInvalidTask(data));

  useEffect(() => {
    if (isInvalidParams) {
      navigateToOrigin(locationState?.origin as PageName);
    }
  }, [isInvalidParams, navigateToOrigin, locationState?.origin]);

  return {
    loading,
    data,
    isInvalidParams,
    checkId,
    origin: locationState?.origin as PageName,
  };
}
