import * as Types from "../../../../__graphql__/types.js";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type OrderFragment = {
  __typename?: "Order";
  ageVerification: string;
  createdAt?: string | null;
  customerFirstName?: string | null;
  customerLastName?: string | null;
  externalProviderId?: string | null;
  id: string;
  isClickAndCollect: boolean;
  isInStore: boolean;
  isNewCustomer: boolean;
  number?: string | null;
  pickingItemCount: number;
  reseller: string;
  state?: string | null;
  weight: number;
};

export type ItemFragment = {
  __typename?: "Item";
  id: string;
  sku?: string | null;
  quantity?: number | null;
  product?: {
    __typename?: "IProduct";
    name: string;
    imageUrl?: string | null;
    sku: string;
    countryOfOrigin: { __typename?: "CountryOfOrigin"; code?: string | null };
    inventoryEntry: {
      __typename?: "InventoryEntry";
      shelfNumber?: string | null;
      stock: { __typename?: "Stock"; shelf: number };
    };
    units: Array<{ __typename?: "Unit"; ean?: string | null; type: string; quantity: number }>;
  } | null;
};

export type GetNextOrderForPickingQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetNextOrderForPickingQuery = {
  __typename?: "Query";
  nextOrderForPicking: {
    __typename?: "NextOrderToBePreparedResponse";
    order?: {
      __typename?: "Order";
      ageVerification: string;
      createdAt?: string | null;
      customerFirstName?: string | null;
      customerLastName?: string | null;
      externalProviderId?: string | null;
      id: string;
      isClickAndCollect: boolean;
      isInStore: boolean;
      isNewCustomer: boolean;
      number?: string | null;
      pickingItemCount: number;
      reseller: string;
      state?: string | null;
      weight: number;
      items: Array<{
        __typename?: "Item";
        id: string;
        sku?: string | null;
        quantity?: number | null;
        product?: {
          __typename?: "IProduct";
          name: string;
          imageUrl?: string | null;
          sku: string;
          countryOfOrigin: { __typename?: "CountryOfOrigin"; code?: string | null };
          inventoryEntry: {
            __typename?: "InventoryEntry";
            shelfNumber?: string | null;
            stock: { __typename?: "Stock"; shelf: number };
          };
          units: Array<{
            __typename?: "Unit";
            ean?: string | null;
            type: string;
            quantity: number;
          }>;
        } | null;
      } | null>;
    } | null;
  };
};

export type GetOrderQueryVariables = Types.Exact<{
  input: Types.GetOrderInput;
}>;

export type GetOrderQuery = {
  __typename?: "Query";
  getOrder: {
    __typename?: "GetOrderResponse";
    order: {
      __typename?: "Order";
      ageVerification: string;
      createdAt?: string | null;
      customerFirstName?: string | null;
      customerLastName?: string | null;
      externalProviderId?: string | null;
      id: string;
      isClickAndCollect: boolean;
      isInStore: boolean;
      isNewCustomer: boolean;
      number?: string | null;
      pickingItemCount: number;
      reseller: string;
      state?: string | null;
      weight: number;
      items: Array<{
        __typename?: "Item";
        id: string;
        sku?: string | null;
        quantity?: number | null;
        product?: {
          __typename?: "IProduct";
          name: string;
          imageUrl?: string | null;
          sku: string;
          countryOfOrigin: { __typename?: "CountryOfOrigin"; code?: string | null };
          inventoryEntry: {
            __typename?: "InventoryEntry";
            shelfNumber?: string | null;
            stock: { __typename?: "Stock"; shelf: number };
          };
          units: Array<{
            __typename?: "Unit";
            ean?: string | null;
            type: string;
            quantity: number;
          }>;
        } | null;
      } | null>;
    };
  };
};

export type StartPickingMutationVariables = Types.Exact<{
  orderNumber: Types.Scalars["String"];
}>;

export type StartPickingMutation = {
  __typename?: "Mutation";
  startPickingV2: {
    __typename?: "StartPickingResponse";
    deliveryTag?: string | null;
    externalDeliveryProvider?: string | null;
    handoverIdentifier?: string | null;
    order?: {
      __typename?: "Order";
      ageVerification: string;
      createdAt?: string | null;
      customerFirstName?: string | null;
      customerLastName?: string | null;
      externalProviderId?: string | null;
      id: string;
      isClickAndCollect: boolean;
      isInStore: boolean;
      isNewCustomer: boolean;
      number?: string | null;
      pickingItemCount: number;
      reseller: string;
      state?: string | null;
      weight: number;
      items: Array<{
        __typename?: "Item";
        id: string;
        sku?: string | null;
        quantity?: number | null;
        product?: {
          __typename?: "IProduct";
          name: string;
          imageUrl?: string | null;
          sku: string;
          countryOfOrigin: { __typename?: "CountryOfOrigin"; code?: string | null };
          inventoryEntry: {
            __typename?: "InventoryEntry";
            shelfNumber?: string | null;
            stock: { __typename?: "Stock"; shelf: number };
          };
          units: Array<{
            __typename?: "Unit";
            ean?: string | null;
            type: string;
            quantity: number;
          }>;
        } | null;
      } | null>;
    } | null;
  };
};

export type StartManualPickingMutationVariables = Types.Exact<{
  orderNumber: Types.Scalars["String"];
}>;

export type StartManualPickingMutation = {
  __typename?: "Mutation";
  startManualPickingV2: {
    __typename?: "StartPickingResponse";
    deliveryTag?: string | null;
    externalDeliveryProvider?: string | null;
    handoverIdentifier?: string | null;
    order?: {
      __typename?: "Order";
      ageVerification: string;
      createdAt?: string | null;
      customerFirstName?: string | null;
      customerLastName?: string | null;
      externalProviderId?: string | null;
      id: string;
      isClickAndCollect: boolean;
      isInStore: boolean;
      isNewCustomer: boolean;
      number?: string | null;
      pickingItemCount: number;
      reseller: string;
      state?: string | null;
      weight: number;
      items: Array<{
        __typename?: "Item";
        id: string;
        sku?: string | null;
        quantity?: number | null;
        product?: {
          __typename?: "IProduct";
          name: string;
          imageUrl?: string | null;
          sku: string;
          countryOfOrigin: { __typename?: "CountryOfOrigin"; code?: string | null };
          inventoryEntry: {
            __typename?: "InventoryEntry";
            shelfNumber?: string | null;
            stock: { __typename?: "Stock"; shelf: number };
          };
          units: Array<{
            __typename?: "Unit";
            ean?: string | null;
            type: string;
            quantity: number;
          }>;
        } | null;
      } | null>;
    } | null;
  };
};

export type EndPickingMutationVariables = Types.Exact<{
  orderNumber: Types.Scalars["String"];
  missingItems: Array<Types.MissingItem> | Types.MissingItem;
  containersIds?: Types.InputMaybe<Array<Types.Scalars["String"]> | Types.Scalars["String"]>;
  shelvesIds?: Types.InputMaybe<Array<Types.Scalars["String"]> | Types.Scalars["String"]>;
}>;

export type EndPickingMutation = {
  __typename?: "Mutation";
  endPicking: { __typename?: "MutationResponse"; message: string };
};

export const OrderFragmentDoc = gql`
  fragment orderFragment on Order {
    ageVerification
    createdAt
    customerFirstName
    customerLastName
    externalProviderId
    id
    isClickAndCollect
    isInStore
    isNewCustomer
    number
    pickingItemCount
    reseller
    state
    weight
  }
`;
export const ItemFragmentDoc = gql`
  fragment itemFragment on Item {
    id
    sku
    quantity
    product {
      name
      imageUrl
      sku
      countryOfOrigin {
        code
      }
      inventoryEntry {
        shelfNumber
        stock {
          shelf
        }
      }
      units {
        ean
        type
        quantity
      }
    }
  }
`;
export const GetNextOrderForPickingDocument = gql`
  query getNextOrderForPicking {
    nextOrderForPicking {
      order {
        ...orderFragment
        items {
          ...itemFragment
        }
      }
    }
  }
  ${OrderFragmentDoc}
  ${ItemFragmentDoc}
`;

/**
 * __useGetNextOrderForPickingQuery__
 *
 * To run a query within a React component, call `useGetNextOrderForPickingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNextOrderForPickingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNextOrderForPickingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNextOrderForPickingQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetNextOrderForPickingQuery,
    GetNextOrderForPickingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetNextOrderForPickingQuery, GetNextOrderForPickingQueryVariables>(
    GetNextOrderForPickingDocument,
    options,
  );
}
export function useGetNextOrderForPickingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNextOrderForPickingQuery,
    GetNextOrderForPickingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetNextOrderForPickingQuery, GetNextOrderForPickingQueryVariables>(
    GetNextOrderForPickingDocument,
    options,
  );
}
export type GetNextOrderForPickingQueryHookResult = ReturnType<
  typeof useGetNextOrderForPickingQuery
>;
export type GetNextOrderForPickingLazyQueryHookResult = ReturnType<
  typeof useGetNextOrderForPickingLazyQuery
>;
export type GetNextOrderForPickingQueryResult = Apollo.QueryResult<
  GetNextOrderForPickingQuery,
  GetNextOrderForPickingQueryVariables
>;
export const GetOrderDocument = gql`
  query getOrder($input: GetOrderInput!) {
    getOrder(input: $input) {
      order {
        ...orderFragment
        items {
          ...itemFragment
        }
      }
    }
  }
  ${OrderFragmentDoc}
  ${ItemFragmentDoc}
`;

/**
 * __useGetOrderQuery__
 *
 * To run a query within a React component, call `useGetOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetOrderQuery(
  baseOptions: Apollo.QueryHookOptions<GetOrderQuery, GetOrderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrderQuery, GetOrderQueryVariables>(GetOrderDocument, options);
}
export function useGetOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOrderQuery, GetOrderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrderQuery, GetOrderQueryVariables>(GetOrderDocument, options);
}
export type GetOrderQueryHookResult = ReturnType<typeof useGetOrderQuery>;
export type GetOrderLazyQueryHookResult = ReturnType<typeof useGetOrderLazyQuery>;
export type GetOrderQueryResult = Apollo.QueryResult<GetOrderQuery, GetOrderQueryVariables>;
export const StartPickingDocument = gql`
  mutation startPicking($orderNumber: String!) {
    startPickingV2(orderNumber: $orderNumber) {
      deliveryTag
      externalDeliveryProvider
      handoverIdentifier
      order {
        ...orderFragment
        items {
          ...itemFragment
        }
      }
    }
  }
  ${OrderFragmentDoc}
  ${ItemFragmentDoc}
`;
export type StartPickingMutationFn = Apollo.MutationFunction<
  StartPickingMutation,
  StartPickingMutationVariables
>;

/**
 * __useStartPickingMutation__
 *
 * To run a mutation, you first call `useStartPickingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartPickingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startPickingMutation, { data, loading, error }] = useStartPickingMutation({
 *   variables: {
 *      orderNumber: // value for 'orderNumber'
 *   },
 * });
 */
export function useStartPickingMutation(
  baseOptions?: Apollo.MutationHookOptions<StartPickingMutation, StartPickingMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<StartPickingMutation, StartPickingMutationVariables>(
    StartPickingDocument,
    options,
  );
}
export type StartPickingMutationHookResult = ReturnType<typeof useStartPickingMutation>;
export type StartPickingMutationResult = Apollo.MutationResult<StartPickingMutation>;
export type StartPickingMutationOptions = Apollo.BaseMutationOptions<
  StartPickingMutation,
  StartPickingMutationVariables
>;
export const StartManualPickingDocument = gql`
  mutation startManualPicking($orderNumber: String!) {
    startManualPickingV2(orderNumber: $orderNumber) {
      deliveryTag
      externalDeliveryProvider
      handoverIdentifier
      order {
        ...orderFragment
        items {
          ...itemFragment
        }
      }
    }
  }
  ${OrderFragmentDoc}
  ${ItemFragmentDoc}
`;
export type StartManualPickingMutationFn = Apollo.MutationFunction<
  StartManualPickingMutation,
  StartManualPickingMutationVariables
>;

/**
 * __useStartManualPickingMutation__
 *
 * To run a mutation, you first call `useStartManualPickingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartManualPickingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startManualPickingMutation, { data, loading, error }] = useStartManualPickingMutation({
 *   variables: {
 *      orderNumber: // value for 'orderNumber'
 *   },
 * });
 */
export function useStartManualPickingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StartManualPickingMutation,
    StartManualPickingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<StartManualPickingMutation, StartManualPickingMutationVariables>(
    StartManualPickingDocument,
    options,
  );
}
export type StartManualPickingMutationHookResult = ReturnType<typeof useStartManualPickingMutation>;
export type StartManualPickingMutationResult = Apollo.MutationResult<StartManualPickingMutation>;
export type StartManualPickingMutationOptions = Apollo.BaseMutationOptions<
  StartManualPickingMutation,
  StartManualPickingMutationVariables
>;
export const EndPickingDocument = gql`
  mutation endPicking(
    $orderNumber: String!
    $missingItems: [MissingItem!]!
    $containersIds: [String!]
    $shelvesIds: [String!]
  ) {
    endPicking(
      orderNumber: $orderNumber
      missingItems: $missingItems
      containersIds: $containersIds
      shelvesIds: $shelvesIds
    ) {
      message
    }
  }
`;
export type EndPickingMutationFn = Apollo.MutationFunction<
  EndPickingMutation,
  EndPickingMutationVariables
>;

/**
 * __useEndPickingMutation__
 *
 * To run a mutation, you first call `useEndPickingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndPickingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endPickingMutation, { data, loading, error }] = useEndPickingMutation({
 *   variables: {
 *      orderNumber: // value for 'orderNumber'
 *      missingItems: // value for 'missingItems'
 *      containersIds: // value for 'containersIds'
 *      shelvesIds: // value for 'shelvesIds'
 *   },
 * });
 */
export function useEndPickingMutation(
  baseOptions?: Apollo.MutationHookOptions<EndPickingMutation, EndPickingMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EndPickingMutation, EndPickingMutationVariables>(
    EndPickingDocument,
    options,
  );
}
export type EndPickingMutationHookResult = ReturnType<typeof useEndPickingMutation>;
export type EndPickingMutationResult = Apollo.MutationResult<EndPickingMutation>;
export type EndPickingMutationOptions = Apollo.BaseMutationOptions<
  EndPickingMutation,
  EndPickingMutationVariables
>;
