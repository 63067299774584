import { useApolloClient } from "@apollo/client";

import { serializeProductsInSubcategory } from "shared/models/product/serializer";
import { serializeInboundUnitFromSearchResult } from "shared/models/productSearchUnit/serializer";
import {
  GetNonScannableCategoriesDocument,
  GetNonScannableCategoriesQuery,
} from "shared/queries/category/category.generated";
import {
  GetProductsBySubcategoryV2Document,
  GetProductsBySubcategoryV2Query,
} from "shared/queries/product/product.generated";
import {
  SearchUnitsByEanDocument,
  SearchUnitsByEanQuery,
  SearchUnitsByEanQueryVariables,
  SearchUnitsByTextDocument,
  SearchUnitsByTextQuery,
  SearchUnitsByTextQueryVariables,
} from "shared/queries/productSearchUnit/productSearchUnit.generated";
import { reportErrorsToDDAndThrow } from "utils/datadog";
import { getFetchPolicyWithCacheTimeout } from "utils/graphql/graphql";

import {
  FetchUnitsByEAN,
  FetchUnitsByText,
  FetchNonScannableCategories,
  FetchProductsForSubcategory,
  MatchType,
} from "./types";

const MAX_EAN_SEARCH_LENGTH = 50;

export function useProductSearchServiceImplems() {
  const client = useApolloClient();

  const fetchUnitsByEAN: FetchUnitsByEAN = async (_, { ean }) => {
    return reportErrorsToDDAndThrow("searching units by EAN", async () => {
      ean = ean.slice(0, MAX_EAN_SEARCH_LENGTH); // Sending super long search queries can slow down the BE
      const { data } = await client.query<SearchUnitsByEanQuery, SearchUnitsByEanQueryVariables>({
        query: SearchUnitsByEanDocument,
        variables: { searchUnitsByEanInput: { ean } },
        fetchPolicy: "no-cache",
      });
      return {
        matchType: "ean",
        origin: "scan",
        results: data.searchUnitsByEan.units.map((unit) =>
          serializeInboundUnitFromSearchResult(unit),
        ),
        searchedValue: ean,
      };
    });
  };

  const fetchUnitsByText: FetchUnitsByText = async (_, event) => {
    return reportErrorsToDDAndThrow("searching units by text", async () => {
      if (!("query" in event) || event.query.length < 3) {
        return null;
      }
      const { data } = await client.query<SearchUnitsByTextQuery, SearchUnitsByTextQueryVariables>({
        query: SearchUnitsByTextDocument,
        variables: { searchUnitsByTextInput: { query: event.query } },
        fetchPolicy: "no-cache",
      });
      return {
        matchType: data.searchUnitsByText.matchType as MatchType,
        origin: "textSearch",
        results: data.searchUnitsByText.units.map((unit) =>
          serializeInboundUnitFromSearchResult(unit),
        ),
        searchedValue: event.query,
      };
    });
  };

  const fetchNonScannableCategories: FetchNonScannableCategories = async () => {
    return reportErrorsToDDAndThrow("fetching non-scannable subcategories", async () => {
      const { data } = await client.query<GetNonScannableCategoriesQuery>({
        query: GetNonScannableCategoriesDocument,
        fetchPolicy: getFetchPolicyWithCacheTimeout({
          key: "getNonScannableCategories",
          minutes: 60,
        }),
      });
      return data.getNonScannableCategories.categories;
    });
  };

  const fetchProductsForSubcategory: FetchProductsForSubcategory = async (context) => {
    return reportErrorsToDDAndThrow("fetching products by subcategory", async () => {
      const { data } = await client.query<GetProductsBySubcategoryV2Query>({
        query: GetProductsBySubcategoryV2Document,
        variables: {
          input: {
            subcategoryId: context.selectedSubcategoryID,
          },
        },
        fetchPolicy: "no-cache",
      });
      return serializeProductsInSubcategory(data);
    });
  };

  return {
    fetchUnitsByEAN,
    fetchUnitsByText,
    fetchNonScannableCategories,
    fetchProductsForSubcategory,
  };
}
