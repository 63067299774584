import * as Types from "../../../../__graphql__/types.js";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type GetProductsStockQueryVariables = Types.Exact<{
  input: Types.GetProductsInput;
}>;

export type GetProductsStockQuery = {
  __typename?: "Query";
  getProducts: {
    __typename?: "GetProductsResponse";
    products: Array<{
      __typename?: "IProduct";
      sku: string;
      inventoryEntry: {
        __typename?: "InventoryEntry";
        stock: { __typename?: "Stock"; shelf: number };
      };
    }>;
  };
};

export type GetUnitsSizesQueryVariables = Types.Exact<{
  input: Types.GetProductsInput;
}>;

export type GetUnitsSizesQuery = {
  __typename?: "Query";
  getProducts: {
    __typename?: "GetProductsResponse";
    products: Array<{
      __typename?: "IProduct";
      sku: string;
      units: Array<{ __typename?: "Unit"; quantity: number }>;
    }>;
  };
};

export const GetProductsStockDocument = gql`
  query getProductsStock($input: GetProductsInput!) {
    getProducts(input: $input) {
      products {
        sku
        inventoryEntry {
          stock {
            shelf
          }
        }
      }
    }
  }
`;

/**
 * __useGetProductsStockQuery__
 *
 * To run a query within a React component, call `useGetProductsStockQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsStockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsStockQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetProductsStockQuery(
  baseOptions: Apollo.QueryHookOptions<GetProductsStockQuery, GetProductsStockQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductsStockQuery, GetProductsStockQueryVariables>(
    GetProductsStockDocument,
    options,
  );
}
export function useGetProductsStockLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetProductsStockQuery, GetProductsStockQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProductsStockQuery, GetProductsStockQueryVariables>(
    GetProductsStockDocument,
    options,
  );
}
export type GetProductsStockQueryHookResult = ReturnType<typeof useGetProductsStockQuery>;
export type GetProductsStockLazyQueryHookResult = ReturnType<typeof useGetProductsStockLazyQuery>;
export type GetProductsStockQueryResult = Apollo.QueryResult<
  GetProductsStockQuery,
  GetProductsStockQueryVariables
>;
export const GetUnitsSizesDocument = gql`
  query getUnitsSizes($input: GetProductsInput!) {
    getProducts(input: $input) {
      products {
        sku
        units {
          quantity
        }
      }
    }
  }
`;

/**
 * __useGetUnitsSizesQuery__
 *
 * To run a query within a React component, call `useGetUnitsSizesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnitsSizesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnitsSizesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUnitsSizesQuery(
  baseOptions: Apollo.QueryHookOptions<GetUnitsSizesQuery, GetUnitsSizesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUnitsSizesQuery, GetUnitsSizesQueryVariables>(
    GetUnitsSizesDocument,
    options,
  );
}
export function useGetUnitsSizesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUnitsSizesQuery, GetUnitsSizesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUnitsSizesQuery, GetUnitsSizesQueryVariables>(
    GetUnitsSizesDocument,
    options,
  );
}
export type GetUnitsSizesQueryHookResult = ReturnType<typeof useGetUnitsSizesQuery>;
export type GetUnitsSizesLazyQueryHookResult = ReturnType<typeof useGetUnitsSizesLazyQuery>;
export type GetUnitsSizesQueryResult = Apollo.QueryResult<
  GetUnitsSizesQuery,
  GetUnitsSizesQueryVariables
>;
