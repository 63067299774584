import * as Types from "../../../__graphql__/types.js";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type SearchUnitsByEanQueryVariables = Types.Exact<{
  searchUnitsByEanInput: Types.SearchUnitsByEanInput;
}>;

export type SearchUnitsByEanQuery = {
  __typename?: "Query";
  searchUnitsByEan: {
    __typename?: "SearchUnitsByEanResponse";
    units: Array<{
      __typename?: "Unit";
      ean?: string | null;
      id: string;
      productSku: string;
      quantity: number;
      type: string;
      product: {
        __typename?: "IProduct";
        bio?: boolean | null;
        imageUrl?: string | null;
        isShelvedInHandlingUnits?: boolean | null;
        name: string;
        numberOfShelfFacings?: number | null;
        countryOfOrigin: { __typename?: "CountryOfOrigin"; code?: string | null };
        inventoryEntry: {
          __typename?: "InventoryEntry";
          shelfNumber?: string | null;
          stock: { __typename?: "Stock"; shelf: number };
        };
      };
    }>;
  };
};

export type SearchUnitsByTextQueryVariables = Types.Exact<{
  searchUnitsByTextInput: Types.SearchUnitsByTextInput;
}>;

export type SearchUnitsByTextQuery = {
  __typename?: "Query";
  searchUnitsByText: {
    __typename?: "SearchUnitsByTextResponse";
    matchType?: Types.MatchType | null;
    units: Array<{
      __typename?: "Unit";
      ean?: string | null;
      id: string;
      productSku: string;
      quantity: number;
      type: string;
      product: {
        __typename?: "IProduct";
        bio?: boolean | null;
        imageUrl?: string | null;
        isShelvedInHandlingUnits?: boolean | null;
        name: string;
        numberOfShelfFacings?: number | null;
        countryOfOrigin: { __typename?: "CountryOfOrigin"; code?: string | null };
        inventoryEntry: { __typename?: "InventoryEntry"; shelfNumber?: string | null };
      };
    }>;
  };
};

export const SearchUnitsByEanDocument = gql`
  query searchUnitsByEan($searchUnitsByEanInput: SearchUnitsByEanInput!) {
    searchUnitsByEan(searchUnitsByEanInput: $searchUnitsByEanInput) {
      units {
        ean
        id
        productSku
        quantity
        type
        product {
          bio
          imageUrl
          isShelvedInHandlingUnits
          name
          numberOfShelfFacings
          countryOfOrigin {
            code
          }
          inventoryEntry {
            shelfNumber
            stock {
              shelf
            }
          }
        }
      }
    }
  }
`;

/**
 * __useSearchUnitsByEanQuery__
 *
 * To run a query within a React component, call `useSearchUnitsByEanQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUnitsByEanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUnitsByEanQuery({
 *   variables: {
 *      searchUnitsByEanInput: // value for 'searchUnitsByEanInput'
 *   },
 * });
 */
export function useSearchUnitsByEanQuery(
  baseOptions: Apollo.QueryHookOptions<SearchUnitsByEanQuery, SearchUnitsByEanQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchUnitsByEanQuery, SearchUnitsByEanQueryVariables>(
    SearchUnitsByEanDocument,
    options,
  );
}
export function useSearchUnitsByEanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchUnitsByEanQuery, SearchUnitsByEanQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchUnitsByEanQuery, SearchUnitsByEanQueryVariables>(
    SearchUnitsByEanDocument,
    options,
  );
}
export type SearchUnitsByEanQueryHookResult = ReturnType<typeof useSearchUnitsByEanQuery>;
export type SearchUnitsByEanLazyQueryHookResult = ReturnType<typeof useSearchUnitsByEanLazyQuery>;
export type SearchUnitsByEanQueryResult = Apollo.QueryResult<
  SearchUnitsByEanQuery,
  SearchUnitsByEanQueryVariables
>;
export const SearchUnitsByTextDocument = gql`
  query searchUnitsByText($searchUnitsByTextInput: SearchUnitsByTextInput!) {
    searchUnitsByText(searchUnitsByTextInput: $searchUnitsByTextInput) {
      matchType
      units {
        ean
        id
        productSku
        quantity
        type
        product {
          bio
          imageUrl
          isShelvedInHandlingUnits
          name
          numberOfShelfFacings
          countryOfOrigin {
            code
          }
          inventoryEntry {
            shelfNumber
          }
        }
      }
    }
  }
`;

/**
 * __useSearchUnitsByTextQuery__
 *
 * To run a query within a React component, call `useSearchUnitsByTextQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUnitsByTextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUnitsByTextQuery({
 *   variables: {
 *      searchUnitsByTextInput: // value for 'searchUnitsByTextInput'
 *   },
 * });
 */
export function useSearchUnitsByTextQuery(
  baseOptions: Apollo.QueryHookOptions<SearchUnitsByTextQuery, SearchUnitsByTextQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchUnitsByTextQuery, SearchUnitsByTextQueryVariables>(
    SearchUnitsByTextDocument,
    options,
  );
}
export function useSearchUnitsByTextLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchUnitsByTextQuery,
    SearchUnitsByTextQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchUnitsByTextQuery, SearchUnitsByTextQueryVariables>(
    SearchUnitsByTextDocument,
    options,
  );
}
export type SearchUnitsByTextQueryHookResult = ReturnType<typeof useSearchUnitsByTextQuery>;
export type SearchUnitsByTextLazyQueryHookResult = ReturnType<typeof useSearchUnitsByTextLazyQuery>;
export type SearchUnitsByTextQueryResult = Apollo.QueryResult<
  SearchUnitsByTextQuery,
  SearchUnitsByTextQueryVariables
>;
