export enum EppoFeatureFlags {
  WRONG_BARCODE_DETAILS = "hubstaffapp_wrong_barcode_details",
  DELIVERY_BASED_INBOUNDING = "hubstaffapp_delivery_based_inbounding",
  DEVICE_CLAIMING_PROCESS = "hubstaffapp_device_claiming_process",
  OPS_ASSIGNMENT_V0 = "hubstaffapp_ops_assignment_v0",
  OA_OCCUPATION_REPORTS = "hubstaffapp_ops_assignment_inbounding",
  EOY_CHECKS = "hubstaffapp_eoy_checks",
  EOY_HIDE_CORRECTIONS = "hubstaffapp_eoy_hide_corrections",
  EOY_SHOW_CHECKS = "hubstaffapp_show_eoy_checks",
  PRODUCT_FACING = "hubstaffapp_product_facings",
  DESADV_BASED_INBOUNDING = "hubstaffapp_desadv_based_inbounding",
  LIST_VERIFICATION_HU_CHECK = "hubstaffapp_list_verification_hu_check",
  ROLLI_VALIDATION = "hubstaffapp_rolli_validation",
  PARTIAL_INBOUNDS = "hub_staff_app_partial_inbounds",
  INTERCOM = "hubstaffapp_intercom",
  NEW_BARCODE_REPORTING = "hubstaffapp_barcode_reporting",
  NEW_RESTOCKING = "hubstaffapp_new_restocking",
  CAMPAIGN_PRODUCT = "hubstaffapp_campaign_product",
  COLLABORATIVE_INBOUND = "hubstaffapp_collaborative_inbound",
}

export enum EppoFeatureVariants {
  ON = "ON",
  OFF = "OFF",
}
