import { useEffect } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { datadogRum } from "@datadog/browser-rum";
import { useIdleTimer } from "react-idle-timer";

import { config } from "config";
import { useEmployeeStore } from "core/stores/useEmployeeStore";
import { useEmployeeLogout } from "flows/Auth/hooks/useEmployeeLogout";
import { INBOUND_MACHINE_CTX_LOCAL_STORAGE_KEY } from "flows/Inbound/machines/inboundMachine/machine";
import { useTimedActivityStore } from "shared/stores/useTimedActivityStore";
import { isNotNullNorUndefined } from "utils/tsHelpers";

const IDLE_LOGOUT_MS = config.environment.IDLE_LOGOUT_MINUTES * 60 * 1000;

export function useAuthStateEffects() {
  const { isAuthenticated, isLoading } = useAuth0();
  const isTimedActivityOngoing = useTimedActivityStore((state) =>
    isNotNullNorUndefined(state.timedActivityInstance),
  );

  const resetEmployeeStore = useEmployeeStore((state) => state.reset);

  const employeeLogout = useEmployeeLogout();

  const onIdle = () => employeeLogout("automatic");

  // ACTIONS
  const { activate, reset, pause } = useIdleTimer({
    onIdle,
    timeout: IDLE_LOGOUT_MS,
    startManually: true,
  });

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      // Make sure to reset employee store whenever the hub is logged out
      resetEmployeeStore();
      // make sure to reset the inboundMachine when the hub is logged out
      localStorage.removeItem(INBOUND_MACHINE_CTX_LOCAL_STORAGE_KEY);
      datadogRum.removeUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isAuthenticated]);

  useEffect(() => {
    if (isTimedActivityOngoing) {
      reset();
      pause();
    } else {
      activate();
    }
  }, [isTimedActivityOngoing, activate, reset, pause]);
}
