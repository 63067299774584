import * as Types from "../../../../../__graphql__/types.js";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type GetCheckByIdQueryVariables = Types.Exact<{
  checkId: Types.Scalars["ID"];
}>;

export type GetCheckByIdQuery = {
  __typename?: "Query";
  getTaskById: {
    __typename?: "GetTaskByIdResponse";
    task?: {
      __typename?: "ICheck";
      id: string;
      priority: number;
      productSku: string;
      shelfNumber: string;
      status: Types.CheckStatus;
      type: Types.TaskType;
      product?: {
        __typename?: "IProduct";
        imageUrl?: string | null;
        minDaysToBestBeforeDate?: number | null;
        name: string;
        sku: string;
        countryOfOrigin: { __typename?: "CountryOfOrigin"; code?: string | null };
      } | null;
    } | null;
  };
};

export type GetNextCheckForHubQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetNextCheckForHubQuery = {
  __typename?: "Query";
  getNextCheckForHub: {
    __typename?: "GetNextCheckForHubResponse";
    check?: {
      __typename?: "ICheck";
      id: string;
      priority: number;
      shelfNumber: string;
      status: Types.CheckStatus;
      type: Types.TaskType;
      product?: {
        __typename?: "IProduct";
        imageUrl?: string | null;
        name: string;
        sku: string;
        countryOfOrigin: { __typename?: "CountryOfOrigin"; code?: string | null };
      } | null;
    } | null;
  };
};

export type GetPendingChecksQueryVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.CheckFilters>;
}>;

export type GetPendingChecksQuery = {
  __typename?: "Query";
  getPendingChecks: {
    __typename?: "GetPendingChecksResponse";
    checks: Array<{
      __typename?: "ICheck";
      id: string;
      priority: number;
      shelfNumber: string;
      type: Types.TaskType;
    }>;
  };
};

export type GetNextCheckForShelfQueryVariables = Types.Exact<{
  shelfNumber: Types.Scalars["String"];
  filters?: Types.InputMaybe<Types.CheckFilters>;
}>;

export type GetNextCheckForShelfQuery = {
  __typename?: "Query";
  getNextCheckForShelf: {
    __typename?: "GetNextCheckForShelfResponse";
    check?: {
      __typename?: "ICheck";
      id: string;
      priority: number;
      productSku: string;
      shelfNumber: string;
      status: Types.CheckStatus;
      type: Types.TaskType;
      product?: {
        __typename?: "IProduct";
        name: string;
        imageUrl?: string | null;
        sku: string;
        countryOfOrigin: { __typename?: "CountryOfOrigin"; code?: string | null };
      } | null;
    } | null;
  };
};

export type CreateCheckMutationVariables = Types.Exact<{
  input: Types.CreateCheckInput;
}>;

export type CreateCheckMutation = {
  __typename?: "Mutation";
  createCheck: { __typename?: "CheckMutationResponse"; success: boolean };
};

export type StartCheckMutationVariables = Types.Exact<{
  checkId: Types.Scalars["ID"];
}>;

export type StartCheckMutation = {
  __typename?: "Mutation";
  startCheck: { __typename?: "CheckMutationResponse"; success: boolean };
};

export const GetCheckByIdDocument = gql`
  query GetCheckById($checkId: ID!) {
    getTaskById(input: { taskId: $checkId }) {
      task {
        id
        priority
        productSku
        shelfNumber
        status
        type
        product {
          imageUrl
          minDaysToBestBeforeDate
          name
          sku
          countryOfOrigin {
            code
          }
        }
      }
    }
  }
`;

/**
 * __useGetCheckByIdQuery__
 *
 * To run a query within a React component, call `useGetCheckByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCheckByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCheckByIdQuery({
 *   variables: {
 *      checkId: // value for 'checkId'
 *   },
 * });
 */
export function useGetCheckByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetCheckByIdQuery, GetCheckByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCheckByIdQuery, GetCheckByIdQueryVariables>(
    GetCheckByIdDocument,
    options,
  );
}
export function useGetCheckByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCheckByIdQuery, GetCheckByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCheckByIdQuery, GetCheckByIdQueryVariables>(
    GetCheckByIdDocument,
    options,
  );
}
export type GetCheckByIdQueryHookResult = ReturnType<typeof useGetCheckByIdQuery>;
export type GetCheckByIdLazyQueryHookResult = ReturnType<typeof useGetCheckByIdLazyQuery>;
export type GetCheckByIdQueryResult = Apollo.QueryResult<
  GetCheckByIdQuery,
  GetCheckByIdQueryVariables
>;
export const GetNextCheckForHubDocument = gql`
  query GetNextCheckForHub {
    getNextCheckForHub {
      check {
        id
        priority
        shelfNumber
        status
        type
        product {
          imageUrl
          name
          sku
          countryOfOrigin {
            code
          }
        }
      }
    }
  }
`;

/**
 * __useGetNextCheckForHubQuery__
 *
 * To run a query within a React component, call `useGetNextCheckForHubQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNextCheckForHubQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNextCheckForHubQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNextCheckForHubQuery(
  baseOptions?: Apollo.QueryHookOptions<GetNextCheckForHubQuery, GetNextCheckForHubQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetNextCheckForHubQuery, GetNextCheckForHubQueryVariables>(
    GetNextCheckForHubDocument,
    options,
  );
}
export function useGetNextCheckForHubLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNextCheckForHubQuery,
    GetNextCheckForHubQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetNextCheckForHubQuery, GetNextCheckForHubQueryVariables>(
    GetNextCheckForHubDocument,
    options,
  );
}
export type GetNextCheckForHubQueryHookResult = ReturnType<typeof useGetNextCheckForHubQuery>;
export type GetNextCheckForHubLazyQueryHookResult = ReturnType<
  typeof useGetNextCheckForHubLazyQuery
>;
export type GetNextCheckForHubQueryResult = Apollo.QueryResult<
  GetNextCheckForHubQuery,
  GetNextCheckForHubQueryVariables
>;
export const GetPendingChecksDocument = gql`
  query GetPendingChecks($input: CheckFilters) {
    getPendingChecks(input: $input) {
      checks {
        id
        priority
        shelfNumber
        type
      }
    }
  }
`;

/**
 * __useGetPendingChecksQuery__
 *
 * To run a query within a React component, call `useGetPendingChecksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPendingChecksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPendingChecksQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPendingChecksQuery(
  baseOptions?: Apollo.QueryHookOptions<GetPendingChecksQuery, GetPendingChecksQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPendingChecksQuery, GetPendingChecksQueryVariables>(
    GetPendingChecksDocument,
    options,
  );
}
export function useGetPendingChecksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPendingChecksQuery, GetPendingChecksQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPendingChecksQuery, GetPendingChecksQueryVariables>(
    GetPendingChecksDocument,
    options,
  );
}
export type GetPendingChecksQueryHookResult = ReturnType<typeof useGetPendingChecksQuery>;
export type GetPendingChecksLazyQueryHookResult = ReturnType<typeof useGetPendingChecksLazyQuery>;
export type GetPendingChecksQueryResult = Apollo.QueryResult<
  GetPendingChecksQuery,
  GetPendingChecksQueryVariables
>;
export const GetNextCheckForShelfDocument = gql`
  query GetNextCheckForShelf($shelfNumber: String!, $filters: CheckFilters) {
    getNextCheckForShelf(input: { shelfNumber: $shelfNumber, filters: $filters }) {
      check {
        id
        priority
        productSku
        shelfNumber
        status
        type
        product {
          name
          imageUrl
          sku
          countryOfOrigin {
            code
          }
        }
      }
    }
  }
`;

/**
 * __useGetNextCheckForShelfQuery__
 *
 * To run a query within a React component, call `useGetNextCheckForShelfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNextCheckForShelfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNextCheckForShelfQuery({
 *   variables: {
 *      shelfNumber: // value for 'shelfNumber'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetNextCheckForShelfQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetNextCheckForShelfQuery,
    GetNextCheckForShelfQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetNextCheckForShelfQuery, GetNextCheckForShelfQueryVariables>(
    GetNextCheckForShelfDocument,
    options,
  );
}
export function useGetNextCheckForShelfLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNextCheckForShelfQuery,
    GetNextCheckForShelfQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetNextCheckForShelfQuery, GetNextCheckForShelfQueryVariables>(
    GetNextCheckForShelfDocument,
    options,
  );
}
export type GetNextCheckForShelfQueryHookResult = ReturnType<typeof useGetNextCheckForShelfQuery>;
export type GetNextCheckForShelfLazyQueryHookResult = ReturnType<
  typeof useGetNextCheckForShelfLazyQuery
>;
export type GetNextCheckForShelfQueryResult = Apollo.QueryResult<
  GetNextCheckForShelfQuery,
  GetNextCheckForShelfQueryVariables
>;
export const CreateCheckDocument = gql`
  mutation CreateCheck($input: CreateCheckInput!) {
    createCheck(input: $input) {
      success
    }
  }
`;
export type CreateCheckMutationFn = Apollo.MutationFunction<
  CreateCheckMutation,
  CreateCheckMutationVariables
>;

/**
 * __useCreateCheckMutation__
 *
 * To run a mutation, you first call `useCreateCheckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCheckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCheckMutation, { data, loading, error }] = useCreateCheckMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCheckMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCheckMutation, CreateCheckMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCheckMutation, CreateCheckMutationVariables>(
    CreateCheckDocument,
    options,
  );
}
export type CreateCheckMutationHookResult = ReturnType<typeof useCreateCheckMutation>;
export type CreateCheckMutationResult = Apollo.MutationResult<CreateCheckMutation>;
export type CreateCheckMutationOptions = Apollo.BaseMutationOptions<
  CreateCheckMutation,
  CreateCheckMutationVariables
>;
export const StartCheckDocument = gql`
  mutation StartCheck($checkId: ID!) {
    startCheck(input: { checkId: $checkId }) {
      success
    }
  }
`;
export type StartCheckMutationFn = Apollo.MutationFunction<
  StartCheckMutation,
  StartCheckMutationVariables
>;

/**
 * __useStartCheckMutation__
 *
 * To run a mutation, you first call `useStartCheckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartCheckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startCheckMutation, { data, loading, error }] = useStartCheckMutation({
 *   variables: {
 *      checkId: // value for 'checkId'
 *   },
 * });
 */
export function useStartCheckMutation(
  baseOptions?: Apollo.MutationHookOptions<StartCheckMutation, StartCheckMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<StartCheckMutation, StartCheckMutationVariables>(
    StartCheckDocument,
    options,
  );
}
export type StartCheckMutationHookResult = ReturnType<typeof useStartCheckMutation>;
export type StartCheckMutationResult = Apollo.MutationResult<StartCheckMutation>;
export type StartCheckMutationOptions = Apollo.BaseMutationOptions<
  StartCheckMutation,
  StartCheckMutationVariables
>;
