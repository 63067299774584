import { ItemFragment } from "flows/Picking/queries/order/order.generated";
import { getShelfDetail } from "utils/item";
import { isNotNullNorUndefined } from "utils/tsHelpers";

import { Item, UnitTypes } from "./types";

const getSmallestHandlingQuantity = (
  units: NonNullable<ItemFragment["product"]>["units"],
): number | null => {
  const quantities = units.filter((unit) => unit.type === "handling").map((unit) => unit.quantity);

  return quantities.length > 0 ? Math.min(...quantities) : null;
};

export const serializeItem = (item: ItemFragment): Item => {
  const shelfDetail = item.product?.inventoryEntry.shelfNumber
    ? getShelfDetail(item.product?.inventoryEntry.shelfNumber)
    : null;
  const hanldingUnitSize = item.product ? getSmallestHandlingQuantity(item.product.units) : null;
  return {
    id: item.id,
    eans: item.product?.units.map((unit) => unit.ean).filter(isNotNullNorUndefined) ?? [],
    hasSingleUnit: item.product?.units?.some((unit) => unit.type === UnitTypes.SINGLE) ?? false,
    imageUrl: item.product?.imageUrl ?? null,
    quantity: item.quantity ?? 0,
    availableQuantity: item.product?.inventoryEntry.stock.shelf ?? 0,
    name: item.product?.name ?? null,
    shelf: item.product?.inventoryEntry.shelfNumber ?? null,
    sku: item.sku ?? null,
    shelfNumber: shelfDetail?.shelfNumber ?? null,
    shelfLetter: shelfDetail?.shelfLetter ?? null,
    countryOfOriginCode: item.product?.countryOfOrigin?.code ?? null,
    hanldingUnitSize,
  };
};
