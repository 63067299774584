import { useApolloClient } from "@apollo/client";
import { datadogRum } from "@datadog/browser-rum";
import { orderBy, uniqBy } from "lodash";

import { InventoryChangeReason, IProduct } from "__graphql__/types";
import {
  GetProductsByShelfDocument,
  GetProductsByShelfQuery,
  GetProductsByShelfQueryVariables,
  SearchProductsByEanDocument,
  SearchProductsByEanQuery,
  SearchProductsByEanQueryVariables,
  SearchProductsByTextDocument,
  SearchProductsByTextQuery,
  SearchProductsByTextQueryVariables,
} from "flows/Inventory/shared/queries/product/product.generated";
import {
  UpdateProductStockByDeltaAndMultipleReasonsDocument,
  UpdateProductStockByDeltaAndMultipleReasonsMutation,
  UpdateProductStockByDeltaAndMultipleReasonsMutationVariables,
} from "shared/queries/inventoryEntry/inventoryEntry.generated";
import { isShelfNumberFormat } from "utils/scan";

import { serializeInventoryProductFromSearchResult } from "../../../../shared/models/inventoryProduct/serializer";
import { FetchProducts, FetchProductsByText, UpdateProductStock } from "./types";

export type UpdateProductStockServiceResult =
  | {
      inventoryProductId: string;
      newStockQuantity: number;
    }
  | undefined;

export function useStockCorrectionServiceImplems() {
  const client = useApolloClient();

  const fetchProductsByShelf = async (shelfNumber: string) => {
    const { data: dataProductsByShelf } = await client.query<
      GetProductsByShelfQuery,
      GetProductsByShelfQueryVariables
    >({
      query: GetProductsByShelfDocument,
      variables: { input: { shelfNumber } },
      fetchPolicy: "no-cache",
    });

    const filteredResults = dataProductsByShelf.getInventoryEntriesByShelf.inventoryEntries
      .filter((inventoryEntry) => inventoryEntry.product)
      .map((inventoryEntry) => {
        return serializeInventoryProductFromSearchResult(inventoryEntry.product as IProduct);
      });
    return orderBy(filteredResults, (result) => result.stock, "desc");
  };

  const fetchProductsByEan = async (ean: string) => {
    const { data: dataProductsByEan } = await client.query<
      SearchProductsByEanQuery,
      SearchProductsByEanQueryVariables
    >({
      query: SearchProductsByEanDocument,
      variables: { searchProductsByEanInput: { ean } },
      fetchPolicy: "no-cache",
    });
    return dataProductsByEan.searchUnitsByEan.units.map((unit) =>
      serializeInventoryProductFromSearchResult(unit.product),
    );
  };

  const fetchProducts: FetchProducts = async (_, { barcode }) => {
    try {
      if (isShelfNumberFormat(barcode)) {
        return await fetchProductsByShelf(barcode);
      }
      return await fetchProductsByEan(barcode);
    } catch (error) {
      datadogRum.addError(new Error(`Error: searchProductsByEan ${error}`));
      throw new Error("Error: searchProductsByEan");
    }
  };

  const fetchProductsByText: FetchProductsByText = async (_, event) => {
    const { data } = await client.query<
      SearchProductsByTextQuery,
      SearchProductsByTextQueryVariables
    >({
      query: SearchProductsByTextDocument,
      variables: { searchProductsByTextInput: { query: event.query } },
      fetchPolicy: "no-cache",
    });

    const filteredUnits = data.searchUnitsByText.units.filter((unit) => !!unit.product);
    const uniqueProducts = uniqBy(filteredUnits, (unit) => unit.product.sku);

    return uniqueProducts.map((unit) => serializeInventoryProductFromSearchResult(unit.product));
  };

  const updateProductStock: UpdateProductStock = async (context, event) => {
    const selectedInventoryUnit = context.inventoryProductsList.find(
      (inventoryUnit) => inventoryUnit.productSku === context.selectedInventoryProductSku,
    );

    try {
      if (!selectedInventoryUnit) {
        throw new Error("Error: inventoryUnit not provided in context");
      }
      const { productSku } = selectedInventoryUnit;

      const stockUpdates = [];

      if (event.expired > 0) {
        stockUpdates.push({
          quantityDelta: event.expired * -1,
          reason: InventoryChangeReason.outbound_product_expired,
          transactionId: `${productSku}:${InventoryChangeReason.outbound_product_expired}:${
            event.expired * -1
          }:${Date.now()}`,
        });
      }
      if (event.damaged > 0) {
        stockUpdates.push({
          quantityDelta: event.damaged * -1,
          reason: InventoryChangeReason.outbound_product_damaged,
          transactionId: `${productSku}:${InventoryChangeReason.outbound_product_damaged}:${
            event.damaged * -1
          }:${Date.now()}`,
        });
      }
      if (event.tooGoodToGo > 0) {
        stockUpdates.push({
          quantityDelta: event.tooGoodToGo * -1,
          reason: InventoryChangeReason.outbound_too_good_to_go,
          transactionId: `${productSku}:${InventoryChangeReason.outbound_too_good_to_go}:${
            event.tooGoodToGo * -1
          }:${Date.now()}`,
        });
      }
      if (event.correction !== 0) {
        stockUpdates.push({
          quantityDelta: event.correction,
          reason: InventoryChangeReason.correction,
          transactionId: `${productSku}:${InventoryChangeReason.correction}:${
            event.correction
          }:${Date.now()}`,
        });
      }

      await client.mutate<
        UpdateProductStockByDeltaAndMultipleReasonsMutation,
        UpdateProductStockByDeltaAndMultipleReasonsMutationVariables
      >({
        mutation: UpdateProductStockByDeltaAndMultipleReasonsDocument,
        variables: {
          updateProductStockByDeltaAndMultipleReasonsInput: {
            sku: productSku,
            stockUpdates,
          },
        },
      });

      return {
        before: event.initial,
        expired: event.expired,
        damaged: event.damaged,
        correction: event.correction,
        tgtg: event.tooGoodToGo,
        productSku: selectedInventoryUnit.productSku,
        after: event.initial - event.expired - event.damaged - event.tooGoodToGo + event.correction,
      };
    } catch (error) {
      datadogRum.addError(new Error(`Error: updateProductStock ${error}`));
      throw new Error("Error: updateProductStock");
    }
  };

  return {
    fetchProducts,
    updateProductStock,
    fetchProductsByText,
  };
}
