import { useCallback, useEffect, useState } from "react";

import { Box, Flex, Image, useDisclosure } from "@chakra-ui/react";
import { shallowEqual, useSelector } from "@xstate/react";
import { useIntl } from "react-intl";

import {
  ConfirmLargeQuantityChangeModal,
  shouldShowLargeQuantityChangeModal,
} from "flows/Inbound/components/ConfirmLargeQuantityChangeModal";
import { TaskCheckHeader } from "flows/Inventory/shared/components/TaskCheckHeader";
import { useGetProductStockQuery } from "flows/Inventory/shared/queries/inventoryEntry/inventoryEntry.generated";
import { IncreaseDecrease } from "shared/components/IncreaseDecrease";
import { Page } from "shared/components/Page";
import { Button } from "ui/Button/Button";
import { BodyS } from "ui/Typography/Typography";
import { formatImage } from "utils/image";
import { isNotNullNorUndefined } from "utils/tsHelpers";

import { useStockCheckService } from "../hooks/useStockCheckService";
import { useStockCheckTaskData } from "../hooks/useStockCheckTaskData";

const IMAGE_SIZE = 218;

export function StockCheckQuantityPage() {
  const intl = useIntl();
  const [countedItems, setCountedItems] = useState<number>(0);
  const [hasConfirmedLargeQuantityChange, setHasConfirmedLargeQuantityChange] = useState(false);

  const {
    isOpen: isConfirmLargeQuantityChangeModalOpen,
    onOpen: openConfirmLargeQuantityChangeModal,
    onClose: closeConfirmLargeQuantityChangeModal,
  } = useDisclosure();

  const stockCheckService = useStockCheckService();

  const { origin, shelfLetter, shelfNumber } = useSelector(
    stockCheckService,
    ({ context }) => context,
    shallowEqual,
  );

  const { sku, productImageUrl, productName, productCountryOfOriginName } = useStockCheckTaskData();

  const {
    data,
    loading: expectedQuantityLoading,
    error: expectedQuantityError,
  } = useGetProductStockQuery({
    fetchPolicy: "network-only",
    variables: { sku: sku! },
    skip: !sku,
  });

  const expectedQuantity = data?.getProduct.inventoryEntry.stock.shelf ?? 0;

  const onCompleteCount = useCallback(() => {
    if (
      !expectedQuantityLoading &&
      !expectedQuantityError &&
      shouldShowLargeQuantityChangeModal(expectedQuantity, countedItems) &&
      !hasConfirmedLargeQuantityChange
    ) {
      openConfirmLargeQuantityChangeModal();
      return;
    }

    stockCheckService.send({
      type: "DECLARE_QUANTITY",
      declaredQuantity: countedItems,
    });
  }, [
    expectedQuantityLoading,
    expectedQuantityError,
    countedItems,
    expectedQuantity,
    hasConfirmedLargeQuantityChange,
    stockCheckService,
    openConfirmLargeQuantityChangeModal,
  ]);

  useEffect(() => {
    setHasConfirmedLargeQuantityChange(false);
  }, [countedItems]);

  useEffect(() => {
    if (hasConfirmedLargeQuantityChange) {
      onCompleteCount();
    }
  }, [hasConfirmedLargeQuantityChange, onCompleteCount]);

  const imageUrlFormatted = isNotNullNorUndefined(productImageUrl)
    ? formatImage(productImageUrl, IMAGE_SIZE, IMAGE_SIZE)
    : null;

  return (
    <Page isCentered isBgGrey isFull isLoading={expectedQuantityLoading}>
      <TaskCheckHeader
        headerMessageId="pages.stock-check-header.stock-check"
        origin={origin}
        shelfLetter={shelfLetter}
        shelfNumber={shelfNumber}
      />
      <Flex flex={1} flexDir="column" overflowY="scroll" w="100%">
        <ConfirmLargeQuantityChangeModal
          onClickConfirm={() => setHasConfirmedLargeQuantityChange(true)}
          onClickDecline={closeConfirmLargeQuantityChangeModal}
          isOpen={isConfirmLargeQuantityChangeModalOpen}
          quantity={countedItems}
        />
        <Flex flex={1} align="center" flexDir="column" justify="center" px="s100">
          <Flex flex={1} align="center">
            {isNotNullNorUndefined(imageUrlFormatted) && (
              <Image
                src={imageUrlFormatted}
                objectFit="contain"
                h="60vh"
                maxW={`${IMAGE_SIZE}px`}
                maxH={`${IMAGE_SIZE}px`}
              />
            )}
          </Flex>
          <Box
            py="s100"
            px="s200"
            mb="s100"
            bg="white"
            borderRadius="sm"
            justifyContent="center"
            w="100%"
          >
            <BodyS
              w="100%"
              textAlign="center"
              color="grey.800"
              data-testid="stock-check-product-name"
            >
              {productName} {productCountryOfOriginName ? ` (${productCountryOfOriginName})` : null}
            </BodyS>
          </Box>
        </Flex>
        <Box bg="white" p="s200" gap="s50">
          <Box mx="s100">
            <IncreaseDecrease
              labelMessageId="components.increasedecrease.how_many_units"
              changeHandler={setCountedItems}
              count={countedItems}
              withInput
            />
          </Box>
          <Button
            size="lg"
            mt="s300"
            width="100%"
            onClick={onCompleteCount}
            data-testid="submit-quantity-button"
          >
            {intl.formatMessage({ id: "pages.task.taskIndex.confirm_amount" })}
          </Button>
        </Box>
      </Flex>
    </Page>
  );
}
