import { useEffect, useState } from "react";

import { FormattedMessage, useIntl } from "react-intl";

import { useCountryCode } from "shared/hooks/useCountryCode";
import { Banner } from "ui/Banner/Banner";
import { Button } from "ui/Button/Button";

import { useInboundUIStore } from "../stores/useInboundUIStore";

const HUB_ALERT_LINK_BY_COUNTRY: Record<string, string> = {
  de: "https://airtable.com/shr9J6s5YJu05tJc1",
  nl: "https://airtable.com/shrrt2zrhZlpSDgZs",
};

const hubAlertLink = (countryCode: string) =>
  // eslint-disable-next-line func-names
  function (children: React.ReactNode[]) {
    return (
      <Button
        textDecoration="underline"
        variant="link"
        size="sm"
        color="pinkFlink.500"
        bg="transparent"
        onClick={() => {
          window.open(HUB_ALERT_LINK_BY_COUNTRY[countryCode]);
        }}
      >
        {children}
      </Button>
    );
  };

export function EditHandlingUnitsBanner() {
  const intl = useIntl();
  const countryCode = useCountryCode();

  const isEditingHUSize = useInboundUIStore((state) => state.isEditingHUSize);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isEditingHUSize && !isOpen) {
      setIsOpen(true);
    } else if (!isEditingHUSize && isOpen) {
      setIsOpen(false);
    }
  }, [isEditingHUSize, isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <Banner
      title={intl.formatMessage({ id: "components.inbound.editing-hu-size-banner.title" })}
      description={
        <FormattedMessage
          id="components.inbound.editing-hu-size-banner.description"
          values={{
            link: hubAlertLink(countryCode),
          }}
        />
      }
      pos="fixed"
      top="55px"
      zIndex={100}
      descriptionSize="s"
    />
  );
}
