import { Flex, FlexProps } from "@chakra-ui/react";

import { ArrowLeftIcon } from "ui/Icons/Icons";
import { TitleM } from "ui/Typography/Typography";

export type NavigationHeaderProps = {
  title: string;
  onClickGoBack: () => void;
  rightElement?: React.ReactNode;
} & FlexProps;

export function NavigationHeader({
  title,
  onClickGoBack,
  rightElement,
  ...rest
}: NavigationHeaderProps) {
  return (
    <Flex
      bg="white"
      p="s200"
      w="100%"
      alignItems="center"
      borderBottomWidth="1px"
      borderBottomColor="grey.200"
      {...rest}
    >
      <ArrowLeftIcon
        data-testid="navigation-header-go-back-icon"
        cursor="pointer"
        boxSize="1.5rem"
        onClick={onClickGoBack}
      />

      <TitleM flex={1} noOfLines={1} textOverflow="ellipsis" marginLeft="s100">
        {title}
      </TitleM>

      {rightElement}
    </Flex>
  );
}
